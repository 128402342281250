import React, { useState, useEffect, useDebugValue } from "react";
import { format } from 'date-fns';
import imgFile from "../../../assets/profile.png"
import { Container, Row, Col } from "react-bootstrap";
import "./style.css"
import imgFile2 from "../../../assets/message2.svg"
import getManagerToken from "../../../config/ManagerToken";
import getApi from "../../../config/ApiGet";
import getManagerInfo from "../../../config/ManagerInfo";
import formatTimestamp from "../Timebox/TimeAPI";

export default function Frontbox ({...props}) {
    console.log("read Status", props.readStatus, props.userName)
    return(
        <div style={{ height: "8vh", background: "#EAEAEA", borderRadius: "5px", width: "90%", marginLeft: "5%" }}>
            <Container>
                <Row>
                    <div className="imagePart">
                        <img
                            style={{ height: "48px", width: "48px", borderRadius: "64px", marginTop: "20%" }}
                            src={imgFile}
                            className="profile_img_main_page"
                        />
                    </div>
                    <div className="messagePart">
                        <Row style={{ marginTop: "5%" }}>
                            {props.userName}
                        </Row>
                        <Row>
                            <div className="messageStyle">
                                <div className="message1">
                                    <img
                                        style={{ marginLeft: "-10px" }}
                                        src={imgFile2}
                                    />  
                                </div>
                                <div className="message2">
                                    <p style={{ fontSize: "0.9em", paddingTop: "2%", color: props.readStatus === true || props.readStatus === undefined ? "#848484" : "black" }}>{props.readStatus === true || props.readStatus === undefined ? props.message?.substring(0, 15) : <strong>{props.message?.substring(0, 15)}</strong> }</p>
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className="datePart" style={{ fontSize: ".9em", marginTop: "20px", display: "flex", justifyContent:"flex-end", color: "#1D7DEF"  }}>
                        {props?.timeStamp ? formatTimestamp(props?.timeStamp) : null}
                    </div>
                </Row>
            </Container>
        </div>
    )
}