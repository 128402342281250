import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { format, parseISO } from 'date-fns';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imgFile from "../../../assets/profile.png"
import searchImage from "../../../assets/Search.png"
import downloadIcon from "../../../assets/incident-download-icon.png"
import downloadDash from "../../../assets/incident-download-dash.png"

const CriminalTrespassListPage = ({ ...props }) => {
  const [criminalTrespass, setCriminalTrespass] = useState([]);
  const [token, setToken] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");
  const [searchOn, setSearchOn] = useState(false)
  const [userListCheck, setUserListCheck] = useState(false);
  const [updatedUserList, setUpdatedUserList] = useState([]);

  const [user, setUser] = useState([]);

  const managerToken = localStorage.getItem("token")

  const notify = () => toast.success('Successfully Removed!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const getCriminalTrespass = (buildingno, _token) => {
    var config = {
      method: "get",
      url: `https://kepah-24275.botics.co/api/v1/admin/criminal-trespass/`,
      headers: {
        Authorization: `token ${managerToken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // let criminalTrespass = response.data;
        // console.log(response.data);
        console.log("response.data.criminalTrespass", JSON.stringify(response.data, null, 2))
        const xx = response.data.filter(res => (
          res.user.criminal_status.status !== false
        ))
        setCriminalTrespass(xx);
        setFiltered(xx);
      })
      .catch(function (error) {
        console.log(error, "It's a error!!!");
      });
  };

  const deleteCriminalTrespass = (id) => {
    const confirmed = window.confirm("Are you sure you want to remove this record?");
    if (confirmed) {
      console.log(id)
      var config = {
        method: "patch",
        url: `https://kepah-24275.botics.co/api/v1/admin/criminal-trespass/${id}/`,
        headers: {
          Authorization: `token ${managerToken}`
        },
        data: {
          status: "inactive"
        }
      };

      axios(config)
        .then(function (response) {
          console.log("response.data", JSON.stringify(response.data, null, 2))
          console.log(response.status)
          notify()
          getCriminalTrespass()
        })
        .catch(function (error) {
          console.log(error, "It's a error!!!");
        });
    }
  };

  var configDownload = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/criminal-trespass/download/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  const downloadClick = (e) => {
    e.preventDefault();
    axios(configDownload)
      .then((res) => {
        // Check if the response contains data
        if (res.data) {
          // Prepare the file data and create a Blob object
          const blob = new Blob([res.data], { type: "application/octet-stream" });

          // Create a temporary URL for the Blob
          const blobUrl = URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = blobUrl;

          // Set the download attribute and suggested file name
          link.download = "criminal-trespass.csv"; // Replace with the desired file name

          // Simulate a click on the link to trigger the download
          link.click();

          // Clean up the created URL
          URL.revokeObjectURL(blobUrl);
        }
      })
      .catch((error) => {
        console.error("Error downloading:", error);
      });
  };

  const formatDate = (date) => {
    const joinedDateISO = date; // "2023-06-25T21:20:45.302375Z"
    const parsedJoinedDate = parseISO(joinedDateISO);

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedJoinedDate = format(parsedJoinedDate, 'yyyy-MM-dd');
    return formattedJoinedDate
  }

  useEffect(() => {
    getCriminalTrespass(
      localStorage.getItem("buildingno"),
      localStorage.getItem("token")
    );
    setToken(localStorage.getItem("token"));
  }, [localStorage.getItem("token") && localStorage.getItem("buildingno")]);

  useEffect(() => {
    let filter = criminalTrespass.filter((val) => {
      if (search !== "" && val.user.name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
      if (search !== "" && val.user.date_joined.includes(search)) {
        return val;
      }
    });
    if (filter.length < 1 && search === "") {
      setFiltered(criminalTrespass);
    } else {
      setFiltered(filter);
    }
  }, [search]);

  return (
    <div>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">
            Criminal Trespass Warnings ({criminalTrespass.length})
          </span>
        </div>
      </div>

      <div className="criminal_input_div">
        <input
          type="text"
          className="criminal_input"
          placeholder="Name or Date Added"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="criminal_search_btn">
          <img src={searchImage} />
        </div>
        <Form className="form_inline_criminal">
          <Button
            className="save_btn_add_criminal"
            variant="primary"
            onClick={() => props.history.push("criminal/add")}
          >
            ADD NEW PERSON
          </Button>
        </Form>

        <Form className="downloadCriminal">
          <Button
            className="download_btn_incident"
            variant="primary"
            type="submit"
          >
            <div className="download_icon_div">
              <img
                src={downloadIcon}
              />
              <img
                src={downloadDash}
              />
            </div>
            <span onClick={(e) => downloadClick(e)} className="download_text">DOWNLOAD</span>
          </Button>
        </Form>
      </div>

      <div className="pt-4">
        <div className="officer_license_number_main_page">
          <span className="name_text_manager_criminal">Name</span>
          <span className="added_text_manager_criminal">Added on</span>
          <span className="added_text_manager_criminal">Added by</span>
          <span className="status_text_manager_criminal">User Portal</span>
        </div>
        <div className="bottom_border_main_page" />

        <div className="all_criminal_trespass_lists">
          {filtered.map((val, ind) => {
            return (
              <div
                key={ind}
                className="officer_license_number_white_back_main_page row_alternate"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <div className="name_text_manager_criminal">
                  {!val.user.profile_picture ? (
                    <img
                      style={{ height: "32px", width: "32px", borderRadius: "64px" }}
                      src={imgFile}
                      className="profile_img_main_page"
                    />
                  ) : (
                    <img
                      style={{ height: "32px", width: "32px", borderRadius: "64px" }}
                      src={val.user.profile_picture}
                      className="profile_img_main_page profImage"
                    />
                  )}
                  <span className="criminalName" style={{ cursor: "pointer" }} onClick={() => {
                    localStorage.setItem("criminalInfo", JSON.stringify(val));
                    props.history.push("criminal/show")
                  }}>
                    {val.user.name && val.user.name ? val.user.name : "-"}
                  </span>
                </div>
                <span className="added_text_manager_criminal">
                  {/* {val.user_details.dob} */}
                  {formatDate(val.added_on)}
                </span>
                <span className="added_text_manager_criminal">
                  {val.added_by.name}
                  {/* {new Date(val.updated_at).getDate()}/
                  {new Date(val.updated_at).getMonth() + 1}/
                  {new Date(val.updated_at).getFullYear()} */}
                </span>
                <span className="status_text_manager_criminal">
                  {val.status === "active" ? "Deactivated" : "Not Active"}
                </span>
                <span style={{ cursor: "pointer" }} onClick={() => deleteCriminalTrespass(val.id)} className="remove_text_manager_criminal">Remove</span>
              </div>
            );
          })}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CriminalTrespassListPage;













