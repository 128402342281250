import React, {useState} from "react";
import "./style.css";
import Sidebar from "../Sidebar/Sidebar";
import ListOfVendors from "./ListOfVendorsPage/ListOfVendorsPage";
import AddNewVendor from "./AddNewVendor/AddNewVendor";
import EditVendor from "./EditVendor/EditVendor";

const Caseload = ({ ...props }) => {
  const [addVendor, setVendor] = useState(false);
  const [editVendor, setEditVendor] = useState(false)

  const vendor = () => {
    setVendor(true);
  };
  const closeAddNewVendor = () => {
    setVendor(false);
  };

  const editVen = () => {
    setEditVendor(true);
  }
  
  const closeEditVen = () => {
    setEditVendor(false);
  }

  return (
      <>
        {addVendor === false && editVendor === false  ? (
          <ListOfVendors editVen={editVen} vendor={vendor} history={props.history}/>
        ) : ""}
        {addVendor === true && editVendor === false ? (
            <AddNewVendor history={props.history} closeAddNewVendor={closeAddNewVendor} />
          
        ) : ""}
        {addVendor === false && editVendor === true ? (
            <EditVendor history={props.history} closeEditVen={closeEditVen} />
        ) : ""}
      </>
    
  );
};

export default Caseload;
