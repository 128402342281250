import React, { useState, useEffect } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import ResidentInfo from "../ResidentInfo/ResidentInfo";
import VisitorInfo from "../VisitorInfo/VisitorInfo";
import EditSvg from "../../../assets/edit.svg"
import searchImage from "../../../assets/Search.png"
import personImage from "../../../assets/profile.png"

const ListOfResidents = ({ ...props }) => {
  const [searchOnAptVar, setSearchOnAptVar] = useState("");
  const [searchOnNameVar, setSearchOnNameVar] = useState("");
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [updatedUserList, setUpdatedUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userListCheck, setUserListCheck] = useState(false);
  const [userListChanged, setUserListChanged] = useState(false); 
  const [searchOnApt, setSearchOnApt] = useState(false)
  const [searchOnName, setSearchOnName] = useState(false)

  const [showResident, setShowResident] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [position, setSelectedUserPosition] = useState(null);
  const [userUpdated, setUserUpdated] = useState(false);

  const showResidentInfo = (event, resident) => {
    // Get the position of the clicked apartment number
    const rect = event.currentTarget.getBoundingClientRect();
    const position = { x: rect.left, y: rect.top + rect.height };
    setShowResident(true);
    setSelectedResident(resident);
    setSelectedUserPosition(position);
  };

  const managerToken = localStorage.getItem("token")

  var config = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/residents/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data
        const res = await axios(config);
        const userListData = res.data;
        console.log(res.data)
  
        // Fetch vehicle info for each user
        const updatedUserListData = await Promise.all(
          userListData.map(async (user) => {
            const config2 = {
              method: "get",
              url: `https://kepah-24275.botics.co/api/v1/admin/tenants/${user.id}/vehicle/`,
              headers: {
                Authorization: `token ${managerToken}`,
                "Content-Type": "application/json",
              },
            };
  
            const vehicleRes = await axios(config2);
            const vehicleData = vehicleRes.data;
            const vehicleNumber = vehicleData?.[0]?.vehicle_number || "";
            const updatedUser = { ...user, vehicle: vehicleNumber };
            return updatedUser;
            // Add vehicle info to the user object
          })
        );
        // Update the state with the updated user list containing vehicle info
        setUserList(updatedUserListData)
        setUpdatedUserList(updatedUserListData);
        setUserData(updatedUserListData);
        console.log(userData)
        setUserListCheck(true);
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!searchOnName && !searchOnApt) {
      setUserList(userData)
      setUpdatedUserList(userList)
       setUserListCheck(true);
    } else {
      let filteredResults = []
      if (searchOnName) {
        filteredResults = userList.filter((user) => {
          console.log(user)
          if (user && user.name) {
            const nameMatch = user.name.toLowerCase().includes(searchOnNameVar.toLowerCase());
            return nameMatch;
          }
          return false;
        });
      }
      if (searchOnApt) {
        filteredResults = userList.filter((user) => {
          if (user && user.apartment_number) {
            const aptMatch = user.apartment_number.toString().includes(searchOnAptVar);
            return aptMatch;
          }
          return false;
        });
      }
      console.log(filteredResults)
      setFilteredUsers(filteredResults);
    }
  }, [userList, searchOnNameVar, searchOnAptVar]);

  useEffect(() => {
    setUpdatedUserList(filteredUsers)
  }, [filteredUsers]);

  
  useEffect(() => {
    if(!searchOnName && !searchOnApt) {
      setUpdatedUserList(userData)
      setUserList(userData)
    } else {
      setUpdatedUserList(updatedUserList)
    }
  }, [userList, searchOnName, searchOnApt, userData]);

  useEffect(() => {
    if(searchOnNameVar.length > 0 ) {
      setSearchOnName(true)
      setSearchOnApt(false)
    } else if(searchOnAptVar.length > 0 )  {
      setSearchOnApt(true)
      setSearchOnName(false)
    } else {
      setSearchOnApt(false)
      setSearchOnName(false)
    }
  }, [searchOnAptVar, searchOnNameVar])

  return (
    <div onClick={(event) => {
      const rect = event.currentTarget.getBoundingClientRect();
      const position2 = { x: rect.left, y: rect.top + rect.height };
      console.log("position2")
      console.log(position2)
      if (selectedResident && position && (position.x !== position2.x || position.y !== position2.y)) {
        setSelectedResident(null); // Close the resident info div
      }
    }}>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">List of Residents ({userList.length})</span>
        </div>
      </div>

      <div className="criminal_input_div">
        <input
          type="text"
          className="rent_input"
          placeholder="Full Name"
          onChange={(e) => {
            setSearchOnNameVar(e.target.value)
            setSearchOnName(true)
          }}
        />
        <input
          type="text"
          className="rent_input"
          placeholder="Apartment Number"
          onChange={(e) => {
            setSearchOnAptVar(e.target.value)
            setSearchOnApt(true)
          }}
        />
        <div className="criminal_search_btn">
          <img src={searchImage} />
        </div>
        <Form className="form_inline_criminal">
          <Button
            className="add_new_resident_btn"
            variant="primary"
            type="submit"
            onClick={() => props.history.push("listofresidents/add")}
          >
            ADD NEW RESIDENT
          </Button>
        </Form>
      </div>

      <div 
        className="pt-4">
        <div className="list_of_residents_div">
            <div>
              <Container>
                <div className="infoRow">
                  <div className="col_resident_name">
                    Resident
                  </div>
                  <div className="col_resident_apt">
                    Apt. Number
                  </div>
                  <div className="col_resident_tenants">
                    Occupants
                  </div>
                  <div className="col_resident_email">
                    Email
                  </div>
                  <div className="col_resident_vehicle">
                    Vehicle Info
                  </div>
                  <div className="col_resident_edit">

                  </div>
                </div>
                <div className="bottom_border_manager mt-1 mb-1" />
                {/* <Row>
                  <div className="bottom_border_manager" />
                </Row> */}
                { userListCheck ? updatedUserList.map(user => (
                  <div className="infoRow p-1 mt-2 row_alternate" key={user.id}>
                    <div className="col_resident_name">
                      <Row>
                        <Col className="col-md-1">
                          <img
                            style={{ height: "32px", width: "32px", borderRadius: "64px" }}
                            src={personImage}
                          />
                        </Col>
                        <Col style={{ marginLeft: "23px", color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                          {user.name}
                        </Col>
                      </Row>
                    </div>
                    <div onClick={(event) => showResidentInfo(event, user)} className="col_resident_apt" style={{ color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                      {user.apartment_number}
                    </div>
                    <div className="col_resident_tenants" style={{ fontSize: "14px", paddingTop: "6px"}} >
                      {user.tenants}
                    </div>
                    <div className="col_resident_email" style={{ fontSize: "14px", paddingTop: "6px"}}>
                      {user.email}
                    </div>
                    <div className="col_resident_vehicle" style={{ fontSize: "14px", paddingTop: "6px"}}>
                      {user.vehicle}
                    </div>
                    <div className="col_resident_edit" style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}} onClick={() => {
                      localStorage.setItem("residentInfoChange", JSON.stringify(user));
                      props.history.push("listofresidents/edit");
                    }}>
                      <img src={EditSvg} style={{ height: "16px", width: "16px" }}/>
                    </div>
                  </div>
                  
                )): <></> }
                {selectedResident && (
                  <div>
                    <ResidentInfo
                      className=""
                      resident={selectedResident}
                      position={position}
                      onClose={() => setSelectedResident(null)} // Function to close ResidentInfo
                    />
                  </div>
                )}
              </Container>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfResidents;
