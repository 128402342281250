import React, { useState } from "react";
import "./style.css";
import Sidebar from "../Sidebar/Sidebar";
import ListOfManagersPage from "./ListOfManagersPage/ListOfManagersPage";
import EditManager from "./EditManager/EditManager";
import AddManager from "./AddManager/AddManager";

const RentRoll = ({ ...props }) => {
  const [editManager, setEditManager] = useState(false);
  const [addManager, setAddManager] = useState(false);
  const [editManagerInfo, setEditManagerInfo] = useState(null);

  const handleManagerInfoChange = (user) => {
    setEditManagerInfo(user);
    console.log(editManagerInfo)
  }

  const manager = () => {
    setEditManager(true);
  };

  const add_manager = () => {
    setAddManager(true)
  }

  const closeEditManager = () => {
    setEditManager(false);
  };
  
  const closeAddManager = () => {
    setAddManager(false);
  };


  if(!editManager && !addManager) {
    return (
      <div
      className={props.popup ? "incident_back_popup" : "incident_back"}
      >
        <div className="side_nav">
          <Sidebar history={props.history} nav="ListOfManagers" />
        </div>

        <div className="rent_main_div">
          <div className="incident_action_main_page">
            <ListOfManagersPage history={props.history} setManager={manager} addNewManager={add_manager} onManagerChange={handleManagerInfoChange}/>
          </div>
        </div>
      </div>
    )
  } else if(editManager) {
    return(
          <EditManager history={props.history} closeEditManager={closeEditManager} />
    )
  } else if(addManager) {
    return(
          <AddManager history={props.history} closeAddManager={closeAddManager} />
    )
  }
  
  // return (
  //   <div
  //     className={props.popup ? "incident_back_popup" : "incident_back"}
  //   >
  //     <div className="side_nav">
  //       <Sidebar history={props.history} nav="ListOfManagers" />
  //     </div>

  //     <div className="rent_main_div">
  //       {editManager === false ? (
  //         <div className="incident_action_main_page">
  //           <ListOfManagersPage setManager={manager} />
  //         </div>
  //       ) : (
  //         <EditManager closeEditManager={closeEditManager} />
  //       )}
  //     </div>

  //   </div>
  // );
};

export default RentRoll;
