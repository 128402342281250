import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import up_arrow from "../../../assets/up_arrow.svg"
import { Container, Col, Row } from "react-bootstrap";
import personImage from "../../../assets/profile.png"


import "./style.css";
const VisitorInfo = ({ resident, position, onClose }) => {
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [positionYI, setPositionYI] = useState(0);
    const [warning, setWarning] = useState(false);
    const [criminal, setCriminal] = useState(false);
  
    useEffect(() => {
        setPositionX(position.x-125)
        setPositionY(position.y)
        setPositionYI(position.y)
    }, [position]);

  const handleResidentInfoClick = (event) => {
    // Prevent event propagation to parent div
    event.stopPropagation();
  };

  const handleWarning = () => {
    setWarning(true)
    setCriminal(false)
  }

  const handleCriminal = () => {
    setCriminal(true)
    setWarning(false)
  }

  if(warning) {
    return (
        <div 
        onClick={handleResidentInfoClick}
        style={{
            position: "absolute",
            left: positionX, // Use the x-coordinate from position prop
            top: positionY, // Use the y-coordinate from position prop,
        }}
        className="visitor_personal_div">
            <Container>
                <Row>
                    <div >
                        <label className="incident_report_label">Violation Type:</label>
                        <div className="bg_of_drop">
                        <div className="phone_input_field_div">

                            <select
                                className="phone_input_field_xx"
                                // value={role}
                                // onChange={handleDropdownChange}
                            >
                                <option value="Resident">Resident</option>
                                <option value="Occupant">Occupant</option>
                                <option value="Visitor">Visitor</option>
                            </select>
                        </div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="">
                        <label className="incident_report_label">Warning Content:</label>
                        <div className="phone_input_div">
                            <div className="phone_input_field_div">
                                <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Row>
                <Row className="warningSubmit">
                    <Button>Submit</Button>
                </Row>
            </Container>
        </div>
    );
    } else if(criminal) {
    return (
        <div 
        onClick={handleResidentInfoClick}
        style={{
            position: "absolute",
            left: positionX, // Use the x-coordinate from position prop
            top: positionY, // Use the y-coordinate from position prop,
            padding: "10px"
        }}
        className="visitor_personal_div">
            <Container>
                <Row>
                    <div className="">
                        <label className="incident_report_label">Violator Name:</label>
                        <div className="phone_input_div">
                            <div className="phone_input_field_div">
                                <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="">
                        <label className="incident_report_label">Address:</label>
                        <div className="phone_input_div">
                            <div className="phone_input_field_div">
                                <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="">
                        <label className="incident_report_label">City:</label>
                        <div className="phone_input_div">
                            <div className="phone_input_field_div">
                                <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="">
                        <label className="incident_report_label">State:</label>
                        <div className="phone_input_div">
                            <div className="phone_input_field_div">
                                <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="">
                        <label className="incident_report_label">Zip:</label>
                        <div className="phone_input_div">
                            <div className="phone_input_field_div">
                                <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="">
                        <label className="incident_report_label">Phone Number:</label>
                        <div className="phone_input_div">
                            <div className="phone_input_field_div">
                                <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="">
                        <label className="incident_report_label">Gender:</label>
                        <div className="phone_input_div">
                            <div className="phone_input_field_div">
                                <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="">
                        <label className="incident_report_label">Race:</label>
                        <div className="phone_input_div">
                            <div className="phone_input_field_div">
                                <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Row>

                <div className="">
                    <label className="incident_report_label">Date of Birth:</label>
                    <div className="phone_input_div bg_of_drop">
                    <div className="phone_input_field_div">
                        <input className="phone_input_field_date" type="date" onChange={(event) => console.log(event.target.value)}/>
                    </div>
                    </div>
                </div>

                <Row className="warningSubmit">
                    <Button>Submit</Button>
                </Row>
            </Container>
        </div>
    );
  }
  
  else {
    return (
        <div 
        onClick={handleResidentInfoClick}
        style={{
          position: "absolute",
          left: positionX, // Use the x-coordinate from position prop
          top: positionY, // Use the y-coordinate from position prop,
        }}
        className="visitor_personal_div">
            <Container>
                <Row>
                    <Col className="col-md-1 arrowTop">
                        <div className="up_arrow_visitor" style={{top: positionYI, left: positionX}}>
                            <img
                                src={up_arrow}
                                className="up_arrow_img"
                                style={{marginTop: "10px", width: "100%"}}
                            />
                        </div>
                    </Col>
                    <Col className="col-md-11">
                        <Row>
                            <div className="top_visitor_space">
    
                            </div>
                        </Row>
                        <Row>
                            <div className="content_padding_visitor">
                                <div className="visitor_info">
                                    <Row className="profile_img_visitor_info">
                                        <Col className="prof_image col-md-4">
                                            <img
                                                src={personImage}
                                                className="profile_img_visitor_info_img"
                                            />
                                        </Col>
                                        <Col className="prof_info_visitor col-md-8">
                                            <p style={{fontSize: "25px"}} className="prof_info_txt_name">{resident.name}</p>
                                            <p className="prof_info_txt_visitor pt-2">Visitor(Imergency Person)</p>
                                            <p className="prof_info_txt_visitor">{resident.email}</p>
                                            <p className="prof_info_txt_visitor">{resident.phone_number}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                        <Row className="warBut">
                            <Button className="warningButton" onClick={handleWarning}>
                                WARNING
                            </Button>
                        </Row>
                        <Row className="warBut">
                            <Button className="infraButton" >
                                INFRACTION
                            </Button>
                        </Row>
                        <Row className="warBut">
                            <Button className="reportButton">
                                REPORT
                            </Button>
                        </Row>
                        <Row className="crimTre">
                            <p style={{ textAlign: "center" }} onClick={handleCriminal}>Criminal Trespass</p>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
  }
};

export default VisitorInfo;