import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./style.css";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EyeSign from "../../assets/vector.png"
import ForestClove from "../../assets/forestCove.png"
import Kepah from "../../assets/kepah.png"

import { notifySuccess, notifyErrorRequired, notifyLoading  } from "../Toast/Toast";

const Welcome = ({ ...props }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword]= useState("")
  const [confirmPassword, setConfirmPassword]= useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [token, setToken] = useState("")
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("")
  const [errorFlag, setErrorFlag] = useState(false)
  const [buttonClickedX, setButtonClickedX] = useState(false);

  useEffect(() => {
    setPasswordError("")
    setError("")
    setErrorFlag(false)
    setButtonClickedX(false)
  }, [password, confirmPassword])

  const notifyError = ({ toastMessage, autoClose }) => toast.error(toastMessage, {
    position: "top-right",
    autoClose: autoClose | 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  const validateFe = () => {
    if(password === "" || confirmPassword === "") {
      setPasswordError("Password Fields Are Required")
      setErrorFlag(true)
    }
    if(password != confirmPassword && !errorFlag) {
      setPasswordError("Password Didn't Match")
      setErrorFlag(true)
    }
  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  useEffect(()=> {
    if(buttonClickedX) {
      if(errorFlag != true){
        handleWelcomeSave()
      }
    }
  }, [errorFlag, buttonClickedX])


  useEffect(() => {

  //   const url = window.location.href;
  
  // // Replace &amp; with &
  //   const cleanedURL = url.replace(/&amp;/g, '&');
  //   console.log(cleanedURL)

  //   // Get the current URL from the window location
  //   const urlParams = new URLSearchParams(cleanedURL);

    const urlParams = new URLSearchParams(window.location.search);

    
    // Extract the email parameter from the URL
    const emailParam = urlParams.get('email');
    const tokenParam = urlParams.get('token');
  
    // Set the email state if the email parameter exists in the URL
    if (emailParam) {
      setEmail(emailParam);
    }
  
    // Set the token state if the token parameter exists in the URL
    if (tokenParam) {
      setToken(tokenParam);
    }

  }, []);
  
  
  useEffect(() => {
    // This effect runs whenever 'token' changes
    console.log("Token:", token);
  }, [token]);
  
  useEffect(() => {
    // This effect runs whenever 'email' changes
    console.log("Email:", email);
  }, [email]);
  
  // ... rest of your component code ...
  

  var data = {
    password_one: password,
    password_two: confirmPassword
  };

  var config = {
    method: "post",
    url: `https://kepah-24275.botics.co/api/v1/admin/${token}/password_change/`,
    data: data
  };

  const handleWelcomeSave = () => {
    setError("Loading")
    notifyLoading("Loading")
      axios(config).then((res) => {
        console.log("Response from reset password:", JSON.stringify(res, null, 2))
  
        if(res.data.status === "fail") {
          console.log("Error in changing pass", JSON.stringify(res.data, null, 2))
          setError(res.data.detail)
        } else {
          if(res.data.user.user_type === "resident") {
            window.open("https://testflight.apple.com/join/2dYFoGaU", "_blank");
          } else if(res.data.user.user_type === "visitor") {
            window.open("https://testflight.apple.com/join/2dYFoGaU", "_blank");
          } else {
            props.history.push("/")
          }
        }
      }).catch((err) => {
       
        setError("Try with a different link. Something went wrong.")
      }
      )
  }

  return (
    <div className="main">
       <ToastContainer />
      <div className="img_div">
        <img
          className="forest_img"
          src={ForestClove}
        />
      </div>
      <div className="white_box">
        <div className="all_texts">
          <h5 className="welcome_text">Welcome</h5>
          <p className="email">{email}</p>
        </div>

        <div className="all_texts">
          <p className="">{error}</p>
        </div>

        <div>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="label_create_password">
                Create Password:
              </Form.Label>
              <div className="input_div_login">
                <Form.Control
                  className="input_create_password_login"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                  value={password}
                />
                <div className="eye_div">
                  <span className="dot" />
                  <img 
                    src={EyeSign} 
                    onClick={()=> {
                      setShowPassword(!showPassword);
                      console.log(showPassword);
                    }}
                  />
                </div>
              </div>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="label_create_password">
                Confirm Password:
              </Form.Label>
              <div className="input_div_login">
                <Form.Control
                  className="input_create_password_login"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(event) => {
                    setConfirmPassword(event.target.value)
                  }}
                  value={confirmPassword}
                />
                <div className="eye_div">
                  <span className="dot" />
                  <img 
                    src={EyeSign} 
                    onClick={()=> {
                      setShowConfirmPassword(!showConfirmPassword);
                      console.log(showPassword);
                    }}
                  />
                </div>
              </div>
            </Form.Group>
            <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
              {passwordError ? <p style={{fontSize: "13px", paddingLeft: "5px", color: "red"}}>{passwordError}</p> : ""}
            </div>
            

            <Button
              onClick={(event) => {
                event.preventDefault()
                buttonClicked()
              }}
              className="save_btn"
              variant="primary"
              type="submit"
            >
              SAVE
            </Button>
          </Form>
        </div>
      </div>
      <div>
        <img
          className="kepah_img"
          src={Kepah}
        />
      </div>
     
    </div>
  );
};

export default Welcome;
