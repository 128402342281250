import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import DailySchedule2 from "./DailySchedule/DailySchedule2";
import ListOfOfficersSchedule from "./ListOfOfficersSchedule/ListOfOfficersSchedule";
import OfficersManagementCalendar from "./OfficersManagementCalendar/OfficersManagementCalendar";
import ChooseDate from "./MakeASchedule/MakeASchedule";

import "./style.css";

const Dashboard = ({ ...props }) => {
  const [chooseDate, setChooseDate] = useState(false);
  const [officerId, setOfficerId] = useState(null);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [dateSelected2, setDateSelected2] = useState(new Date());
  const [custom, setCustom] = useState(false);


  useEffect(() => {
    console.log("Date Selected 2 changed:", dateSelected2)
  }, [dateSelected2])

  const setDateSelectedTwo = (value) => {
    console.log("Value:", value)
    setDateSelected2(new Date(value))

  }

  const changeToCustom = () => {
    console.log("Custom set to true")
    setCustom(true);
  }
  const changeToDaily = () => {
    console.log("Custom set to false")
    setCustom(false);
  }
 
  const clickDate = (e) => {
    if (e && e !== undefined) {
      setDateSelected(e);
      console.log("WDFTag:",e)
    }
  };
  const clickDate2 = (e) => {
    if (e && e !== undefined) {
      setDateSelected2(e);
    }
  };

  const closeCalendar = () => {
    setChooseDate(false);
  };

  const openCalendar = (id) => {
    console.log("Officer_ID",id)
    setOfficerId(id);
  };
  return (
    <>
    {chooseDate ? <ChooseDate officerId={officerId} closeCalendar={closeCalendar} history={props.history} /> :
    <div className="main-officer_schedule">
      <div className="side_nav">
        <Sidebar history={props.history} nav="Officers Schedule" />
      </div>
        <div className="all_components_schedule">
          <div className="officer_daily_schedule">
            <div className="officer_management_calendar">
              <OfficersManagementCalendar
                dateSelected={dateSelected}
                setDateSelected={setDateSelected}
                setDateSelected2={setDateSelected2}
                dateSelected2={dateSelected2}
                clickDate={clickDate}
                clickDate2={clickDate2}
                custom={custom}
              />
            </div>
            <div className="daily_schedule">
              <DailySchedule2 changeToCustom={changeToCustom} changeToDaily={changeToDaily} dateSelected={dateSelected} setDateSelectedTwo={setDateSelectedTwo} />
            </div>
          </div>
          <div className="list_of_officers_schedule">
            <ListOfOfficersSchedule
              history={props.history}
              openCalendar={openCalendar}
              dateSelected={dateSelected}
              dateSelected2={dateSelected2}
            />
          </div>
        </div>
    </div>}
    </>
  );
};

export default Dashboard;
