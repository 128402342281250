import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { Popover, OverlayTrigger, Form, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import searchImage from "../../../assets/Search.png"
import personImage from "../../../assets/profile.png"

import downloadIcon from "../../../assets/incident-download-icon.png"
import downloadDash from "../../../assets/incident-download-dash.png"

import { format, parseISO } from 'date-fns';

const VehicleActionLogs = ({ ...props }) => {
  const [showTagInfo, setShowTagInfo] = useState(null);
  const [workStatus, setWorkStatus] = useState(false);
  const [reportType, setReportType] = useState("");
  const [actionLogs, setVehicleActionLogs] = useState([]);
  const [token, setToken] = useState(null);
  const [vehicleStatus] = useState(["", "Booted", "Towed"]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");
  const [popOverDetails, setPopOverDetails] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const colorMapping = {
    "illegally_parked": "#FB8787",
    "flat_tire": "#71BB81",
    "inoperable_vehicle": "#5D98DE",
    "suspicious_vehicle": "#D7C2A3",
    "abandon_vehicle": "#FFCF88",
    "remove_vehicle": "#40323e",
    "damaged_vehicle": "#d9340d",
    "no_tag_on_vehicle": "#254473",
    "vehicle_in_fire_lane": "#EEEDED",
  };


  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  const formatDate = (date) => {
    const joinedDateISO = date; // "2023-06-25T21:20:45.302375Z"
    const parsedJoinedDate = parseISO(joinedDateISO);

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedJoinedDate = format(parsedJoinedDate, 'yyyy-MM-dd  h:mm a');
    return formattedJoinedDate
  }

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        popoverOpen &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setPopoverOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [popoverOpen]);


  const managerToken = localStorage.getItem("token")

  const getVehicleActionLog = (buildingno, _token) => {
    var config = {
      method: "get",
      url: `https://kepah-24275.botics.co/api/v1/admin/vehicle/action-logs/`,
      headers: {
        Authorization: `token ${managerToken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function(response) {
        let actionLogs = response.data;
        console.log(response, "reeessponse");
    
        setVehicleActionLogs(actionLogs);
        setFiltered(actionLogs);
      })
      .catch(function(error) {
        console.log(error, "It's a error!!!");
      });
  };





  var configDownload = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/vehicle/download-vehicle-action-logs/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  const downloadClick = (e) => {
    e.preventDefault();
    axios(configDownload)
      .then((res) => {
        // Check if the response contains data
        if (res.data) {
          // Prepare the file data and create a Blob object
          const blob = new Blob([res.data], { type: "application/octet-stream" });
  
          // Create a temporary URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
  
          // Create a link element
          const link = document.createElement("a");
          link.href = blobUrl;
  
          // Set the download attribute and suggested file name
          link.download = "vehicle-log.csv"; // Replace with the desired file name
  
          // Simulate a click on the link to trigger the download
          link.click();
  
          // Clean up the created URL
          URL.revokeObjectURL(blobUrl);
        }
      })
      .catch((error) => {
        console.error("Error downloading:", error);
      });
  };

  const updateParkingViolation = (status, vehicle_id, index) => {
    let token = localStorage.getItem("token");
    let data = JSON.stringify({
      status: status,
    });

    let config = {
      method: "patch",
      url: `https://kepah-24275.botics.co/api/v1/illegal-parking/${vehicle_id}/`,
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // console.log(response.data)
        actionLogs.splice(index, 1, response.data);

        setVehicleActionLogs((actionLogs) => [...actionLogs]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getVehicleActionLog(
      localStorage.getItem("buildingno"),
      localStorage.getItem("token")
    );
    setToken(localStorage.getItem("token"));
  }, [localStorage.getItem("token") && localStorage.getItem("buildingno")]);

  useEffect(() => {
    console.log(search)
    let filterd = actionLogs.filter((val) => {
      
      if(val.user !== null ) {
        console.log(val.user.name)
        if (search !== "" && val.user && val.user.name.toLowerCase().includes(search.toLowerCase())) {
          return val;
        }
        if(search !== "" && val.vehicle_number && val.vehicle_number.toLowerCase().includes(search.toLowerCase())) {
          return val;
        }
      }
      
    });
    if (filterd.length < 1 && search === "") {
      setFiltered(actionLogs);
    } else {
      console.log("FILTERING",filterd)
      setFiltered(filterd);
    }
  }, [search]);

  const popover = (
    <Popover id="popover-basic" className="profInfo pb-3">
      {popOverDetails != null && (
        <Container>
          <Row className="pt-3">
            <Col className="col-md-4">
              {popOverDetails.profile_picture ? (
                <img
                  style={{ height: "64px", width: "64px", borderRadius: "50%" }}
                  src={popOverDetails.profile_picture}
                />
              ) : (
                <img
                  style={{ height: "64px", width: "64px", borderRadius: "50%" }}
                  src={personImage}
                />
              )}
            </Col>
            <Col className="col-md-8 mt-3 nameBold">
              {popOverDetails.name}
            </Col>
            
          </Row>
          <Row className="ml-3 mt-3">
            {popOverDetails.email}
          </Row>
          <Row className="ml-3 mt-2">
            {popOverDetails.phone_number}
          </Row>
        </Container>
      )}
    </Popover>
  );


  return (
    <div>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">
            Vehicle Action Logs ({actionLogs.length})
          </span>
        </div>
      </div>
      

      <div className="criminal_input_div">
      <div className="vehicle_action_input_div">
        <input
          type="text"
          className="list_of_officers_input"
          placeholder="Name or License No...."
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="criminal_search_btn">
          <img src={searchImage} />
        </div>
      </div>
        <Form className="form_inline_criminal">
          <Button
            className="save_btn_add_criminal"
            variant="primary"
            onClick={(e) => {
              e.preventDefault()
              localStorage.setItem("vehicleActionAdd", JSON.stringify(true))
              props.history.push("/incidentreports/auto")
            }}
          >
            REPORT A VEHICLE
          </Button>
        </Form>

        <Form className="downloadVehicleLog">
            <Button
              className="download_btn_incident"
              variant="primary"
              type="submit"
            >
              <div className="download_icon_div">
                <img
                  src={downloadIcon}
                />
                <img
                  src={downloadDash}
                />
              </div>
              <span onClick={downloadClick} className="download_text pl-1">DOWNLOAD</span>
            </Button>
          </Form>
      </div>

      

      <div className="license_scroll_div_vehicle_page">
        {filtered.map((val, ind) => {
          return (
            <>
              <div key={ind} className="vehicle_actions_logs_all">
                <span className="date_time">
                  {formatDate(val.created)}
                </span>
                {/* 
                <div className="booted_text">
                  <img
                    className="vehicle_profile_img"
                    src={require("../../../assets/profile.png").default}
                  />
                </div> */}
                <span className="booted_text"></span>

                {/* <span className="booted_text">{val.status}</span> */}
                {/* <span className="booted_text">Booted</span> */} 
                

                <div className="taginfo_width">

                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={popover}
                    rootClose
                    onToggle={() => {
                      setPopoverOpen(!popoverOpen)
                      setPopOverDetails(val.user)
                    }}
                    ref={popoverRef}
                  >
                    <span className="vehicleNameXX">
                      {val.vehicle_number}
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="owner_name_div">
                  <span className="owner_name_text">Owner:</span>
                  <span className="resident_profile_name_text">
                    {val.user && val.user.name ? val.user.name : ""}
                  </span>
                </div>
                {/* <OverlayTrigger
                  trigger="click"
                  placement="left"
                  overlay={popover2}
                  show={showReport === ind + 1 ? true : false}
                  onToggle={() => setShowReport(ind + 1)}
                >
                  <div className="round_back_div">
                    <img
                      className="round_background"
                      src={require("../../../assets/round.png").default}
                    />
                  </div>
                </OverlayTrigger> */}

                <div className="vehicle_report_div">
                  {/* <div
                    className="report_div"
                    onClick={() =>
                      setWorkStatus(workStatus === val ? null : val)
                    }
                  >
                    <span>
                      {val.status
                        ? vehicleStatus[val.status]
                        : "No action taken"}
                      {reportType !== "" ? reportType : "Select report"}
                    </span>
                    {workStatus === val ? (
                      <img
                        className="up_arrow_img"
                        src={require("../../../assets/up-arrow.png").default}
                      />
                    ) : (
                      <img
                        src={require("../../../assets/scroll-down.png").default}
                      />
                    )}
                  </div> */}
                  <div className="bgRound">
                    <div style={{ height: "16px", width: "16px", borderRadius: "50%", backgroundColor: colorMapping[val.status]  }}>
                    
                    </div>
                  </div>
                  

                  {/* {workStatus === val ? (
                    <div>
                      <div
                        className="report_choose"
                        onClick={() => {
                          setWorkStatus(false);
                          setReportType("Booted");
                          updateParkingViolation(1, val.id, ind);
                        }}
                      >
                        <span>Booted</span>
                      </div>
                      <div
                        className="report_choose1"
                        onClick={() => {
                          setWorkStatus(false);
                          setReportType("Towed");
                          updateParkingViolation(2, val.id, ind);
                        }}
                      >
                        <span>Towed</span>
                      </div>
                      <div
                        className="report_choose2"
                        onClick={() => {
                          setWorkStatus(false);

                          setReportType("No action taken");
                          updateParkingViolation(null, val.id, ind);
                        }}
                      >
                        <span>No action taken</span>
                      </div>
                    </div>
                  ) : null} */}
                </div>
              </div>
              <div className="bottom_border_vehicle_page" />
            </>
          );
        })}

        {showTagInfo ? (
          <div
            onClick={() => {
              setShowTagInfo(false);
            }}
            className="incident-class"
          ></div>
        ) : null}

        {/* <div className="vehicle_actions_logs_all">
          <span className="date_time">6/25/2021 - 14:53</span>
          <span className="booted_text">Towed</span>

          <span className="license_main_page">Tag Info: 123SDF</span>
          <div className="owner_name_div">
            <span className="owner_name_text">Report Owner:</span>
            <span className="resident_profile_name_text">
              John Doe (Resident)
            </span>
          </div>

          <div className="round_back_div">
            <img
              className="round_background"
              src={require("../../../assets/round.png").default}
            />
          </div>
        </div>
        <div className="bottom_border_vehicle_page" />

        <div className="vehicle_actions_logs_all">
          <span className="date_time">6/25/2021 - 14:53</span>
          <span className="booted_text">No action taken</span>

          <span className="license_main_page">Tag Info: 123SDF</span>
          <div className="owner_name_div">
            <span className="owner_name_text">Report Owner:</span>
            <span className="resident_profile_name_text">
              John Doe (Resident)
            </span>
          </div>

          <div className="round_back_div">
            <img
              className="round_background"
              src={require("../../../assets/round.png").default}
            />
          </div>
        </div>
        <div className="bottom_border_vehicle_page" />

        <div className="vehicle_actions_logs_all">
          <span className="date_time">6/25/2021 - 14:53</span>
          <span className="booted_text">Towed</span>

          <span className="license_main_page">Tag Info: 123SDF</span>
          <div className="owner_name_div">
            <span className="owner_name_text">Report Owner:</span>
            <span className="resident_profile_name_text">
              John Doe (Resident)
            </span>
          </div>

          <div className="round_back_div">
            <img
              className="round_background"
              src={require("../../../assets/round.png").default}
            />
          </div>
        </div>
        <div className="bottom_border_vehicle_page" />

        <div className="vehicle_actions_logs_all">
          <span className="date_time">6/25/2021 - 14:53</span>
          <span className="booted_text">Booted</span>

          <span className="license_main_page">Tag Info: 123SDF</span>
          <div className="owner_name_div">
            <span className="owner_name_text">Report Owner:</span>
            <span className="resident_profile_name_text">
              John Doe (Resident)
            </span>
          </div>

          <div className="round_back_div">
            <img
              className="round_background"
              src={require("../../../assets/round.png").default}
            />
          </div>
        </div>
        <div className="bottom_border_vehicle_page" />

        <div className="vehicle_actions_logs_all">
          <span className="date_time">6/25/2021 - 14:53</span>
          <span className="booted_text">Booted</span>

          <span className="license_main_page">Tag Info: 123SDF</span>
          <div className="owner_name_div">
            <span className="owner_name_text">Report Owner:</span>
            <span className="resident_profile_name_text">
              John Doe (Resident)
            </span>
          </div>

          <div className="round_back_div">
            <img
              className="round_background"
              src={require("../../../assets/round.png").default}
            />
          </div>
        </div>
        <div className="bottom_border_vehicle_page" />

        <div className="vehicle_actions_logs_all">
          <span className="date_time">6/25/2021 - 14:53</span>
          <span className="booted_text">Booted</span>

          <span className="license_main_page">Tag Info: 123SDF</span>
          <div className="owner_name_div">
            <span className="owner_name_text">Report Owner:</span>
            <span className="resident_profile_name_text">
              John Doe (Resident)
            </span>
          </div>

          <div className="round_back_div">
            <img
              className="round_background"
              src={require("../../../assets/round.png").default}
            />
          </div>
        </div>
        <div className="bottom_border_vehicle_page" /> */}
      </div>
    </div>
  );
};

export default VehicleActionLogs;
