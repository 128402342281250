import React from 'react';
import "./BackButton.css"

const BackButton = ({...props}) => {

    const backButtonStyle = {
        position: 'fixed', // Position the button relative to the viewport
        top: '20px', // Adjust the distance from the top
        right: '20px', // Adjust the distance from the right
        zIndex: 999, // Ensure it's on top of other content
        backgroundColor: 'blue', // Customize the button's appearance
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
      };
  
  return (
    <button style={backButtonStyle} onClick={() => props.closeFunction()} className="back-button">
      Back
    </button>
  );
};

export default BackButton;