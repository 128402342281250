import React, { useEffect, useState } from "react";
import "./style.css";
import Sidebar from "../Sidebar/Sidebar";
import IncidentReport from "./IncidentReportsPage/IncidentReport";
import AddIncidentReport from "./AddIncidentReport/AddIncidentReport";
import IncidentReportTemplatePt2 from "./AddIncidentReport/AddIncidentReportPt2";
import { Popover, OverlayTrigger, Container, Row, Col } from "react-bootstrap";
import dropdown from "../../assets/dropdown.svg"
import AutoReportTemplate from "./AddIncidentReport/AutoReport";
import TowReportTemplate from "./AddIncidentReport/TowReportTemplate";

const Caseload = ({ ...props }) => {
  const [addIncident, setAddIncident] = useState(false)
  const [addAuto, setAddAuto] = useState(false)
  const [addTow, setAddTow] = useState(false)
  const [addIncidentPt2, setAddIncidentPt2] = useState(false)
  const [dropdownOn, setDropdownOn] = useState(false)
  const [forceRender, setForceRender] = useState(false);

  const vehicleActionAdd = JSON.parse(localStorage.getItem("vehicleActionAdd"));

  const addIncidentReport = () => {
    console.log("add inc")
    setAddIncident(true)
    setAddTow(false)
    setAddAuto(false)
    setAddIncidentPt2(false)
  }
  const addTowReport = () => {
    console.log("add tow")
    localStorage.setItem("vehicleActionAdd", JSON.stringify(false))
    setAddTow(true)
    setAddIncident(false)
    setAddAuto(false)
    setAddIncidentPt2(false)
  }

  const addAutoReport = () => {
    console.log("add auto")
    setAddAuto(true)
    setAddIncident(false)
    setAddIncidentPt2(false)
    setAddTow(false)
  }

  const closeAddIncidentReport = () => {
    setAddIncident(false)
    setAddIncidentPt2(false)
  }

  const closeAutoReport = () => {
    localStorage.setItem("vehicleActionAdd", JSON.stringify(false))
    setAddAuto(false)
    setForceRender(prev => !prev);
  }
  const closeAddTow= () => {
    setAddTow(false)
  }

  const addIncidentReportPt2 = () => {
    setAddIncidentPt2(true)
    setAddIncident(false)
    setAddTow(false)
    setAddAuto(false)
  }

  const handleReportTypeChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "incident") {
        addIncidentReport();
    } else if (selectedValue === "auto") {
        addAutoReport();
    } else if (selectedValue === "tow") {
        addTowReport();
    }
  };

  const handleReportTypeChangeAuto = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "incident") {
        addIncidentReport();
    } else if (selectedValue === "auto") {
        setAddTow(true)
        setAddIncident(false)
        setAddAuto(false)
        setAddIncidentPt2(false)
        
    } else if (selectedValue === "tow") {
        addTowReport();
        
    }
  };

  if (true) {
    return (
      <div className="incident_back">
        <div className="side_nav">
          <Sidebar history={props.history} nav="Incident Reports" />
        </div>
        <div className="incident_action_main_div">
          <div className="incident_action_main_page">
            <IncidentReport history={props.history} addIncidentReport={addIncidentReport} />
          </div>
        </div>
      </div>
    );
  } else if (addIncident) {
    return (
     
            <AddIncidentReport 
              history={props.history}
              closeAddIncidentReport={closeAddIncidentReport}
              addIncidentReportPt2={addIncidentReportPt2} 
              addAutoReport={addAutoReport} 
              addTowReport={addTowReport} 
              />
    )
  } else if (addIncidentPt2) {
    return (
      <IncidentReportTemplatePt2 history={props.history} closeAddIncidentReport={closeAddIncidentReport} />
    )
  } else if (addAuto || vehicleActionAdd) {
    return (
      <AutoReportTemplate history={props.history} addTowReport={addTowReport} closeAutoReport={closeAutoReport}/>
    )
  } else if (addTow) {
    return (
      <TowReportTemplate history={props.history} closeAddTow={closeAddTow}/>
    )
  }
};

export default Caseload;
