import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";

const EditVendor = ({ ...props }) => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("")

  const [user, setUser] = useState([]);
  const [ten, setTen] = useState("");

  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [updateUser, setUpdateUser] = useState(null);

  const [showDropdown, setShowDropdown] = useState(true);


  const [errorFlag, setErrorFlag] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [buttonClickedX, setButtonClickedX] = useState(false);

  const [id, setId] = useState("")
  const [userSelect, setUserSelect] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [arivalTime, setArivalTime] = useState("")
  const [departureTime, setDepartureTime] = useState("")

  const closeFunction = () => {
    props.history.goBack()
  }

  const notify = () => toast.success('Successfully Added!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError = () => toast.error('Error Happened!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyErrorRequired = () => toast.error('Fields marked * are required!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyLoading = () => toast.info('Loading', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notifyPhotoAdded = () => toast.info('Image successfully Added', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  useEffect(() => {
    const managerInfoChangeString = localStorage.getItem("vendorInfoChange");
    const managerInfoChange = JSON.parse(managerInfoChangeString);

    if (managerInfoChange) {
      setId(managerInfoChange.id)
      setName(managerInfoChange.name)
      setEmail(managerInfoChange.email)
      setCompanyName(managerInfoChange.company)
      setPhone(managerInfoChange.phone_number)
      if (managerInfoChange.arrival_time) {
        setArivalTime(managerInfoChange.arrival_time.substring(0, 5))
      }
      if (managerInfoChange.departure_time) {
        setDepartureTime(managerInfoChange.departure_time.substring(0, 5))
      }

    }
    console.log(managerInfoChange.profile_picture)

  }, []);


  const validateFe = () => {
    setErrorFlag(false)
    if (name === "" || email === "" || companyName === "" || phone === "" || arivalTime === "" || departureTime === "") {
      setErrorFlag(true)
      notifyErrorRequired()
    }
  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return phoneNumber;

    let formattedNumber = "";

    if (match[1]) {
      formattedNumber += `(${match[1]})`;
    }

    if (match[2]) {
      formattedNumber += ` ${match[2]}`;
    }

    if (match[3]) {
      formattedNumber += `-${match[3]}`;
    }

    return formattedNumber;
  };

  useEffect(() => {
    setErrorFlag(false)
    setButtonClickedX(false)
    const info = {
      name: name,
      email: email,
      phone_number: phone,
      company: companyName,
      arrival_time: arivalTime,
      departure_time: departureTime
    }

    setVehicleInfo(info)
  }, [name, email, phone, companyName, arivalTime, departureTime]);

  const managerToken = localStorage.getItem("token")

  var userApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/users/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  const handleDropdownChange = (e) => {
    console.log(e.target.value)
    setUserSelect(e.target.value);
  };


  var config = {
    method: "put",
    url: `https://kepah-24275.botics.co/api/v1/admin/vendors/${id}/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
    data: vehicleInfo
  };

  useEffect(() => {
    if (buttonClickedX && !errorFlag) {
      notifyLoading()
      axios(config)
        .then((res) => {
          console.log(res)
          if (res.status == 200) {
            notify()
            setTimeout(() => {
              props.history.goBack()
            }, 5000);
          }
        })
        .catch((error) => {
          notifyError()
          console.log(error.response.data)
        });
    }
  }, [buttonClicked, errorFlag])

  function handleTimeChange(e) {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, '');

    // Format the input as HH:MM
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2);
    }

    // Update the input field's value
    e.target.value = inputValue;
    setArivalTime(inputValue)
  }

  function handleTimeChange2(e) {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, '');

    // Format the input as HH:MM
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2);
    }

    // Update the input field's value
    e.target.value = inputValue;
    setDepartureTime(inputValue)
  }

  return (
    <div className="incident_back">
      <div className="side_nav">
        <Sidebar history={props.history} nav="List Of Vendors" />
      </div>
      <div className="incident_action_main_div">
        <div className="vendorEditPage">
          <div className="add_new_resident_back ">
            <div className="add_new_resident_background_color">
              <div className="add_new_resident_name_img_div">
                <h3 className="add_new_resident_text">Edit Vendor</h3>
                <BackButton closeFunction={closeFunction} />
                {/* <label htmlFor="upload-photo" className="upload_photo_text pt-1">
            {vehiclePhoto != null ? vehiclePhoto.name : "Upload Vehicle Photo"}
          </label>
          <input
            type="file"
            id="upload-photo"
            style={{ display: "none" }}
            onChange={(event) => {
              const selectedFile = event.target.files[0];
              setVehiclePhoto(selectedFile);
              notifyPhotoAdded();
            }}
          /> */}

              </div>
              {/* <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
         {errorMessage? <p style={{fontSize: "13px", paddingLeft: "5px", color: "red"}}>{errorMessage}</p>: ""}
        </div> */}
              <div className="input_fields_div">

                {/* <div>
            <Row>
              <Col className="col-md-10">
                <label className="phone_label">Vehicle Owner:</label>
              </Col>
              <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                {errorFlag ? "*" : ""}
              </Col>
            </Row>
            <div className="phone_input_div">
              <div className="phone_input_field_div">

                <select
                  className="phone_input_field"
                  onChange={handleDropdownChange}
                >
                  <option> Select An User </option>
                  {user.map((usr) => (
                    <option value={usr.id} > {usr.name} </option>
                  ))}
                </select>
              </div>
            </div>
          </div> */}

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Name:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={name} className="phone_input_field" type="text" onChange={(e) => setName(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Email:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={email} className="phone_input_field" type="text" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Phone:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={phone} className="phone_input_field" type="text" onChange={(e) => setPhone(formatPhoneNumber(e.target.value))} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Company:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={companyName} className="phone_input_field" type="text" onChange={(e) => setCompanyName(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Arival Time</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input className="phone_input_field" type="phone" value={arivalTime} onChange={(e) => handleTimeChange(e)} placeholder="12:00" />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Departure Time</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input className="phone_input_field" type="phone" value={departureTime} onChange={(e) => handleTimeChange2(e)} placeholder="12:00" />
                    </div>
                  </div>
                </div>


                <Form className="edit_resident_profile_save_button_div">
                  <Button
                    className="buttonSave"
                    variant="primary"
                    onClick={buttonClicked}
                  >
                    Save
                  </Button>
                </Form>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>

  );
};

export default EditVendor;
