import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Popover, OverlayTrigger, Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dropdown from "../../../assets/dropdown.svg"
import upload from "../../../assets/upload.svg"
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";
import getApi from "../../../config/ApiGet";

const AutoReportTemplate = ({ ...props }) => {
    const [licencePlate, setLicencePlate] = useState("")
    const [userPhoto, setUserPhoto] = useState([]);
    const [photoForm, setPhotoForm] = useState(null);
    const [role, setRole] = useState("")
    const [incidentType, setIncidentType] = useState("")
    const [selectedOption, setSelectedOption] = useState("towed");
    const [photoSelected, setPhotoSelected] = useState(false)
    const [errorState, setErrorState] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Fields marked * are required")
    const [progressMessage, setProgressMessage] = useState("")
    const [buttonClicked, setButtonClicked] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [vehicles, setVehicles] = useState([])
    const [selectedVehicle, setSelectedVehicle] = useState("")
    const [photoRows, setPhotoRows] = useState(0)
    const [vehicleId, setVehicleId] = useState("");
    const [typeDescription, setTypeDescription] = useState("");
    const [vehicleModel, setVehicleModel] = useState("")
    const [vehicleMake, setVehicleMake] = useState("")
    const [vehicleYear, setVehicleYear] = useState("")

    const [violationTypes, setViolationTypes] = useState([])
    const [subViolationTypes, setSubViolationTypes] = useState([])

    const closeFunction = () => {
        props.history.goBack()
    }

    const notify = () => toast.success('Successfully Added!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const notifyError = () => toast.error('Error Happened!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyErrorRequired = () => toast.error('Fields marked * are required!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    const notifyLoading = () => toast.info('Loading', {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const notifyPhotoSelected = () => toast.info('Photos Selected', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const validate = () => {
        setButtonClicked(true)
        setErrorState(false);
        if (selectedVehicle === "") {
            setErrorState(true);
        }
        if (!photoSelected && incidentType !== "towed") {
            setErrorState(true);
        }
        if (role === "") {
            setErrorState(true);
        }
        
    }

    useEffect(() => {
        const x = vehicles.find(obj => String(obj.id) === String(vehicleId));
        if(x !== undefined) {
            setVehicleModel(x.model)
            setVehicleMake(x.make)
            setVehicleYear(x.year)
            setSelectedVehicle(x.vehicle_number)
        }
    }, [vehicleId]);


    const managerToken = localStorage.getItem("token")


    useEffect(() => {
    (async () => {
        const violationType = await getApi("https://kepah-24275.botics.co/api/v1/vehicle/action-log-statuses/");
        console.log("Violation Types", JSON.stringify(violationType, null, 2))
        setViolationTypes(violationType);
    })();
    }, []);

    useEffect(() => {
    (async () => {
        const violationType = await getApi("https://kepah-24275.botics.co/api/v1/admin/violation-type/");
        console.log("Violation Sub Types", JSON.stringify(violationType, null, 2))
        setSubViolationTypes(violationType);
    })();
    }, []);
    
    var vehicleApi = {
        method: "get",
        url: `https://kepah-24275.botics.co/api/v1/admin/vehicle/`,
        headers: {
            Authorization: `token ${managerToken}`,
        },
    }

    useEffect(() => {
        axios(vehicleApi).then((res) => {
            console.log("apt")

            // const numbers = res.data.map((apt) => apt.number);
            // setApartmentNumbers(numbers);
            console.log(res.data)
            setVehicles(res.data);
        })
    }, [])

    useEffect(() => {
        setButtonClicked(false)
        setErrorState(false)
        console.log(userPhoto)
        const formData = new FormData();
        formData.append("license_plate", selectedVehicle);
        formData.append("type", incidentType);
        formData.append("violation_type", role);
        formData.append("type_description", typeDescription);
        
        if (userPhoto) {
            userPhoto.forEach((photo, index) => {
                formData.append(`uploaded_images`, photo);
            });
        } else {
            formData.append("uploaded_images", "");
        }
        console.log("Type", incidentType)
        setPhotoForm(formData)
        
        const photosPerRow = 3;
        const totalPhotos = userPhoto.length;
        const numRows = Math.ceil(totalPhotos / photosPerRow);
        setPhotoRows(numRows);

    }, [userPhoto, incidentType, licencePlate, role, typeDescription]);


    var config = {
        method: "post",
        url: `https://kepah-24275.botics.co/api/v1/admin/auto-report/`,
        headers: {
            Authorization: `token ${managerToken}`
        },
        data: photoForm
    };

    useEffect(() => {
        if (buttonClicked && !errorState && incidentType != "towed") {
            notifyLoading()
            axios(config)
                .then((res) => {
                    if (res.status === 201) {
                        notify();
                        console.log(res.data)
                        setTimeout(() => {
                            props.history.push("/vehicleaction")
                        }, 5000);
                    }
                })
                .catch((error) => {
                    if(error.response && error.response.data) {
                        console.log(error.response.data)
                    }
                    setIsLoading(false)
                    notifyError();
                })
        }

        if (buttonClicked && !errorState && incidentType === "towed") {
            const val = {
                "license_plate": selectedVehicle,
                "vehicleMake": vehicleMake,
                "vehicleModel": vehicleModel,
                "vehicleYear": vehicleYear
            }
            localStorage.setItem("vehicleReportInfo", JSON.stringify(val))
            
            props.history.push("tow")
        }
        if(errorState) {
            notifyErrorRequired()
        }


    }, [buttonClicked, errorState]);

    const handleDropdownChangeVehicle = (event) => {
        setVehicleId(event.target.value)
    };

    const handleDropdownChange = (event) => {
        setRole(event.target.value);
    };

    const handleDropdownChangeType = (event) => {
        console.log(event.target.value)
        setIncidentType(event.target.value);
    };
    const handleReportTypeChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === "incident") {
            props.history.push("incident")
        } else if (selectedValue === "auto") {
            props.history.push("auto")
        } else if (selectedValue === "tow") {
            props.history.push("tow")
        }
      };

    return (
        <div className="incident_back">
        <div className="side_nav">
          <Sidebar history={props.history} nav="Incident Reports" />
        </div>
        <div className="incident_action_main_div2">
          <div className="incident_action_main_page3">
            <div className="">
              <div className="title_add_incident_dd">
                <Row className="">
                  <></>
                  <Col className="col-md-10">
                    <select className="titleDropdown" id="reportType" onChange={handleReportTypeChange}>
                      <option value="auto">Auto Report</option>
                      <option value="tow">Tow Form Template</option>
                      <option value="incident">Incident Report Template</option>
                    </select>
                  </Col>
                  <Col className="col-md-2">
                    <div class="svg-icon">
                    <img style={{ width: "10px", height: "10px" }} src={dropdown} alt="Icon" />
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
              <div>
            <BackButton closeFunction={closeFunction} />
            <div>
                <Container>
                    
                    <Row>
                        <div>
                            <Row>
                                <Col className="col-md-10">
                                    <label className="phone_label">Licence Number:</label>
                                </Col>
                                <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                    {errorState ? "*" : ""}
                                </Col>
                            </Row>

                            <div className="phone_input_div">
                                <div className="phone_input_field_div">

                                    <select
                                        className="phone_input_field"
                                        onChange={handleDropdownChangeVehicle}
                                    >
                                        {/* Render the dropdown options based on the fetched data */}
                                        <option> Select A Number: </option>
                                        {vehicles.map((vehicle) => {
                                            return (
                                                <option value={vehicle.id}> {vehicle.vehicle_number} </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div className="">
                            <Row>
                                <Col className="col-md-10">
                                    <label className="incident_report_label">Select primary reason:</label>
                                </Col>
                                <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                    {errorState ? "*" : ""}
                                </Col>
                            </Row>

                            <div className="bg_of_drop">
                                <div className="phone_input_field_div">

                                    <select
                                        className="phone_input_field_xx"
                                        value={incidentType}
                                        onChange={handleDropdownChangeType}
                                    >
                                        <option value="">Select An Option</option>
                                        {violationTypes && violationTypes.map((v) => (
                                            <option value={v.value}>{v.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div>
                            <Row>
                                <Col className="col-md-10">
                                    <label className="phone_label">Other Reasons:</label>
                                </Col>
                                <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                    {errorState ? "" : ""}
                                </Col>
                            </Row>

                            <div className="phone_input_div">
                                <div className="phone_input_field_div">
                                    <input style={{width: "100%", background: "#ebf4fe", border: "none"}} type="text" onChange={(e)=> setTypeDescription(e.target.value)}/>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <Row>
                                <Col className="col-md-10">
                                    <label className="incident_report_label">Violation:</label>
                                </Col>
                                <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                    {errorState ? "*" : ""}
                                </Col>
                            </Row>

                            <div className="bg_of_drop">
                                <div className="phone_input_field_div">

                                    <select
                                        className="phone_input_field_xx"
                                        value={role}
                                        onChange={handleDropdownChange}
                                    >
                                        <option value="">Select An Option</option>
                                        {subViolationTypes.map((subViolation) => {
                                            return (
                                                <option value={subViolation.id}> {subViolation.name} </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            {incidentType === "towed" ? "" :
                            (
                                <>
                                    <div >
                                        <Row>
                                            <Col className="col-md-10">
                                                <label className="incident_report_label">Upload photo verification:</label>
                                            </Col>
                                            <Col style={{ color: "red" }} className="incident_report_label col-md-2">

                                                {errorState ? "*" : ""}
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="phone_input_field_div_xx">
                                        <label htmlFor="upload-photo">

                                            <input
                                                type="file"
                                                id="upload-photo"
                                                style={{ display: "none" }}
                                                onChange={(event) => {
                                                    const selectedFile = event.target.files[0];
                                                    console.log(selectedFile)
                                                    const selectedFiles = Array.from(event.target.files);
                                                    setUserPhoto(selectedFiles)
                                                    setPhotoSelected(true)
                                                    notifyPhotoSelected()
                                                    
                                                }}
                                                multiple
                                            />
                                            
                                            <img className="uploadImg" src={upload} alt="Upload Icon" />
                                        </label>
                                    </div>
                                    <div className="mt-3">
                                        <Row>
                                            {userPhoto ? userPhoto.map((phto) => (
                                                <Col className="col-md-4">
                                                    <img className="m-1" style={{ width: "100%", height: "80px" }} src={URL.createObjectURL(phto)} alt="Upload Icon" />
                                                </Col>
                                            )): ""}
                                        </Row>
                                    </div>
                                    
                                </>
                            )}
                        </div>

                        <div className="mt-auto ">
                            <Button className="buttonStyleAddAuto" onClick={validate}>CONTINUE</Button>
                        </div>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
    );
};


export default AutoReportTemplate;
