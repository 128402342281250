import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";

import "./style.css";

import ListOfMaintenance from "./MaintenanceList/MaintenanceList";
import MaintenanceProfile from "./MaintenanceProfile/MaintenanceProfile";
import RequestX from "./Request/RequestX";
import axios from "axios";

import ListOfMaintenancePerson from "./MaintenancePersonList/MaintenancePersonList";
import EditMaintenance from "./EditMaintenance/EditMaintenance";

const MaintenanceRequests = ({ ...props }) => {
  const [singlePerson, setSinglePerson] = useState(false);
  const [singlePersonProfile, setSinglePersonProfile] = useState(false);
  const [show, setShow] = useState(false);
  const [editMain, setEditMain] = useState(false)

  const [updatedUserList, setUpdatedUserList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    localStorage.setItem("showProfile", JSON.stringify(false));
  }, [])

  const showPerson = () => {
    setSinglePerson(true)
  }
  
  const showSinglePersonProfile = () => {
    setSinglePersonProfile(true);
  }

  const editMaintenance = () => {
    console.log("WOrking")
    setEditMain(true)
  }

  const closeEditMaintenance = () => {
    console.log("WOrking")
    setEditMain(false)
  }
  
  const closeShowPerson = () => {
    setSinglePerson(false)
  };
  
  const closeSinglePersonProfile = () => {
    setSinglePersonProfile(false);
  }

  const handlePrevious = () => {
    if(currentIndex > 0 ) {
      setCurrentIndex(currentIndex-1);
    } 
    else{
      setCurrentIndex(updatedUserList.length)
    }
  };
  
  const handleNext = () => {
    console.log(currentIndex)
    console.log(updatedUserList.length)
    if(currentIndex < updatedUserList.length) {
      setCurrentIndex(currentIndex+1);
    } 
    else {
      setCurrentIndex(0)
    }
  };
  
  const showProfile = () => {
    setShow(!show)
  }

  useEffect(() => {
    if(singlePerson) {
      const showProf = JSON.parse(localStorage.getItem("maintenanceRequestUser"));
      if(singlePerson) {
        updatedUserList.map((user, key) => {
          if(user.id == showProf.id) {
            setCurrentIndex(key)
          }
        })
      }
    }
  }, [updatedUserList])

  useEffect(() => {
    localStorage.setItem("showProfile", JSON.stringify(show));
  }, [show])

  useEffect(() => {
    let user = updatedUserList[currentIndex]
    if(user != undefined) {
      console.log(user)
      localStorage.setItem("maintenanceRequestUser", JSON.stringify(user));
    }
  }, [currentIndex])

  const managerToken = localStorage.getItem("token");

  useEffect(() => {
    if(singlePerson) {
      const fetchData = async () => {
        try {
          const config = {
            method: "get",
            url: "https://kepah-24275.botics.co/api/v1/admin/maintenance/request/",
            headers: {
              Authorization: `token ${managerToken}`,
              "Content-Type": "application/json",
            },
          };
  
          const res = await axios(config);
          const userListData = res.data;
          setUpdatedUserList(userListData);
  
          //console.log(userListData.findIndex(user => user.id === maintenanceUser.id));
          // if (userIndex !== -1) {
          //   setCurrentIndex(userIndex);
          // }


          if(singlePerson) {
            const showProf = JSON.parse(localStorage.getItem("maintenanceRequestUser"));
            updatedUserList.map((user, key) => {
              if(user.id == showProf.id) {
                setCurrentIndex(key)
              }
            })
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      if (managerToken) {
        fetchData();
      }
    }
  }, [singlePerson]);

  if(!singlePerson && !singlePersonProfile && !editMain) {
    return (
      <div className="main-officer_schedule">
        <div className="side_nav">
          <Sidebar history={props.history} nav="Maintenance Request" />
        </div>
        <div className="update_lease_information">
          <div className="list_of_maintenance_request">
            <ListOfMaintenance history={props.history} showSinglePerson={showPerson} showSinglePersonProfile={showSinglePersonProfile}/>
          </div>
          <div className="list_of_maintenance_request_person">
            <ListOfMaintenancePerson history={props.history} editMaintenance={editMaintenance}/>
          </div>
        </div>
      </div>
    );
  } else if (singlePerson) {
    return (
      <div className="incident_back">
        <div className="side_nav">
          <Sidebar history={props.history} nav="Maintenance Request" />
        </div>
        <div className="maintenance_main_div">
         <div className="maintenance_profile_main_page">
         <MaintenanceProfile showProfile={showProfile} handleNext={handleNext} handlePrevious={handlePrevious} />
         </div>
          <div className="request_page_main_div">
          <RequestX closeShowPerson={closeShowPerson}/>
          </div>
        </div>
      </div>
    );
  } else if (editMain) {
    return (
      <div
      className={props.popup ? "incident_back_popup" : "incident_back"}
      >
        <div className="side_nav">
          <Sidebar history={props.history} nav="Maintenance Request" />
        </div>

        <div className="rent_main_div">
         <EditMaintenance history={props.history} closeEditMaintenance={closeEditMaintenance}/>
        </div>
      </div>
    );
  }
};

export default MaintenanceRequests
