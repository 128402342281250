import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import up_arrow from "../../../assets/up_arrow.svg"
import "./style.css";
import VisitorInfo from "../VisitorInfo/VisitorInfo";
import { Container, Col, Row } from "react-bootstrap";
import personImage from "../../../assets/profile.png"

import axios from "axios";


const ResidentInfo = ({ resident, position, onClose }) => {
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [positionVisitor, setSelectedVisitorPosition] = useState(null);
  const [aptValue, setAptValue] = useState(null);

  const managerToken = localStorage.getItem("token")
  const id = resident.apartment_number;

  var aptValues = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/apartments/${id}/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  useEffect(() => {
    axios(aptValues).then((res) => {
      console.log("apt")
      console.log(res.data)
      // const numbers = res.data.map((apt) => apt.number);
      // setApartmentNumbers(numbers);
      setAptValue(res.data);
    })
  }, [])

  const visitor = {
    name: "Braylon Hensley",
    email: "sha@gma.co",
    phone_number: "(123) 123-1234",
    apartment_number: 1
  }

  const showVisitorInfo = (event) => {
    // Get the position of the clicked apartment number
    const rect = event.currentTarget.getBoundingClientRect();
    const positionV = { x: rect.left + window.scrollX + 70, y: rect.bottom - 250};
    setSelectedVisitor(visitor);
    setSelectedVisitorPosition(positionV);
  };

  const handleResidentInfoClick = (event) => {
    // Prevent event propagation to parent div
    event.stopPropagation();
  };

  const updatePositionX = position.x - 200;
  return (
    <div 
    onClick={handleResidentInfoClick}
    style={{
      position: "absolute",
      left: updatePositionX, // Use the x-coordinate from position prop
      top: position.y, // Use the y-coordinate from position prop
    }}
    className="resident_personal_div">
      <div className="up_arrow">
        <img
          src={up_arrow}
          className="up_arrow_img"
        />
      </div>
      <div className="resident_personal_info">
        <div className="apt_no">
          <p className="apt_no_text">Apt Number {resident.apartment_number}</p>
        </div>
        <div className="content_padding">
          <div className="residient_info">
            <div>
              <p className="resident_info_title_text">Resident Information:</p>
            </div>
            <div className="resident_info_text_image">
              <div className="prof_image">
                  <img
                    src={personImage}
                    className="profile_img_resident_info"
                  />
              </div>
              <div className="prof_info">
                <p className="prof_info_txt_name">{resident.name}</p>
                <p className="prof_info_txt">{resident.email}</p>
                <p className="prof_info_txt">{resident.phone_number}</p>
              </div>
            </div> 
          </div>
        </div>
        <div className="border_div_area">
          <div className="prof_info_border">
          </div>
        </div>
        <div className="content_padding">
          <div className="occupant_title">
            <p className="resident_info_title_occupants">Occupants</p>
          </div>
            <div className="occupants_scroll_wrapper">
              {aptValue && aptValue.occupants.length > 0 ? (
                aptValue.occupants.map((occupant, index) => (
                  <div key={index} className="occupants_info_text_image" id={index}>
                    <div className="prof_image_occupants_div">
                      {occupant.profile_picture ? (
                        <img
                          src={occupant.profile_picture}
                          className="profile_img_occupants_info"
                        />
                      ) : (
                        <img
                          src={personImage}
                          className="profile_img_occupants_info"
                        />
                      )}
                    </div>
                    <div
                      className="prof_info_occupants"
                      onClick={(event) => showVisitorInfo(event)}
                    >
                      <p className="prof_info_txt_name_occupant">{occupant.name}</p>
                      <p className="prof_info_txt_occupant_show">{occupant.emergency_contact ? "Emmergency Person": ""}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No occupants available.</p>
              )}
            </div>
        </div>

        <div className="border_div_area">
          <div className="prof_info_border">
          </div>
        </div>

        <div className="content_padding">
          <div className="occupant_title">
            <p className="resident_info_title_occupants">Visitors</p>
          </div>
          
          <div className="occupants_scroll_wrapper">
              {aptValue && aptValue.visitors.length > 0 ? (
                aptValue.visitors.map((occupant, index) => (
                  <div key={index} className="occupants_info_text_image" id={index}>
                    <div className="prof_image_occupants_div">
                      {occupant.profile_picture ? (
                        <img
                          src={occupant.profile_picture}
                          className="profile_img_occupants_info"
                        />
                      ) : (
                        <img
                          src={personImage}
                          className="profile_img_occupants_info"
                        />
                      )}
                    </div>
                    <div
                      className="prof_info_occupants"
                      onClick={(event) => showVisitorInfo(event)}
                    >
                      <p className="prof_info_txt_name_occupant">{occupant.name}</p>
                      <p className="prof_info_txt_occupant_show">{occupant.emergency_contact ? "Emmergency Person": ""}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No occupants available.</p>
              )}
            </div>
        </div>
        {selectedVisitor && (
          <div>
            <VisitorInfo
              className=""
              resident={selectedVisitor}
              position={positionVisitor}
              onClose={() => setSelectedVisitor(null)} // Function to close ResidentInfo
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResidentInfo;