import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Popover, OverlayTrigger, Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dropdown from "../../../assets/dropdown.svg"
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Sidebar from "../../../utils/TimelineGraph/lib/layout/Sidebar";


const IncidentReportTemplatePt2 = ({ ...props }) => {
    const [role, setRole] = useState("")
    const [incidentType, setIncidentType] = useState("")

    const [policeReportFiled, setPoliceReportFiled] = useState("")
    const [precinct, setPrecinct] = useState("")
    const [reportingOfficer, setReportingOfficer] = useState("")
    const [reportingOfficerPhone, setReportingOfficerPhone] = useState("")
    const [followUpAction, setFollowUpAction] = useState("")
    const [supervisorName, setSupervisorName] = useState("")
    const [supervisorSign, setSupervisorSign] = useState("")
    const [supervisorDate, setSupervisorDate] = useState("")
    const [policeMatter, setPoliceMatter] = useState(false)

    const [errorMessage, setErrorMessage] = useState("Fields marked * are required")
    const [progressMessage, setProgressMessage] = useState("")
    const [errorState, setErrorState] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)

    const incidentReportTemplate = JSON.parse(localStorage.getItem("incidentReportTemplate"));

    const notify = () => toast.success('Successfully Added!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const notifyError = () => toast.error('Error Happened!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyLoading = () => toast.info('Loading', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const [incidentReport, setIncidentReport] = useState(() => {
        const initialIncidentReport = {
            reported_by: incidentReportTemplate.reportedBy,
            reporter_role: incidentReportTemplate.reporterRole,
            report_date: incidentReportTemplate.dateOfReport,
            incident_type: incidentReportTemplate.incidentType,
            incident_date: incidentReportTemplate.dateOfIncident,
            incident_location: incidentReportTemplate.incidentLocation,
            incident_city: incidentReportTemplate.incidentCity,
            incident_state: incidentReportTemplate.incidentState,
            incident_zip_code: incidentReportTemplate.incidentZip,
            incident_specific_area: incidentReportTemplate.incidentSpecificArea,
            incident_summary: incidentReportTemplate.incidentDescription,
            party_invlolved_name: incidentReportTemplate.partyName,
            party_involved_role: incidentReportTemplate.partyRole,
            party_involved_phone: incidentReportTemplate.partyContact,
            witness_name: incidentReportTemplate.witnessName,
            witness_role: incidentReportTemplate.witnessRole,
            witness_phone: incidentReportTemplate.witnessContact,
        };
        return initialIncidentReport;
    });

    useEffect(() => {
        setButtonClicked(false)
        setErrorState(false)
        const updatedIncidentReport = {
            ...incidentReport,
            emergency_person_name: precinct,
            agency_name: reportingOfficer,
            police_phone: reportingOfficerPhone,
            police_follow_up_action: followUpAction,
            police_supervisor_name: supervisorName,
            police_call: policeMatter
        };
        setIncidentReport(updatedIncidentReport);
    }, [
        precinct,
        reportingOfficer,
        reportingOfficerPhone,
        followUpAction,
        supervisorName,
        policeMatter
    ]);

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, "");
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (!match) return phoneNumber;

        let formattedNumber = "";

        if (match[1]) {
            formattedNumber += `(${match[1]})`;
        }

        if (match[2]) {
            formattedNumber += ` ${match[2]}`;
        }

        if (match[3]) {
            formattedNumber += `-${match[3]}`;
        }

        return formattedNumber;
    };

    const validate = () => {
        setButtonClicked(true)
        console.log(incidentReportTemplate)
        setErrorState(false); // Reset the error state before validating

        console.log("Emergency:", precinct);
        console.log("Agency:", reportingOfficer);
        console.log("reportingOfficerPhone:", reportingOfficerPhone);
        console.log("followUpAction:", followUpAction);
        console.log("supervisorName:", supervisorName);
        console.log("PoliceMatter:", policeMatter);

        if (
            reportingOfficerPhone === "" || followUpAction === "" || supervisorName === ""
        ) {
            setErrorState(true);
        }
    };

    useEffect(() => {
        if (buttonClicked && !errorState) {
            notifyLoading()
            const managerToken = localStorage.getItem("token")

            var config = {
                method: "post",
                url: `https://kepah-24275.botics.co/api/v1/admin/incident-report/`,
                headers: {
                    Authorization: `token ${managerToken}`
                },
                data: incidentReport
            };
            axios(config).then((res) => {
                if (res.status == 201) {
                    notify()
                    console.log(res.data)
                    setProgressMessage("Successfully Added!")
                    localStorage.setItem("incidentReportTemplate", JSON.stringify(null));
                    setTimeout(() => {
                        props.history.goBack();
                    }, 5000);
                }
            }).catch((err) => {
                notifyError()
                setErrorMessage("Error, Please try again!")
            })
        }
    }, [errorState, buttonClicked])



    const handleDropdownChange = (event) => {
        setRole(event.target.value);
    };

    const handleDropdownChangeIncident = (event) => {
        setIncidentType(event.target.value);

    };

    return (
        <div className="tt">
            <div>
                <Container>
                    <Row>
                        <label className="incident_report_label">{errorState ? errorMessage : ""} </label>
                    </Row>
                    <Row>
                        <Col className="col-md-4">
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Police Call:</label>
                                    </Col>

                                </Row>

                                <div className="phone_input_div bg_of_drop">
                                    <Row>
                                        <Col>
                                            <div className="radio">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    checked={policeMatter === true}
                                                    onClick={() => setPoliceMatter(true)}
                                                />
                                                <label className="yes-text">Yes</label>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="radio">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    checked={policeMatter === false}
                                                    onClick={() => setPoliceMatter(false)}
                                                />
                                                <label className="yes-text">No</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            {policeMatter ? (
                                <>
                                    <div className="inputWidth">
                                        <Row>
                                            <Col className="col-md-10">
                                                <label className="incident_report_label">Police or Emergency Person Name</label>
                                            </Col>
                                            <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                {errorState ? "*" : ""}
                                            </Col>
                                        </Row>

                                        <div className="phone_input_div bg_of_drop">
                                            <div className="phone_input_field_div">
                                                <input className="phone_input_field" type="text" onChange={(e) => setPrecinct(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inputWidth">
                                        <Row>
                                            <Col className="col-md-10">
                                                <label className="incident_report_label">Agency Name:</label>
                                            </Col>
                                            <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                {errorState ? "*" : ""}
                                            </Col>
                                        </Row>

                                        <div className="phone_input_div bg_of_drop">
                                            <div className="phone_input_field_div">
                                                <input className="phone_input_field" type="text" onChange={(e) => setReportingOfficer(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : ""}
                        </Col>

                        <Col className="col-md-4">
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Phone:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div bg_of_drop">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" value={reportingOfficerPhone} onChange={(e) => setReportingOfficerPhone(formatPhoneNumber(e.target.value))} />
                                    </div>
                                </div>
                            </div>

                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Follow Up Action:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" onChange={(e) => setFollowUpAction(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Supervisor Name:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" onChange={(e) => setSupervisorName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-md-4 d-flex flex-column">
                            <div className="mt-auto inputWidth">
                                <Button className="buttonStyleAddIncident" onClick={validate}>SUBMIT</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
        </div>
    );
}

export default IncidentReportTemplatePt2;