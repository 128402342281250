import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Popover, OverlayTrigger, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { format } from 'date-fns';

import downloadIcon from "../../../assets/incident-download-icon.png"
import downloadDash from "../../../assets/incident-download-dash.png"
import textImage from "../../../assets/text.png";
import previousImage from "../../../assets/previous.png"
import nextImage from "../../../assets/next.png"
import upImage from "../../../assets/up-arrow.png";
import downImage from "../../../assets/scroll-down.png"


const IncidentReport = (props) => {
  const [status] = useState(["Filled", "Active", "Closed"]);
  const [policeMatter, setPoliceMatter] = useState("");
  const [incidentReports, setIncidentReports] = useState(null);
  const [policeCall, setPoliceCall] = useState(true);
  const [incidentStatus, setIncidentStatus] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [incidentDetails, setIncidentDetails] = useState(null);
  const [incidentList, setIncidentList] = useState([]);
  const [incidentLog, setIncidentLog] = useState([]);
  const [show, setShow] = useState(false);
  const [token, setToken] = useState();
  const [idForComment, setIdForComment] = useState("");
  const [comment, setComment] = useState("");
  const [filteredInc, setFilteredInc] = useState([])

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);
  // const [filteredIncidents, setFilteredIncidents] = useState([])

  const managerToken = localStorage.getItem("token")
  useEffect(()=> {
    setToken(managerToken)
  }, [managerToken])

  var configDownload = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/incident-report/download-incident-report/",
    headers: {
      Authorization: `token ${token}`,
      "Content-Type": "application/json",
    },
  };

  const downloadClick = (e) => {
    e.preventDefault();
    axios(configDownload)
      .then((res) => {
        // Check if the response contains data
        if (res.data) {
          // Prepare the file data and create a Blob object
          const blob = new Blob([res.data], { type: "application/octet-stream" });
  
          // Create a temporary URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
  
          // Create a link element
          const link = document.createElement("a");
          link.href = blobUrl;
  
          // Set the download attribute and suggested file name
          link.download = "incident_report_list.csv"; // Replace with the desired file name
  
          // Simulate a click on the link to trigger the download
          link.click();
  
          // Clean up the created URL
          URL.revokeObjectURL(blobUrl);
        }
      })
      .catch((error) => {
        console.error("Error downloading:", error);
      });
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        popoverOpen &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setPopoverOpen(false);
      }
    };
  
    document.addEventListener("click", handleDocumentClick);
  
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [popoverOpen]);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let _date = new Date();
  let dateMDY = format(_date, 'yyyy-MM-dd');
  const [actualDate, setActualDate] = useState(_date);

  const [date, setDate] = useState(dateMDY);
  const [selectedDate, setSelectedDate] = useState(dateMDY);

  const changeDate = () => {
    let currentDate = new Date(actualDate.getTime() + 24 * 60 * 60 * 1000);
  
    let formattedDate = format(currentDate, 'yyyy-MM-dd');
  
    setDate(formattedDate);
    setActualDate(currentDate);
    setSelectedDate(formattedDate);
  };
  
  const previous = () => {
    let currentDate = new Date(actualDate.getTime() - 24 * 60 * 60 * 1000);
  
    let formattedDate = format(currentDate, 'yyyy-MM-dd');
  
    setDate(formattedDate);
    setActualDate(currentDate);
    setSelectedDate(formattedDate);
  };

  useEffect(()=> {
    const filteredIncidents = incidentLog.filter(
      (incident) => incident.report_date === selectedDate
    );
    if(filteredIncidents.length === 0) {
      setFilteredInc(incidentLog)
    } else {
      setFilteredInc(filteredIncidents)
    }
  }, [selectedDate, incidentLog])

  const getIncidentReport = (buildingno, _token) => {

    var config = {
      method: "get",
      // url:
      // "https://kepah-24275.botics.co/api/v1/security-report/?residence_building=1&incident_status=0,1",
      url: `https://kepah-24275.botics.co/api/v1/admin/incident-report/`,

      headers: {
        Authorization: `token ${_token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function(response) {
        // console.log(response, "reeessponse");
        let incidentListX = response.data;

        // if (incidentList && incidentList.length > 0) {
        console.log(incidentListX);
        console.log("reeessponse");

        //   incidentList.reverse();
        setIncidentLog(incidentListX);
        // }
      })
      .catch(function(error) {
        console.log(error, "It's a error!!!");
      });
  };

  const updateStatus = (id, status, option, key) => {
    var data = JSON.stringify({
      incident_status: status,
    });

    var config = {
      method: "patch",
      url: `https://kepah-24275.botics.co/api/v1/admin/incident-report/${id}/`,
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        console.log("Status Upgrade")
        console.log(JSON.stringify(response.data));
        setIncidentStatus(false);
        setStatusType(option);
        
        setIncidentList((incidentList) => [...incidentList]);
        window.location.reload()
      })
      .catch(function(error) {
        setIncidentStatus(false);
        setStatusType(option);
      });
  };

  const updatePoliceMatter = (id, option) => {
    var data = JSON.stringify({
      police_call: option,
    });

    var config = {
      method: "patch",
      url: `https://kepah-24275.botics.co/api/v1/admin/incident-report/${id}/`,
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        setIncidentDetails(response.data)
      })
      .catch(function(error) {
        console.log("Ok bad Request")
      });
  };

  const addComment = () => {
    let data = JSON.stringify({
      comment: comment,
    });

    let config = {
      method: "post",
      url: `https://kepah-24275.botics.co/api/v1/admin/incident-report/${idForComment}/add-comment/`,
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        getIncidentReport()
        handleClose();

      })
      .catch(function(error) {
        console.log(error);
        handleClose();
      });
  };

  useEffect(() => {
    getIncidentReport(
      localStorage.getItem("buildingno"),
      localStorage.getItem("token")
    );
    setToken(localStorage.getItem("token"));
  }, [localStorage.getItem("token") && localStorage.getItem("buildingno")]);


  const popover = (
    // <>
    <Popover id="popover-basic">
      {incidentDetails && (
        <div className="incident_report_popup_background">
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">Date:</span>
            <span className="reporter_name">{incidentDetails.report_date}</span>
          </div>
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">Time:</span>
            <span className="reporter_name">{incidentDetails.report_time}</span>
          </div>
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">Incident Reporter:</span>
            <span className="reporter_name">
              {incidentDetails.reported_by}
            </span>
          </div>
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">Reporter Role:</span>
            <span className="reporter_name">
              {incidentDetails.reporter_role}
            </span>
          </div>
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">Action Taken:</span>
            <span className="reporter_name">
              {incidentDetails.action_taken}
            </span>
          </div>
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">Additional Report Made:</span>
            <span className="reporter_name">
              {incidentDetails.additional_report}
            </span>
          </div>
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">Infraction Given:</span>
            <span className="reporter_name">
              {incidentDetails.infraction_given}
            </span>
          </div>
          
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">Incident Type:</span>
            <span className="reporter_name">
              {incidentDetails.incident_type.name}
            </span>
          </div>
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">Summary:</span>
            <span className="reporter_name">
              {incidentDetails.incident_summary}
            </span>
          </div>
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">
              Potential Witness Name:
            </span>
            <span className="reporter_name">
              {incidentDetails.witness_name}
            </span>
          </div>
          <div className="incident_reporter_div">
            <span className="incident_reporter_text">
              Potential Witness Phone:
            </span>
            <span className="reporter_name">
              {incidentDetails.witness_phone}
            </span>
          </div>
          <div className="police_matter_div">
            <span className="incident_reporter_text">Police Matter:</span>
            <Row>
              <Col>
              <div className="radio">
                <input
                  type="radio"
                  value="yes"
                  onClick={() => updatePoliceMatter(incidentDetails.id, true)}
                  checked={incidentDetails.police_call === true}
                />
                <label className="yes-text">Yes</label>
              </div>
              </Col>
              <Col>
                <div className="radio">
                  <input
                    type="radio"
                    value="no"
                    onClick={() => updatePoliceMatter(incidentDetails.id, false)}
                    checked={incidentDetails.police_call === false}
                  />
                  <label className="no-text">No</label>
                </div>
              </Col>
            </Row>
            {incidentDetails.police_call ? (
              <>
                <div className="incident_reporter_div">
                  <span className="incident_reporter_text">Police Name:</span>
                  <span className="reporter_name">
                    {incidentDetails.emergency_person_name
                      ? incidentDetails.emergency_person_name
                      : "-"}
                  </span>
                </div>
                <div className="incident_reporter_div">
                  <span className="incident_reporter_text">Agency Name:</span>
                  <span className="reporter_name">
                    {incidentDetails.agency_name
                      ? incidentDetails.agency_name
                      : "-"}
                  </span>
                </div>
                <div className="incident_reporter_div">
                  <span className="incident_reporter_text">
                    Badge/Vehicle No:
                  </span>
                  <span className="reporter_name">
                    {incidentDetails.vehicle_number
                      ? incidentDetails.vehicle_number
                      : "-"}
                  </span>
                </div>
              </>
            ) : (
              <div className="incident_reporter_div">
                
              </div>
            )}
            <div>
              <span className="incident_reporter_text">
                Comments:
              </span>
              <ul>
                {incidentDetails.comments && incidentDetails.comments.map((cmt) => (
                  <li key={cmt.id}>{cmt.comment}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </Popover>
  );

  return (
    <div>
      <div className="incident_report_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">
            Incident Reports ({incidentLog.length})
          </span>
        </div>
        <div className="download_create_btns">
          <Form className="download_btn_form">
            <Button
              className="download_btn_incident"
              variant="primary"
              type="submit"
            >
              <div className="download_icon_div">
                <img
                  src={downloadIcon}
                />
                <img
                  src={downloadDash}
                />
              </div>
              <span onClick={downloadClick} className="download_text">DOWNLOAD</span>
            </Button>
          </Form>
          <Form className="download_btn_form">
            <Button
              className="create_new_incident_btn"
              variant="primary"
              type="submit"
              onClick={(event) => {
                event.preventDefault()
                props.history.push("incidentreports/incident")
              }}
            >
              CREATE NEW REPORT
            </Button>
          </Form>
        </div>
      </div>

      <div className="calendar_incident">
        <img
          onClick={() => previous()}
          src={previousImage}
        />
        <span>{date}</span>
        <img
          onClick={() => changeDate()}
          src={nextImage}
        />
      </div>

      <div className="scroll_div_incident_page">
        { filteredInc && filteredInc.map((val, ind) => {
          return (
            <div key={ind}>
              {/* {val.incident_status === null || val.incident_status === 0 ? ( */}
              <div className="incident_all_reports_div">
                <div className="incident_report_name_time_div">
                  <div className="incident_report_div">
                    <OverlayTrigger
                      trigger="click"
                      placement="right"
                      overlay={popover}
                      onToggle={() => {
                        setIncidentDetails(val);
                        setIncidentReports(ind + 1);
                        setPopoverOpen(!popoverOpen)
                        props.history.push(`/incidentreports`)
                      }}
                      ref={popoverRef}
                    >
                      <div>
                        <span className="incident_report_no_name">
                          {val?.incident_type?.name}- #{val?.id}
                        </span>
                      </div>
                    </OverlayTrigger>

                    <span className="incident_report_time_date">
                      {val?.report_date} - {val?.report_time}
                    </span>
                  </div>
                  <div>
                    <p className="incident_main_report">
                      “{val?.incident_summary}”
                    </p>
                  </div>
                </div>
                <div className="incident_share_report_page_div">
                  <div
                    onClick={() => {
                      handleShow(true);
                      setIdForComment(val?.id);
                    }}
                  >
                    <img src={textImage} />
                  </div>

                  <div className="incident_report_status_div">
                    <div
                      className="status_report_div"
                      onClick={() =>
                        setIncidentStatus(incidentStatus === val ? null : val)
                      }
                    >
                      <span>
                        {val?.incident_status == 0
                          ? "Filed"
                          : status[val?.incident_status]}
                      </span>
                      {incidentStatus === val ? (
                        <img
                          className="up_arrow_img"
                          src={upImage}
                        />
                      ) : (
                        <img
                          src={downImage}
                        />
                      )}
                    </div>

                    {incidentStatus === val ? (
                      <div>
                        <div
                          className="status_report_choose"
                          onClick={() => {
                            updateStatus(val.id, 0, "Filed", ind);
                          }}
                        >
                          <span>Filed</span>
                        </div>
                        <div
                          className="status_report_choose1"
                          onClick={() => {
                            updateStatus(val.id, 1, "Active", ind);
                          }}
                        >
                          <span>Active</span>
                        </div>
                        <div
                          className="status_report_choose2"
                          onClick={() => {
                            updateStatus(val.id, 2, "Closed", ind);
                          }}
                        >
                          <span>Closed</span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="bottom_border_vehicle_page" />
            </div>
          );
        })}
        {incidentReports ? (
          <div
            onClick={() => {
              setIncidentReports(false);
            }}
            className="incident-class"
          ></div>
        ) : null}

        <Modal
          show={show}
          onHide={handleClose}
          className="commentModal"
        >
          <div className="commentModalDiv">
            <div className="add_comment_background">
              <span className="add_comment_text"> Add a Comment</span>
            </div>
            <div className="comment_main_div">
              <div>
                <span className="comment_text">Comment:</span>
                <div className="comment_input_div">
                  <textarea
                    className="incidentCommentInput"
                    rows="5"
                    placeholder="Add a Comment"
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <div>
                  <Form className="comment_form">
                    <Button
                      className="confirm_button"
                      variant="primary"
                      onClick={() => addComment()}
                    >
                      CONFIRM
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div onClick={handleClose} />
    </div>
  );
};

export default IncidentReport;
