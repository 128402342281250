import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Popover, OverlayTrigger, Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dropdown from "../../../assets/dropdown.svg"
import dropdown_up from "../../../assets/dropdown_up.svg"
import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../../parts/BackButton/BackButton";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "../../../utils/TimelineGraph/lib/layout/Sidebar";


const IncidentReportTemplate = ({ ...props }) => {

    const [reportedBy, setReportedBy] = useState("")
    const [reporterRole, setReporterRole] = useState("")
    const [dateOfReport, setDateOfReport] = useState(new Date().toISOString().split('T')[0])
    const [incidentNumber, setIncidentNumber] = useState("")
    const [incidentType, setIncidentType] = useState("")
    const [dateOfIncident, setDateOfIncident] = useState("")
    const [incidentLocation, setIncidentLocation] = useState("")
    const [incidentCity, setIncidentCity] = useState("San francisco")
    const [incidentState, setIncidentState] = useState("California")
    const [incidentZip, setIncidentZip] = useState("23123")
    const [incidentSpecificArea, setIncidentSpecificArea] = useState("")
    const [incidentDescription, setIncidentDescription] = useState("")
    const [partyName, setPartyName] = useState("")
    const [partyRole, setPartyRole] = useState("")
    const [partyContact, setPartyContact] = useState("")
    const [witnessName, setWitnessName] = useState("")
    const [witnessRole, setWitnessRole] = useState("")
    const [witnessContact, setWitnessContact] = useState("")

    const [errorMessage, setErrorMessage] = useState("Fields marked * are required")
    const [progressMessage, setProgressMessage] = useState("")
    const [errorState, setErrorState] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)
    const [incidentData, setIncidentData] = useState([])

    const notifyError = () => toast.error('Fields marked * are required', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const closeFunction = () => {
        props.history.goBack()
    }

    const incidentReportTemplateX = JSON.parse(localStorage.getItem("incidentReportTemplate"));

    useEffect(() => {
        if(incidentReportTemplateX !== null) {
            setReportedBy(incidentReportTemplateX.reportedBy)
            setReporterRole(incidentReportTemplateX.reporterRole)
            setDateOfReport(incidentReportTemplateX.dateOfReport)
            setIncidentType(incidentReportTemplateX.incidentType)
            setDateOfIncident(incidentReportTemplateX.dateOfIncident)
            setIncidentLocation(incidentReportTemplateX.incidentLocation)
            setIncidentState(incidentReportTemplateX.incidentState)
            setIncidentCity(incidentReportTemplateX.incidentCity)
            setIncidentZip(incidentReportTemplateX.incidentZip)
            setIncidentSpecificArea(incidentReportTemplateX.incidentSpecificArea)
            setIncidentDescription(incidentReportTemplateX.incidentDescription)
            setPartyName(incidentReportTemplateX.partyName)
            setPartyRole(incidentReportTemplateX.partyRole)
            setPartyContact(incidentReportTemplateX.partyContact)
            setWitnessName(incidentReportTemplateX.witnessName)
            setWitnessContact(incidentReportTemplateX.witnessContact)
            setWitnessRole(incidentReportTemplateX.witnessRole)
        }
    }, [])

    const validate = () => {
        
        setButtonClicked(true)
        console.log("reportedBy:", reportedBy);
        console.log("reporterRole:", reporterRole);
        console.log("dateOfReport:", dateOfReport);
        console.log("incidentType:", incidentType);
        console.log("dateOfIncident:", dateOfIncident);
        console.log("incidentLocation:", incidentLocation);
        console.log("incidentCity:", incidentCity);
        console.log("incidentState:", incidentState);
        console.log("incidentZip:", incidentZip);
        console.log("incidentSpecificArea:", incidentSpecificArea);
        console.log("incidentDescription:", incidentDescription);
        console.log("partyName:", partyName);
        console.log("partyRole:", partyRole);
        console.log("partyContact:", partyContact);
        console.log("witnessName:", witnessName);
        console.log("witnessRole:", witnessRole);
        console.log("witnessContact:", witnessContact);

        if (reportedBy === "" || reporterRole === "" || dateOfReport === "" ||
            incidentType === "" || dateOfIncident === "" || incidentLocation === "" || incidentCity === "" ||
            incidentState === "" || incidentZip === "" || incidentDescription === "" ||
            partyName === "" || partyRole === "" || partyContact === "" || witnessName === "" ||
            witnessRole === "" || witnessContact === "") {
            setErrorState(true);
            notifyError();
        }
    };

    useEffect(() => {
        setErrorState(false)
        setButtonClicked(false)
        const incidentDataX = {
            reportedBy,
            reporterRole,
            dateOfReport,
            incidentType,
            dateOfIncident,
            incidentLocation,
            incidentCity,
            incidentState,
            incidentZip,
            incidentSpecificArea,
            incidentDescription,
            partyName,
            partyRole,
            partyContact,
            witnessName,
            witnessRole,
            witnessContact
        };
        setIncidentData(incidentDataX)
    }, [
        reportedBy,
        reporterRole,
        dateOfReport,
        incidentType,
        dateOfIncident,
        incidentLocation,
        incidentCity,
        incidentState,
        incidentZip,
        incidentSpecificArea,
        incidentDescription,
        partyName,
        partyRole,
        partyContact,
        witnessName,
        witnessRole,
        witnessContact
    ])

    useEffect(() => {
        if(!errorState && buttonClicked) {
            localStorage.setItem("incidentReportTemplate", JSON.stringify(incidentData));
            props.handleContinue();
        }
    }, [errorState, buttonClicked])

    const handleDropdownChangeRole = (event) => {
        setReporterRole(event.target.value);
    };

    const handleDropdownChangeIncident = (event) => {
        setIncidentType(event.target.value);
    };

    const handleDropdownChangeWitnessType = (event) => {
        setWitnessRole(event.target.value);
    };

    const handleDropdownChangePartyType = (event) => {
        setPartyRole(event.target.value);
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, "");
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (!match) return phoneNumber;

        let formattedNumber = "";

        if (match[1]) {
            formattedNumber += `(${match[1]})`;
        }

        if (match[2]) {
            formattedNumber += ` ${match[2]}`;
        }

        if (match[3]) {
            formattedNumber += `-${match[3]}`;
        }

        return formattedNumber;
    };


    return (
        <div className="lastPad">
            <BackButton closeFunction={closeFunction} />
            <div>
                <Container>
                    <Row>
                    </Row>
                    <Row>
                        <Col className="col-md-4">
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Reported By:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div bg_of_drop">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" value={reportedBy} onChange={(e) => setReportedBy(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Title/Role:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="bg_of_drop">
                                    <div className="phone_input_field_div">

                                        <select
                                            className="phone_input_field_xx"
                                            value={reporterRole}
                                            onChange={handleDropdownChangeRole}
                                        >
                                            <option value="">Select An Option</option>
                                            <option value="resident">Resident</option>
                                            <option value="visitor">Visitor</option>
                                            <option value="occupant">Occupant</option>
                                            <option value="security_admin">Security Admin</option>
                                            <option value="security_officer">Security Officer</option>
                                            <option value="manager">Manager</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-9">
                                        <label className="incident_report_label">Date of Report:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-3">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div bg_of_drop">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field_date" disabled type="date" value={dateOfReport} onChange={(event) => setDateOfReport(event.target.value)} />
                                    </div>
                                </div>
                            </div>

                           
                            <div className="header_incident_report">
                                <p className="incidentInfoTitleMg">Incident Information</p>
                            </div>

                            <div className="inputWidth incidentInfoTitle">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label_type">Incident Type:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="bg_of_drop">
                                    <div className="phone_input_field_div">
                                        <select
                                            className="phone_input_field_xx"
                                            value={incidentType}
                                            onChange={handleDropdownChangeIncident}
                                        >
                                             <option value="">Select An Option</option>
                                            <option value="5">Burglary/Theft</option>
                                            <option value="7">Fight</option>
                                            <option value="13">Suspected Drug Use</option>
                                            <option value="14">Loud Music</option>
                                            <option value="15">Unauthorized Person on Property</option>
                                            <option value="16">Armed Person</option>
                                            <option value="8">Fire</option>
                                            <option value="17">BBQ Grill on Pattio</option>
                                            <option value="18">Garbage left in Hallway</option>
                                            <option value="19">Unruly children</option>
                                            <option value="11">Police on Property</option>
                                            <option value="20">Lights out Location</option>
                                            <option value="15">Lease violation</option>
                                            <option value="12">Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Date of Incident:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div bg_of_drop">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field_date" type="date" value={dateOfIncident} onChange={(event) => setDateOfIncident(event.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Address:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" value={incidentLocation} onChange={(e) => setIncidentLocation(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">City:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div bg_of_drop">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" value={incidentCity} onChange={(e) => setIncidentCity(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col className="col-md-4">
                            
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-6">
                                        <div>
                                            <Row>
                                                <Col className="col-md-9">
                                                    <label className="incident_report_label">State:</label>
                                                </Col>
                                                <Col style={{ color: "red" }} className="incident_report_label col-md-3">
                                                    {errorState ? "*" : ""}
                                                </Col>
                                            </Row>

                                            <div className="phone_input_div">
                                                <div className="phone_input_field_div">
                                                    <input className="phone_input_field" type="text" value={incidentState} onChange={(e) => setIncidentState(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="col-md-6">
                                        <div>
                                            <Row>
                                                <Col className="col-md-9">
                                                    <label className="incident_report_label">ZIP code:</label>
                                                </Col>
                                                <Col style={{ color: "red" }} className="incident_report_label col-md-3">
                                                    {errorState ? "*" : ""}
                                                </Col>
                                            </Row>

                                            <div className="phone_input_div">
                                                <div className="phone_input_field_div">
                                                    <input className="phone_input_field" type="text" value={incidentZip} onChange={(e) => setIncidentZip(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="inputWidth">

                                <label className="incident_report_label">Specific Area (if applicable):</label>
                                <div className="phone_input_div">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" value={incidentSpecificArea} onChange={(e) => setIncidentSpecificArea(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Incident Summary:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" value={incidentDescription} onChange={(e) => setIncidentDescription(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="header_incident_report">
                                <p className="incidentInfoTitleMg">Parties Involved</p>
                            </div>
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label_type">Name:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" value={partyName} type="text" onChange={(e) => setPartyName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Role:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>


                                <div className="bg_of_drop">
                                    <div className="phone_input_field_div">

                                        <select
                                            className="phone_input_field_xx"
                                            value={partyRole}
                                            onChange={handleDropdownChangePartyType}
                                        >
                                            <option value="">Select An Option</option>
                                            <option value="resident">Resident</option>
                                            <option value="occupant">Occupant</option>
                                            <option value="visitor">Visitor</option>
                                            <option value="security_admin">Security Admin</option>
                                            <option value="security_officer">Security Officer</option>
                                            <option value="manager">Manager</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Contact:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" value={partyContact} onChange={(e) => setPartyContact(formatPhoneNumber(e.target.value))} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-md-4 d-flex flex-column">
                            <div className="header_incident_report_party">
                                <p className="incidentInfoTitleParty">Parties Witnesses</p>
                            </div>
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Name:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div bg_of_drop">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" value={witnessName} type="text" onChange={(e) => setWitnessName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Role:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="bg_of_drop">
                                    <div className="phone_input_field_div">

                                        <select
                                            className="phone_input_field_xx"
                                            value={witnessRole}
                                            onChange={handleDropdownChangeWitnessType}
                                        >
                                            <option value="">Select An Option</option>
                                            <option value="resident">Resident</option>
                                            <option value="occupant">Occupant</option>
                                            <option value="visitor">Visitor</option>
                                            <option value="security_admin">Security Admin</option>
                                            <option value="security_officer">Security Officer</option>
                                            <option value="manager">Manager</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Contact:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" value={witnessContact} onChange={(e) => setWitnessContact(formatPhoneNumber(e.target.value))} />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-auto inputWidth">
                                <Button className="buttonStyleAddIncident" onClick={validate}>CONTINUE</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </div>
        </div>
    );
}

export default IncidentReportTemplate;