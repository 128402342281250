import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import ListOfSecurityCompaniesPage from "./ListOfSecurityCompaniesPage/ListOfSecurityCompaniesPage";
import ListOfOfficers from "./ListOfOfficers/ListOfOfficers";

import "./style.css";
import AddNewSecurityCompany from "./AddSecurityCompany/AddSecurityCompany";
import EditNewSecurityCompany from "./EditSecurityCompany/EditSecurityCompany";

const UpdateLeaseInformation = ({ ...props }) => {
  const [editSecurityCompany, setEditSecurityCompany] = useState(false)
  const [addNewSecurityCompany, setAddNewSecurityCompany] = useState(false)


  const editSecurityCompanyinfo = () => {
    setEditSecurityCompany(true)
  }
  
  const closeEditSecurityCompanyinfo = () => {
    setEditSecurityCompany(false)
  }

  const addSecurityCompanyinfo = () => {
    setAddNewSecurityCompany(true)
  }

  const closeAddSecurityCompanyinfo = () => {
    setAddNewSecurityCompany(false)
  }

  if(!editSecurityCompany && !addNewSecurityCompany) {
    return (
      <div className="main-officer_schedule">
        <div className="side_nav">
          <Sidebar history={props.history} nav="Security Companies" />
        </div>
  
        <div className="update_lease_information">
          <div className="list_of_securityCompany">
            <ListOfSecurityCompaniesPage history={props.history} editSecurityCompanyinfo={editSecurityCompanyinfo} addSecurityCompanyinfo={addSecurityCompanyinfo}/>
          </div>
          <div className="history_of_payments scrollClass">
            <ListOfOfficers />
          </div>
        </div>
      </div>
    );
  } else if(addNewSecurityCompany) {
    return (
      <AddNewSecurityCompany history={props.history} closeAddSecurityCompanyinfo={closeAddSecurityCompanyinfo} />
    );
  }
  else if(editSecurityCompany) {
    return (
      <EditNewSecurityCompany history={props.history} closeEditSecurityCompanyinfo={closeEditSecurityCompanyinfo} />
    );
  }
};

export default UpdateLeaseInformation;
