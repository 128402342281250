import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";

const AddCriminal = ({ ...props }) => {
  const [userPhoto, setUserPhoto] = useState(null);
  const [photoForm, setPhotoForm] = useState(null);
  const [photoCheck, setPhotoCheck] = useState(false);
  const [id, setId] = useState("")
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [badgeID, setBadgeID] = useState("");
  const [updateUser, setUpdateUser] = useState(null);
  const [upPhoto, setUpPhoto] = useState(null);
  const [errorFlag, setErrorFlag] = useState(false)
  const [errorMail, setErrorMail] = useState("")
  const [errorPhone, setErrorPhone] = useState("")
  const [errorBadgeId, setErrorBadgeId] = useState("")
  const [errorName, setErrorName] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [buttonClickedX, setButtonClickedX] = useState(false);
  const [criminalTrespass, setCriminalTrespass] = useState(null)

  const [user, setUser] = useState([])
  const [userInfo, setUserInfo] = useState(null)
  const [userId, setUserId] = useState("")

  const closeFunction = () => {
    props.history.goBack()
  }


  const notify = () => toast.success('Successfully Added!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError = () => toast.error('Error Happened!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyErrorRequired = () => toast.error('Fields marked * are required!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyLoading = () => toast.info('Loading', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const managerToken = localStorage.getItem("token")

  var userApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/users/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  useEffect(() => {
    (async () => {
      axios(userApi).then((res) => {
        const validUsers = res.data.filter(user => user && user.name !== null); // Filter out null user objects and those with null names
  
        const sortedData = validUsers.slice(); // Create a copy of the array
        sortedData.sort((a, b) => a.name.localeCompare(b.name)); // Sort in alphabetical order by 'name'
        console.log(sortedData)
        setUser(sortedData);
      })
    })()
  }, [])

  var configCriminalTrespass = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/criminal-trespass/`,
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    (async () => {
      await axios(configCriminalTrespass)
      .then(function (response) {
        // let criminalTrespass = response.data;
        // console.log(response.data);
        console.log("response.data.criminalTrespass", JSON.stringify(response.data, null, 2))
        const xx = response.data
        setCriminalTrespass(xx)
      })
      .catch(function (error) {
        console.log(error, "It's a error!!!");
      })
    })();
  }, []);

  const validateFe = () => {
    if (userId === "") {
      setErrorFlag(true)
    }

  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  useEffect(() => {
    if (buttonClickedX) {
      if (errorFlag != true) {
        handleAddCriminal()
      }
    }
  }, [errorFlag, buttonClickedX])


  useEffect(() => {
    setErrorFlag(false)
    setButtonClickedX(false)
    const userInf = {
      user: parseInt(userId),
      status: "active"
    }
    console.log(userInf)
    setUserInfo(userInf)
    const x = user.find(obj => obj.id == userId)
    console.log(userId)
    if (x !== undefined) {
      setUserEmail(x.email)
      setUserName(x.name)
      console.log(x)
    }

  }, [userId]);

  var config = {
    method: "post",
    url: `https://kepah-24275.botics.co/api/v1/admin/criminal-trespass/`,
    headers: {
      Authorization: `token ${managerToken}`
    },
    data: userInfo
  };

  const handleAddCriminal = () => {
    notifyLoading()
    console.log("UserInfo", JSON.stringify(userInfo, null, 2))
    console.log("UserToken", JSON.stringify(managerToken, null, 2))

    const criminalTrespassRecord = criminalTrespass.find(res => parseInt(res?.user?.id) === parseInt(userId));

    if (criminalTrespassRecord) {
      console.log("userHasCri", JSON.stringify(criminalTrespassRecord, null, 2))

      var configPatch = {
        method: "patch",
        url: `https://kepah-24275.botics.co/api/v1/admin/criminal-trespass/${criminalTrespassRecord?.id}/`,
        headers: {
          Authorization: `token ${managerToken}`
        },
        data: {
          status: "active"
        }
      };
      axios(configPatch).then((res) => {
        if (res.status == 200) {
          notify()
          setTimeout(() => {
            props.history.goBack()
          }, 5000);
        }
      }).catch((err) => {
        console.log("error in patch", JSON.stringify(err?.response?.data, null, 2))
        notifyError()
      })
    } else {
      axios(config).then((res) => {
        console.log("Response", JSON.stringify(res, null, 2))
        if (res.status == 201) {
          notify()
          setTimeout(() => {
            props.history.goBack()
          }, 5000);
        }
      }).catch((err) => {
        console.log("error", JSON.stringify(err?.response?.data?.user, null, 2))
        notifyError()
      })
    }
  }

  const handleDropdownChange = (e) => {
    setUserId(e.target.value);
  };


  return (
    <div className="incident_back">
      <div className="side_nav">
        <Sidebar history={props.history} nav="Criminal" />
      </div>
      <div className="rent_main_div">
        <div className="addCriminal">
          <div className="edit_manager_back">
            <BackButton closeFunction={closeFunction} />
            <div className="edit_manager_background_color">
              <div className="edit_manager_name_img_div">
                <h3 className="edit_manager_text">Add New Criminal Trespass Record</h3>

              </div>

              <div className="input_fields_div">
                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">User:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">

                      <select
                        className="phone_input_field"
                        onChange={handleDropdownChange}
                      >
                        {/* Render the dropdown options based on the fetched data */}
                        <option> Select An User </option>
                        {user.map((usr) => {

                          if (usr.user_type === "resident" || usr.user_type === "occupant" || usr.user_type === "visitor" && usr.user_type != "manager") {

                            if (((usr.criminal_status && usr.criminal_status.status != true) || usr.criminal_status === null) && usr.apartment_number != null) {
                              // console.log(usr)
                              return (
                                <option value={usr.id}> {usr.name} </option>
                              )
                            }
                          }
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <Row className="pt-2">
                  {userEmail ? <p>You are sending this to {userEmail}</p> : ""}
                </Row>
                <Row className="pt-2">
                  {userName ? <p>{userName} account will be deactivated</p> : ""}
                </Row>

                <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  {errorPhone ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorPhone}</p> : ""}
                </div>
                <Form className="edit_profile_save_button_div">
                  <Button
                    className="edit_profile_save_button"
                    variant="primary"
                    onClick={buttonClicked}
                  >
                    Save
                  </Button>
                </Form>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>

  );
};

export default AddCriminal;
