import React, { useState, useEffect } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import EditSvg from "../../../assets/edit.svg"
import "bootstrap/dist/css/bootstrap.min.css";
import { Popover, OverlayTrigger, Container, Col, Row } from "react-bootstrap";
import searchImage from "../../../assets/Search.png"
import personImage from "../../../assets/profile.png"

const ListOfManagersPage = ({ ...props }) => {
  const [search, setSearch] = useState("");
  const [userList, setUserList] = useState([]);
  const [updatedUserList, setUpdatedUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userListCheck, setUserListCheck] = useState(false);
  const [userListChanged, setUserListChanged] = useState(false); 
  const [searchOn, setSearchOn] = useState(false)

  const managerToken = localStorage.getItem("token")

  var config = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/managers/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    axios(config).then((res) => {
      setUserList(res.data);
      setUpdatedUserList(res.data)
      setUserListCheck(true);
      console.log(res.data)
    });
  }, []);

  useEffect(() => {
    if(!searchOn) {
      axios(config).then((res) => {
        setUserList(res.data);
        setUpdatedUserList(res.data)
        setUserListCheck(true);
      });
    } else {
      setUpdatedUserList(userList)
    }
  }, [userList, searchOn]);

  useEffect(() => {
    if (userList.length > 0 && userList !== null) {
      const filteredResults = userList.filter((user) => {
        if (user) {
          const nameMatch =
            user.name && user.name.toLowerCase().includes(search.toLowerCase());
          const emailMatch =
            user.email && user.email.toLowerCase().includes(search.toLowerCase());
          const phoneMatch =
            user.phone_number && user.phone_number.includes(search);
          const badgeMatch =
            user.badge_id && user.badge_id.includes(search);
  
          return nameMatch || emailMatch || phoneMatch || badgeMatch;
        }
        return false;
      });
      setUpdatedUserList(filteredResults);
    }
  }, [search, userList]);

  useEffect(() => {
    if(search.length > 0) {
      setSearchOn(true)
    } else {
      setSearchOn(false)
    }
  }, [search])

  return (
    <div>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">Lists of Managers ({userList.length}) </span>
        </div>
      </div>

      <div className="criminal_input_div">
        <input
          type="text"
          className="search_input_list_of_managers"
          placeholder="Search by Name/Email/ID Badge/Phone..."
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="criminal_search_btn">
          <img src={searchImage} />
        </div>
        <Form className="form_inline_criminal">
          <Button
            className="save_btn_add_criminal"
            variant="primary"
            type="submit"
            // onClick={() => props.addNewManager()}
            onClick={() => {
              props.history.push("list/add");
            }}
          >
            ADD NEW PERSON
          </Button>
        </Form>
      </div>

      <div className="pt-4">
        <div className="list_of_managers_div">
            <div>
              <Container>
                <Row className="col-md-12 pb-2">
                  <Col className="col-md-3">
                    Name
                  </Col>
                  <Col className="col-md-3">
                    Email
                  </Col>
                  <Col className="col-md-3 ml-5">
                    Phone
                  </Col>
                  <Col className="col-md-2">
                    ID Badge
                  </Col>
                  <Col>
                    
                  </Col>
                  <div className="bottom_border_manager mt-1 mb-1" />
                </Row>
                {/* <Row>
                  <div className="bottom_border_manager" />
                </Row> */}
                { userListCheck ? updatedUserList.map(user => (
                  <Row className="col-md-12 pt-2 pb-2 row_alternate" key={user.id}>
                    <Col className="col-md-3">
                      <Row>
                        <Col className="col-md-1">
                        {user.profile_picture ? (
                          <img
                            style={{ height: "32px", width: "32px", borderRadius: "64px" }}
                            src={user.profile_picture}
                            alt="Profile Photo"
                          />
                        ) : (
                          <img
                            style={{ height: "32px", width: "32px", borderRadius: "64px" }}
                            src={personImage}
                            alt="Default Profile Photo"
                          />
                        )}

                        </Col>
                        <Col style={{ marginLeft: "23px", color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                          {user.name}
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-md-3" style={{ color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                      {user.email}
                    </Col>
                    <Col className="col-md-3" style={{ fontSize: "14px", paddingTop: "6px"}} >
                      {user.phone_number}
                    </Col>
                    <Col className="col-md-2" style={{ fontSize: "14px", paddingTop: "6px"}}>
                      {user.badge_id}
                    </Col>
                    <Col style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}} onClick={() => {
                      localStorage.setItem("managerInfoChange", JSON.stringify(user));
                      props.setManager();
                      props.history.push("list/edit");
                    }}>
                      <img src={EditSvg} style={{ height: "16px", width: "16px" }}/>
                    </Col>
                  </Row>
                )): <></> }
              </Container>
            </div>
        </div>
        
      </div>
    </div>
  );
};

export default ListOfManagersPage;
