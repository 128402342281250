import React, { useState } from "react";
import "./style.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const OfficersManagementCalendar = ({...props}) => {
  // const [value, onChange] = useState(new Date());
  const [custom, setCustom] = useState(false)
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);

  useState(() => {
    setCustom(props.custom)
  }, [props.custom])

  return (
    <div className="officer_management_back">
      <div className="officers_management_div">
        <span className="officers_management_text">
          Officers Management - Calendar 
        </span>
        <span className="officers_management_text">
          {custom && firstDate === null ? "Choose Starting Date" : ""}
        </span>
        <span className="officers_management_text">
          {custom && secondDate === null ? "Choose Ending Date" : ""}
        </span>
      </div>
      <div className="calendar_display">
        {custom && firstDate === null ? 
          <Calendar
            prev2Label={null}
            next2Label={null}
            onChange={props.setDateSelected}
            value={props.dateSelected}
            onClickDay={props.clickDate}
          />
          :
          ""
        } 
        {custom && secondDate === null ? 
          <Calendar
          prev2Label={null}
          next2Label={null}
          onChange={props.setDateSelected2}
          value={props.dateSelected}
          onClickDay={props.clickDate2}
        />
          :
          ""
        } 
        {!custom ? 
          <Calendar
          prev2Label={null}
          next2Label={null}
          onChange={props.setDateSelected}
          value={props.dateSelected}
          onClickDay={props.clickDate}
        />
          :
          ""
        }
      </div>
      <div className="slots_div">
        {/* <div>
          <img src={require("../../../assets/green.png").default} />
          <span className="all_slots_officers_management">
            All slots are full
          </span>
        </div>

        <div>
          <img src={require("../../../assets/yellow.png").default} />
          <span className="all_slots_officers_management">
            All slots are full
          </span>
        </div>

        <div>
          <img src={require("../../../assets/red.png").default} />
          <span className="all_slots_officers_management">
            All slots are full
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default OfficersManagementCalendar;
