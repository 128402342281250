import axios from "axios";
import getManagerToken from "./ManagerToken";

export default function LogOut() {
    const token = getManagerToken()
    var config = {
        method: "post",
        url: "https://kepah-24275.botics.co/rest-auth/logout/",
        headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
        },
    };

    // Return the promise from the axios call
    return axios(config)
        .then((res) => {
            // Return the response data from the successful request
            localStorage.setItem("token", "")
            return res.data;
        })
        .catch((err) => {
            // Throw an error or handle it as needed
            console.log("Error from auth user logOut", err);
            throw err;
        });
}
