import { toast } from 'react-toastify';

export const notifySuccess = ({ toastMessage, autoClose }) => toast.success(toastMessage, {
  position: "top-right",
  autoClose: autoClose | 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
});

export const notifyError = ({ toastMessage, autoClose }) => toast.error(toastMessage, {
  position: "top-right",
  autoClose: autoClose | 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
});

export const notifyErrorRequired = ({ toastMessage, autoClose }) => toast.error('Fields marked * are required!', {
  position: "top-right",
  autoClose: autoClose | 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
});

export const notifyLoading = ({ toastMessage, autoClose }) => toast.info(toastMessage, {
  position: "top-right",
  autoClose: autoClose | 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
});
