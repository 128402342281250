import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";

const EditSecurityCompany = ({ ...props }) => {

  const [errorFlag, setErrorFlag] = useState(false)
  const [buttonClickedX, setButtonClickedX] = useState(false);

  const [vehicleInfo, setVehicleInfo] = useState(null);

  const [id, setId] = useState("");
  const [securityCompanyName, setSecurityCompanyName] = useState('');
  const [securityCompanyEmail, setSecurityCompanyEmail] = useState('');
  const [securityCompanyAddress, setSecurityCompanyAddress] = useState('');
  const [securityCompanyPhone, setSecurityCompanyPhone] = useState('');
  const [securityCompanyLicence, setSecurityCompanyLicence] = useState('');

  const closeFunction = () => {
    props.history.goBack()
  }

  useEffect(() => {
    const managerInfoChangeString = localStorage.getItem("securityCompanyInfoChange");
    const managerInfoChange = JSON.parse(managerInfoChangeString);

    if (managerInfoChange) {
      setId(managerInfoChange.id)
      setSecurityCompanyName(managerInfoChange.name)
      setSecurityCompanyEmail(managerInfoChange.email)
      setSecurityCompanyAddress(managerInfoChange.address)
      setSecurityCompanyLicence(managerInfoChange.license_number)
      setSecurityCompanyPhone(managerInfoChange.phone)
    }
  }, []);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return phoneNumber;

    let formattedNumber = "";

    if (match[1]) {
      formattedNumber += `(${match[1]})`;
    }

    if (match[2]) {
      formattedNumber += ` ${match[2]}`;
    }

    if (match[3]) {
      formattedNumber += `-${match[3]}`;
    }

    return formattedNumber;
  };


  const notify = () => toast.success('Successfully Added!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError = () => toast.error('Error Happened!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyErrorRequired = () => toast.error('Fields marked * are required!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyLoading = () => toast.info('Loading', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyPhotoAdded = () => toast.info('Image successfully Added', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  const validateFe = () => {
    setErrorFlag(false)
    if (securityCompanyName === "" || securityCompanyEmail === "" || securityCompanyAddress === "" || securityCompanyPhone === "" || securityCompanyLicence === "") {
      setErrorFlag(true)
      notifyErrorRequired()
    }
  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  useEffect(() => {
    setErrorFlag(false)
    setButtonClickedX(false)
    const formData = {
      name: securityCompanyName,
      email: securityCompanyEmail,
      phone: securityCompanyPhone,
      address: securityCompanyAddress,
      license_number: securityCompanyLicence
    }
    setVehicleInfo(formData)
  }, [securityCompanyAddress, securityCompanyEmail, securityCompanyLicence, securityCompanyName, securityCompanyPhone]);

  const managerToken = localStorage.getItem("token")


  var config = {
    method: "put",
    url: `https://kepah-24275.botics.co/api/v1/admin/security-company/${id}/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
    data: vehicleInfo
  };

  useEffect(() => {
    if (buttonClickedX && !errorFlag) {
      notifyLoading()
      axios(config)
        .then((res) => {
          if (res.status == 200) {
            notify()
            setTimeout(() => {
              props.history.goBack()
            }, 5000);
          }
        })
        .catch((error) => {
          notifyError()
        });
    }
  }, [buttonClicked, errorFlag])

  return (
    <div className="main-officer_schedule">
      <div className="side_nav">
        <Sidebar history={props.history} nav="Security Companies" />
      </div>

      <div className="bgOfAdd">
        <div className="securityCompanyAdd">
          <div className="add_new_resident_back">
            <BackButton closeFunction={closeFunction} />
            <div className="add_new_resident_background_color">
              <div className="add_new_resident_name_img_div">
                <h3 className="add_new_resident_text">Edit Security Company</h3>

              </div>
              {/* <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
         {errorMessage? <p style={{fontSize: "13px", paddingLeft: "5px", color: "red"}}>{errorMessage}</p>: ""}
        </div> */}
              <div className="input_fields_div">

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Company name:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={securityCompanyName} className="phone_input_field" type="text" onChange={(e) => setSecurityCompanyName(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Address:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={securityCompanyAddress} className="phone_input_field" type="text" onChange={(e) => setSecurityCompanyAddress(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Phone:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={securityCompanyPhone} className="phone_input_field" type="text" onChange={(e) => setSecurityCompanyPhone(formatPhoneNumber(e.target.value))} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Email:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={securityCompanyEmail} className="phone_input_field" type="text" onChange={(e) => setSecurityCompanyEmail(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Licence Number:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={securityCompanyLicence} className="phone_input_field" type="text" onChange={(e) => setSecurityCompanyLicence(e.target.value)} />
                    </div>
                  </div>
                </div>

                <Form className="edit_resident_profile_save_button_div">
                  <Button
                    className="buttonSave"
                    variant="primary"
                    onClick={buttonClicked}
                  >
                    Save
                  </Button>
                </Form>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>

  );
};

export default EditSecurityCompany;
