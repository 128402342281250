import React, { useState, useEffect } from "react";
import { format } from 'date-fns';
import imgFile from "../../../assets/profile.png"
import { Container, Row, Col } from "react-bootstrap";
import imgFile2 from "../../../assets/message2.svg"
import formatTimestamp from "../Timebox/TimeAPI";

export default function Messagebox ({...props}) {
    return(
        <div style={{ height: "8vh", borderRadius: "5px", width: "100%", height: "auto"}}>
            <Container>
                <Row>
                    <div className="imagePart2">
                        <img
                            style={{ height: "48px", width: "48px", borderRadius: "64px" }}
                            src={imgFile}
                            className="profile_img_main_page"
                        />
                    </div>
                    <div className="messagePart2">
                        <div className="messageStyle">
                            <div className="messageDet">
                                <p style={{ paddingLeft: "10%", paddingTop: "2%" }}>
                                    {props.message}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="datePart3" style={{ fontSize: ".9em", marginTop: "20px", display: "flex", justifyContent:"flex-end", color: "#1D7DEF", alignItems: "flex-end"  }}>
                        {formatTimestamp(props.timestamp)}
                    </div>
                </Row>
            </Container>
        </div>
    )
}