import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

import { format, parseISO } from 'date-fns';
import searchImage from "../../../assets/Search.png"


import { Popover, OverlayTrigger, Row, Col, Container } from "react-bootstrap";

const ListOfVehiclesPage = ({ ...props }) => {
  const [criminalTrespass, setCriminalTrespass] = useState([1]);
  const [token, setToken] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");
  

  const [vehicles, setVehicles] = useState([])
  const [vehicleActions, setVehicleActions] = useState([])
  const [modalInfo, setModalInfo] = useState([]);
  const [actions, setActions] = useState([])

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  const statusMapping = {
    "towed": "Towed",
    "booted": "Booted",
    "damaged": "Damaged",
    "vehicle_fine": "Vehicle Fine",
    "flat_tyre": "Flat Tyre",
    "warning": "Warning",
    "remove_car": "Remove Car",
    "illegally_parked": "Illegally Parked",
    "no_action_taken": "No Action Taken",
  };

  const managerToken = localStorage.getItem("token")

  const formatDate = (date) => {
    const joinedDateISO = date; // "2023-06-25T21:20:45.302375Z"
    const parsedJoinedDate = parseISO(joinedDateISO);

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedJoinedDate = format(parsedJoinedDate, 'yyyy-MM-dd');
    return formattedJoinedDate
  }

  var actionApi = {
    method: "get",
    url: `https://kepah-24275.botics.co​/api/v1/admin/vehicle/action-logs/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  useEffect(() => {

    let vehicleLog = actions.filter((val) => {
      return val.vehicle_number === modalInfo.vehicle_number;
    })
    console.log(vehicleLog)
    console.log(modalInfo)
    setVehicleActions(vehicleLog)
  }, [modalInfo])


  useEffect(() => {
    axios(actionApi).then((res) => {
      console.log(res.data)
      setActions(res.data);
    })
  }, [])

  useEffect(() => {
    console.log(search)
    console.log("Vehicles", vehicles);
    let filter = vehicles.filter((val) => {
      console.log(val.user.name)
      if (search !== "" && val.user && val.user.name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
      if(search !== "" && val.vehicle_number && val.vehicle_number.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
      if(search !== "" && val.tag_number && val.tag_number.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
    });
    if (filter.length < 1 && search === "") {
      setFiltered(vehicles);
    } else {
      setFiltered(filter);
    }
  }, [search]);

  var vehicleApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/vehicle/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }


  useEffect(() => {
    axios(vehicleApi).then((res) => {
      console.log(res.data)
      setVehicles(res.data);
      setFiltered(res.data)
    })
  }, [])

  const popover = (
    // <>
    <Popover id="popover-basic borderVehicleModal">
      <Container className="vehicleInfo">
        <div className="p-3">
          <Row className="pb-3">
            {modalInfo.media_file ? (
              <img
                style={{ height: "200px", width: "100%", borderRadius: "5px" }}
                src={modalInfo.media_file}
              />
            ) : ""}
          </Row>
          <Row>
            Owner: {modalInfo && modalInfo.user ? modalInfo.user.name : "Name not available"}
          </Row>
          <Row>
            Email: {modalInfo && modalInfo.user ? modalInfo.user.email : "Email not available"}
          </Row>
          <Row>
            Phone: {modalInfo && modalInfo.user ? modalInfo.user.phone_number : "Phone not available"}
          </Row>
          <Row>
            Type: {modalInfo && modalInfo.user ? modalInfo.user.user_type : "Type not available"}
          </Row>
          <Row className="pt-2">
            Vehicle History
          </Row>
          <Row className="pt-2">
            <Col>Date Reported</Col>
            <Col>Type</Col>
          </Row>
          {vehicleActions && vehicleActions.map((value) => (
            <Row key={value.created}>
              <Col>{formatDate(value.created)}</Col>
              <Col>{statusMapping[value.status]}</Col>
            </Row>
          ))}
        </div>
      </Container>
    </Popover>
  );

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        popoverOpen &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setPopoverOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [popoverOpen]);



  return (
    <div>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">
            List of Vehicles ({vehicles.length})
          </span>
        </div>
      </div>

      <div className="criminal_input_div">
        <input
          type="text"
          className="criminal_input"
          placeholder="Owner, Tag Number or Licence Number"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="criminal_search_btn">
          <img src={searchImage} />
        </div>
        <Form className="form_inline_criminal">
          <Button
            className="save_btn_add_criminal"
            variant="primary"

            onClick={() => props.history.push("Listofvehicles/add")}
          >
            ADD NEW VEHICLE
          </Button>
        </Form>
      </div>

      <div className="pt-5 vehicleScroll">
        <div className="officer_license_number_main_page">
          <span className="vehicleNameX2">Vehicle Name</span>
          <span className="vehicleOwner">Owner</span>
          <span className="vehiclePhone">Phone</span>
          
          <span className="vehicleNumber">Licence Number</span>
          <span className="vehicleTag">Tag Number</span>
        </div>
        <div className="bottom_border_main_page" />

        <div className="all_criminal_trespass_lists">
          {filtered.map((val, ind) => {
            return (
              <div
                key={ind}
                className="officer_license_number_white_back_main_page row_alternate"
                data-toggle="modal"
                data-target="#exampleModal"
              >

                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={popover}
                  rootClose
                  onToggle={() => {
                    setPopoverOpen(!popoverOpen)
                    setModalInfo(val)
                  }}
                  ref={popoverRef}
                >
                  <span style={{ cursor: "pointer" }} className="vehicleNameX">
                    {val.make} {val.model} {val.year}
                  </span>
                </OverlayTrigger>

                <span className="vehicleOwner">
                  {val.user.name}
                  {/* {val.user_details.name ? val.user_details.name : "-"} */}
                </span>
                <span className="vehiclePhone">
                  {val.user.phone_number}
                </span>
                
                <span className="vehicleNumber">
                  {val.vehicle_number}
                </span>
                <span className="vehicleTag">
                  {val.tag_number}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ListOfVehiclesPage;