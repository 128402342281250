import React, { useState, useEffect } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import EditSvg from "../../../assets/edit.svg"
import getApi from "../../../config/ApiGet";

import searchImage from "../../../assets/Search.png"
import personImage from "../../../assets/profile.png"


const ListOfApartments = ({ ...props }) => {
  const [search, setSearch] = useState("");
  const [searchOnNameVar, setSearchOnNameVar] = useState("");
  const [userList, setUserList] = useState([]);
  const [updatedUserList, setUpdatedUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userListCheck, setUserListCheck] = useState(false);
  const [userListChanged, setUserListChanged] = useState(false); 
  const [searchOn, setSearchOn] = useState(false)
  const [searchOnName, setSearchOnName] = useState(false)

  const [showResident, setShowResident] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [position, setSelectedUserPosition] = useState(null);

  const showResidentInfo = (event, resident) => {
    // Get the position of the clicked apartment number
    const rect = event.currentTarget.getBoundingClientRect();
    const position = { x: rect.left, y: rect.top + rect.height };
    setShowResident(true);
    setSelectedResident(resident);
    setSelectedUserPosition(position);
  };

  const managerToken = localStorage.getItem("token")

  useEffect(() => {
  (async () => {
      const violationType = await getApi("https://kepah-24275.botics.co/api/v1/admin/payment/lease/?page=1&page_size=20");
      console.log("AptList", JSON.stringify(violationType, null, 2))
      setUserList(violationType.results);
      setUpdatedUserList(violationType.results)
  })();
  }, []);

  useEffect(() => {
    if (userList.length > 0 && userList !== null) {
      const filteredResults = userList.filter((user) => {
        if (user) {
          const nameMatch =
            user.resident.name && user.resident.name.toLowerCase().includes(search.toLowerCase());
          const aptMatch =
            user.apartment_number && user.apartment_number.toString().includes(search);
  
          return nameMatch || aptMatch;
        }
        return false;
      });
      console.log(filteredResults)
      setFilteredUsers(filteredResults)
    }
  }, [search, userList]);

  useEffect(() => {
    setUpdatedUserList(filteredUsers)
  }, [filteredUsers])

  useEffect(() => {
    if(search.length > 0) {
      setSearchOn(true)
    } else {
      setSearchOn(false)
    }
  }, [search])

  return (
    <div onClick={(event) => {
      console.log(event)
      const rect = event.currentTarget.getBoundingClientRect();
      const position2 = { x: rect.left, y: rect.top + rect.height };
      console.log("position2")
      console.log(position2)
      if (selectedResident && position && (position.x !== position2.x || position.y !== position2.y)) {
        setSelectedResident(null); // Close the resident info div
      }
    }}>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">List of Apartments</span>
        </div>
      </div>

      <div className="criminal_input_div">
        <input
          type="text"
          className="search_input_list_of_managers"
          placeholder="Search by Name or Apartment Number"
          onChange={(e) => setSearch(e.target.value)}
        />

        <div className="criminal_search_btn">
          <img src={searchImage} />
        </div>
        {/* <Form className="form_inline_criminal">
          <Button
            className="add_new_resident_btn"
            variant="primary"
            type="submit"
            onClick={() => props.addNewApartment()}
          >
            ADD NEW APARTMENT
          </Button>
        </Form> */}
      </div>

      <div 
        className="pt-4">
        <div className="list_of_apt_div">
            <div className="list_of_apt_sc">
              <Container>
                <div className="infoRow">
                  <div className="col_apt_name">
                    Name
                  </div>
                  <div className="col_apt_apt">
                    Apt. Number
                  </div>
                  <div className="col_apt_email">
                    Rent
                  </div>
                  <div className="col_apt_status">
                    Status
                  </div>
                  {/* <div className="col_apt_edit">

                  </div> */}
                </div>
                <div className="bottom_border_manager mt-1 mb-1" />
                {/* <Row>
                  <div className="bottom_border_manager" />
                </Row> */}
                {updatedUserList.map(user => (
                  <div className="infoRow p-1 mt-2 row_alternate" key={user.id}>
                    <div className="col_apt_name" style={{ color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                      {user.resident.name}
                    </div>
                    <div onClick={(event) => console.log("Apt No clicked")} className="col_apt_apt" style={{ color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                      {user.apartment_number}
                    </div>
                    <div className="col_apt_email" style={{ fontSize: "14px", paddingTop: "6px"}}>
                      {user.rent}
                    </div>
                    <div className="col_apt_status" style={{ fontSize: "14px", paddingTop: "6px"}}>
                      {user.status}
                    </div>
                    {/* <div className="col_apt_edit" style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}} onClick={() => {
                      localStorage.setItem("managerInfoChange", JSON.stringify(user));
                      props.setApartment();
                    }}>
                      <img src={EditSvg} style={{ height: "16px", width: "16px" }}/>
                    </div> */}
                  </div>
                  
                ))}
                {selectedResident && (
                  <div>
                    <ResidentInfo
                      className=""
                      resident={selectedResident}
                      position={position}
                      onClose={() => setSelectedResident(null)} // Function to close ResidentInfo
                    />
                  </div>
                )}
              </Container>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfApartments;