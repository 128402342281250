import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./style.css";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { notifyError, notifySuccess, notifyErrorRequired, notifyLoading  } from "../Toast/Toast";


import EyeSign from "../../assets/vector.png"
import ForestClove from "../../assets/forestCove.png"
import Kepah from "../../assets/kepah.png"


const Welcome = ({ ...props }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword]= useState("")
  const [confirmPassword, setConfirmPassword]= useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [token, setToken] = useState("")
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("")
  const [errorFlag, setErrorFlag] = useState(false)
  const [buttonClickedX, setButtonClickedX] = useState(false);
  const [userId, setUserId] = useState('')

  useEffect(() => {
    setPasswordError("")
    setError("")
    setErrorFlag(false)
    setButtonClickedX(false)
  }, [password, confirmPassword])


  const validateFe = () => {
    if(password === "" || confirmPassword === "") {
      setPasswordError("Password Fields Are Required")
      setErrorFlag(true)
    }
    if(password != confirmPassword && !errorFlag) {
      setPasswordError("Password Didn't Match")
      setErrorFlag(true)
    }
  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  useEffect(()=> {
    if(buttonClickedX) {
      if(errorFlag != true){
        handleWelcomeSave()
      }
    }
  }, [errorFlag, buttonClickedX])


  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);

    // Extract the email parameter from the URL
    const emailParam = urlParams.get('user_id');
  
    // Set the email state if the email parameter exists in the URL
    if (emailParam) {
      setUserId(emailParam);
    }

  }, []);
  
  
  useEffect(() => {
    // This effect runs whenever 'token' changes
    console.log("Token:", userId);
  }, [userId]);
  
  // ... rest of your component code ...
  

  var data = {
    user_id: userId,
    new_password1: password,
    new_password2: confirmPassword,
  };



  var config = {
    method: "post",
    url: `https://kepah-24275.botics.co/api/v1/set_password/`,
    data: data
  };

  const handleWelcomeSave = () => {
    setError("Loading")
    console.log(token)
    console.log(data)
    console.log(password)
    console.log(confirmPassword)
    axios(config).then((res) => {
      console.log("Response from reset password:", JSON.stringify(res.data, null, 2))

      if(res.data.status === "fail") {
        setError(res.data.detail)
      } else {
          window.open("https://testflight.apple.com/join/2dYFoGaU", "_blank");
      }
    })
  }

  return (
    <div className="main">
      <div className="img_div">
        <img
          className="forest_img"
          src={ForestClove}
        />
      </div>
      <div className="white_box">
        <div className="all_texts">
          <h5 className="welcome_text">Welcome</h5>
          <p className="email">{email}</p>
        </div>

        <div className="all_texts">
          <p className="">{error}</p>
        </div>

        <div>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="label_create_password">
                Create Password:
              </Form.Label>
              <div className="input_div_login">
                <Form.Control
                  className="input_create_password_login"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                />
                <div className="eye_div">
                  <span className="dot" />
                  <img 
                    src={EyeSign} 
                    onClick={()=> {
                      setShowPassword(!showPassword);
                      console.log(showPassword);
                    }}
                  />
                </div>
              </div>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="label_create_password">
                Confirm Password:
              </Form.Label>
              <div className="input_div_login">
                <Form.Control
                  className="input_create_password_login"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(event) => {
                    setConfirmPassword(event.target.value)
                  }}
                />
                <div className="eye_div">
                  <span className="dot" />
                  <img 
                    src={EyeSign} 
                    onClick={()=> {
                      setShowConfirmPassword(!showConfirmPassword);
                      console.log(showPassword);
                    }}
                  />
                </div>
              </div>
            </Form.Group>
            <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
              {passwordError ? <p style={{fontSize: "13px", paddingLeft: "5px", color: "red"}}>{passwordError}</p> : ""}
            </div>
            

            <Button
              onClick={(event) => {
                event.preventDefault()
                buttonClicked()
              }}
              className="save_btn"
              variant="primary"
              type="submit"
            >
              SAVE
            </Button>
          </Form>
        </div>
      </div>
      <div>
        <img
          className="kepah_img"
          src={Kepah}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Welcome;
