import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { format, parseISO } from 'date-fns';

import logoImage from "../../../assets/forestCove.png";
import Sidebar from "../../Sidebar/Sidebar";

const gapSpan = (text) => {
  return(<span style={{ marginLeft: "40px", marginRight: "40px"}}>{text}</span>)
}

const ShowCriminal = ({ ...props }) => {
  const [userName, setUserName] = useState("")
  const [userAddedDate, setUserAddedDate] = useState("")
  const [userDateOfBirth, setUserDateOfBirth] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [issuerPhone, setIssuerPhone] = useState("")
  const [issuerName, setIssuerName] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [userAddress, setUserAddress] = useState("")
  const [userAddressName, setUserAddressName] = useState("")

  const formatDate = (date) => {
    const joinedDateISO = date; // "2023-06-25T21:20:45.302375Z"
    const parsedJoinedDate = parseISO(joinedDateISO);

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedJoinedDate = format(parsedJoinedDate, 'yyyy-MM-dd');
    return formattedJoinedDate
  }

  useEffect(() => {
    if (localStorage.getItem("criminalInfo") !== undefined) {
      
      const managerInfoChangeString = localStorage.getItem("criminalInfo");
      const managerInfoChange = JSON.parse(managerInfoChangeString);
      console.log(managerInfoChange)
      if (managerInfoChange !== undefined && managerInfoChange.user !== null) {
        setUserName(managerInfoChange.user.name)
        setUserDateOfBirth(formatDate(managerInfoChange.user.dob))
        setUserPhone(managerInfoChange.user.phone_number)
        setUserEmail(managerInfoChange.user.email)
        const address = managerInfoChange.user?.residence_building[0]?.name + ", " + managerInfoChange.user?.residence_building[0]?.address 
        setUserAddress(address)
      }

      setUserAddedDate(formatDate(managerInfoChange.added_on))

      setIssuerName(managerInfoChange.added_by.name)
      setIssuerPhone(managerInfoChange.added_by.phone_number)
    }

  }, []);

  return (
    <div className="incident_back">
      <div className="side_nav">
        <Sidebar history={props.history} nav="Criminal" />
      </div>
      <div className="incident_action_main_div">
        <div className="incident_action_main_page_showCriminal">
          <div >

            <Container style={{ width: "80%" }}>
              <Row className="text-center pt-2">
                <Col className="col-md-11"></Col>
               
              </Row>
              <div className="forest_img_div pt-2 pb-2" style={{ background: "#E7EAFE", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <img
                  src={logoImage}
                  className="forest_img"
                />
              </div>
              <Row className="text-center pt-2">
                <p style={{ fontSize: "30px" }}>Criminal Trespass Warning Notification </p>
              </Row>
              <Row className="pt-2">
                <h6>Date and Time: {userAddedDate}</h6>
              </Row>
              <Row className="pt-2">
                <p>To: <span style={{  fontWeight: 'bold'  }}>{userEmail}</span></p>
              </Row>
              <Row className="text-center pt-2">
                <p>This notice is to inform(   Issued To {gapSpan(userName)}    ) that you are hereby being issued a 
                  <span style={{ color: "red" }}> Criminal Trespass Warning</span> not to return to the property.   You are <span style={{ color: "red" }}>NOT </span> 
                  authorized, permitted, or invited to enter or to remain on this private 
                  property known as ({gapSpan("Forest Cove Apt.")}) Located At ({ gapSpan("California")}) Criminal 
                  Trespassing is the misdemeanor offense of knowingly entering another 
                  person property without consent/authorization or intentionally defacing, 
                  destroying, or demolishing any part of a property totaling $500 or less in 
                  damages. You are further advised if you if you trespass on said Property, the
                  owners will pursue Criminal charges against you for Trespassing. Any 
                  previous authorizations to visit property are hereby revoked.
                </p>
              </Row>
              <Row className=" pt-2">
                <p>Visitor Profile
                </p>
              </Row>
             
              <Row className="pt-2" style={{ paddingLeft: "4%" }}>
                <ol>
                  <li>Violator Name: ({gapSpan(userName)})</li>
                  <li>Address: (  {gapSpan(userAddress)}   )  </li>
                  <li>City, State, Zip: (   {gapSpan("")}   )  </li>
                  <li>Phone Number: (   {gapSpan(userPhone)}   )</li>
                </ol>
              
              </Row>
              <Row className="pt-2">
                <p>Issued From:
                </p>
              </Row>
              <Row className=" pt-2">
                <Col className="col-md-6">
                  <p>Name and phone number <br />Stamped</p>
                </Col>
                <Col className="col-md-6" style={{ display: "flex", justifyContent: "flex-end" }}>
                <p>Date and Time <br />{userAddedDate}</p>
                </Col>
              </Row>
       
              <Row style={{ display: 'flex', justifyContent: 'center' }} className=" pt-2">
                <button style={{ width: "30%", background: '#ebf4fe' }} onClick={() => {
                  props.history.goBack()
                }}>
                  Close
                </button>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ShowCriminal