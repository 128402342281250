import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";

import searchImage from "../../../assets/Search.png"
import EditSvg from "../../../assets/edit.svg"


import "bootstrap/dist/css/bootstrap.min.css";

import { Popover, OverlayTrigger, Row, Col, Container } from "react-bootstrap";

const ListOfSecurityOfficers = ({ ...props }) => {

  const [filtered, setFiltered] = useState([]);
  const [securityCompany, setSecurityCompany] = useState([])
  const [search, setSearch] = useState("");

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);
  const [modalInfo, setModalInfo] = useState(null)

  const managerToken = localStorage.getItem("token")

  var userApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/security-personnel/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  useEffect(()=> {

    axios(userApi).then((res) => {
      console.log("apt")
      console.log(res.data)
      setSecurityCompany(res.data)
      setFiltered(res.data)
    })
  }, [])

  useEffect(() => {
    console.log(securityCompany)
    let filter = securityCompany.filter((val) => {
      if (search !== "" && val.name && val.name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
      if(search !== "" && val.license_number && val.license_number.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
    });
    if (filter.length < 1 && search === "") {
      setFiltered(securityCompany);
    } else {
      setFiltered(filter);
    }
  }, [search]);

  const popover = (
    // <>
    <Popover id="popover-basic borderVehicleModal">
      <Container className="vehicleInfo">
        <div className="p-3">
          <Row className="pb-3">
            {modalInfo && modalInfo.profile_picture ? (
              <img
                style={{ height: "200px", width: "200px", borderRadius: "5px" }}
                src={modalInfo.profile_picture}
              />
            ) : ""}
          </Row>
          <Row>
            Name: {modalInfo && modalInfo.name ? modalInfo.name : "Name not available"}
          </Row>
          <Row>
            Email: {modalInfo && modalInfo.email ? modalInfo.email : "Email not available"}
          </Row>
          <Row>
            Phone: {modalInfo && modalInfo.phone_number ? modalInfo.phone_number : "Phone not available"}
          </Row>
          <Row>
            Licence No: {modalInfo && modalInfo.license_number ? modalInfo.license_number : "Licence not available"}
          </Row>
          <Row>
            Expiration Status: {modalInfo && modalInfo.license_number_expiration_status ? "Expired" : "Not Expired"}
          </Row>
          <Row>
            Expiration Date: {modalInfo && modalInfo.license_number_expiration_date ? modalInfo.license_number_expiration_date : "Licence Expiration Date Not Available"}
          </Row>
        </div>
      </Container>
    </Popover>
  );

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        popoverOpen &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setPopoverOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [popoverOpen]);

  return (
    <div>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">
            List of Security Officers ({securityCompany.length})
          </span>
        </div>
      </div>

      <div className="list_of_officers_input_div">
        <input
          type="text"
          className="list_of_officers_input"
          placeholder="Name or License No...."
          onChange={(e) => setSearch(e.target.value)}
          // value={(e) => e.target.val}
        />
        <div className="list_of_officers_search_btn">
          <img src={searchImage} />
        </div>
        
      </div>

      <div className="">
        <div className="officer_license_number_main_page">
          <span className="securityOfficerName">Officer</span>
          <span className="securityOfficerName">Licence Number</span>
          <span className="securityOfficerName">Phone</span>
          <span className="securityOfficerEmail">Email</span>
        </div>
        <div className="bottom_border_main_page" />

        <div className="all_security_companies_lists">
          {filtered.map((val, ind) => {
            return (
              <div
                className="officer_license_number_white_back_main_page row_alternate"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={popover}
                  rootClose
                  onToggle={() => {
                    setPopoverOpen(!popoverOpen)
                    setModalInfo(val)
                  }}
                  ref={popoverRef}
                >
                  <span style={{ color: "#257CFF", cursor: "pointer" }} className="securityOfficerName">
                    {val.name ? val.name : ""}
                  </span>
                </OverlayTrigger>
              
                <span className="securityOfficerName">
                  {val.license_number}
                </span>
                <span className="securityOfficerName">
                  {val.phone_number ? val.phone_number : ""}
                </span>

                <span className="securityOfficerEmail">
                  {val.email ? val.email : ""}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ListOfSecurityOfficers;