import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import EditSvg from "../../../assets/edit.svg"

import { format, parseISO } from 'date-fns';
import searchImage from "../../../assets/Search.png"
import Sidebar from "../../Sidebar/Sidebar";


import { Popover, OverlayTrigger, Row, Col, Container } from "react-bootstrap";

const ListOfVendorsPage = ({ ...props }) => {
  const [criminalTrespass, setCriminalTrespass] = useState([1]);
  const [token, setToken] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");


  const [vehicles, setVehicles] = useState([])
  const [vehicleActions, setVehicleActions] = useState([])
  const [modalInfo, setModalInfo] = useState([]);
  const [actions, setActions] = useState([])

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  const statusMapping = {
    "towed": "Towed",
    "booted": "Booted",
    "damaged": "Damaged",
    "vehicle_fine": "Vehicle Fine",
    "flat_tyre": "Flat Tyre",
    "warning": "Warning",
    "remove_car": "Remove Car",
    "illegally_parked": "Illegally Parked",
    "no_action_taken": "No Action Taken",
  };

  const andy = [
    {
      name: "John Doe",
      company: "ABC Corporation",
      phone_number: "555-123-4567",
      email: "john.doe@example.com",
      arrival_time: "09:00 AM",
      departure_time: "05:00 PM",
    },
    {
      name: "Jane Smith",
      company: "XYZ Inc.",
      phone_number: "555-987-6543",
      email: "jane.smith@example.com",
      arrival_time: "08:30 AM",
      departure_time: "04:30 PM",
    },
    {
      name: "Alice Johnson",
      company: "123 Industries",
      phone_number: "555-555-5555",
      email: "alice.johnson@example.com",
      arrival_time: "08:00 AM",
      departure_time: "04:00 PM",
    },
    {
      name: "Bob Brown",
      company: "Acme Corporation",
      phone_number: "555-888-9999",
      email: "bob.brown@example.com",
      arrival_time: "10:00 AM",
      departure_time: "06:00 PM",
    },
    {
      name: "Eva Anderson",
      company: "Tech Solutions",
      phone_number: "555-777-3333",
      email: "eva.anderson@example.com",
      arrival_time: "09:30 AM",
      departure_time: "05:30 PM",
    },
    {
      name: "Michael Wilson",
      company: "Global Innovations",
      phone_number: "555-444-2222",
      email: "michael.wilson@example.com",
      arrival_time: "10:30 AM",
      departure_time: "06:30 PM",
    },
    {
      name: "Sara Davis",
      company: "Swift Services",
      phone_number: "555-666-7777",
      email: "sara.davis@example.com",
      arrival_time: "08:45 AM",
      departure_time: "04:45 PM",
    },
    {
      name: "David Lee",
      company: "Dynamic Ventures",
      phone_number: "555-222-1111",
      email: "david.lee@example.com",
      arrival_time: "09:15 AM",
      departure_time: "05:15 PM",
    },
    // Add more objects as needed
  ];


  const managerToken = localStorage.getItem("token")

  const formatDate = (date) => {
    const joinedDateISO = date; // "2023-06-25T21:20:45.302375Z"
    const parsedJoinedDate = parseISO(joinedDateISO);

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedJoinedDate = format(parsedJoinedDate, 'yyyy-MM-dd');
    return formattedJoinedDate
  }

  const formatDate2 = (date) => {
    const joinedDateISO = date; // "2023-06-25T21:20:45.302375Z"
    const parsedJoinedDate = parseISO(joinedDateISO);

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedJoinedDate = format(parsedJoinedDate, 'yyyy-MM-dd');
    return formattedJoinedDate
  }

  var actionApi = {
    method: "get",
    url: `https://kepah-24275.botics.co​/api/v1/admin/vehicle/action-logs/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  useEffect(() => {

    let vehicleLog = actions.filter((val) => {
      return val.vehicle_number === modalInfo.vehicle_number;
    })
    console.log(vehicleLog)
    console.log(modalInfo)
    setVehicleActions(vehicleLog)
  }, [modalInfo])


  useEffect(() => {
    // axios(actionApi).then((res) => {
    //   console.log(res.data)
    //   setActions(res.data);
    // })
    // setFiltered(andy)
  }, [])

  useEffect(() => {
    console.log(search)
    let filter = vehicles.filter((val) => {
      if (search !== "" && val.name && val.name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
      if (search !== "" && val.company && val.company.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }

    });
    if (filter.length < 1 && search === "") {
      setFiltered(vehicles);
    } else {
      setFiltered(filter);
    }
  }, [search]);

  var vehicleApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/vendors/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }


  useEffect(() => {
    axios(vehicleApi).then((res) => {
      console.log(res.data)
      setVehicles(res.data);
      setFiltered(res.data)
    })
  }, [])

  const popover = (
    // <>
    <Popover id="popover-basic borderVehicleModal">
      <Container className="vehicleInfo">
        <div className="p-3">
          <Row className="pb-3">
            {modalInfo.media_file ? (
              <img
                style={{ height: "200px", width: "100%", borderRadius: "5px" }}
                src={modalInfo.media_file}
              />
            ) : ""}
          </Row>
          <Row>
            Owner: {modalInfo && modalInfo.user ? modalInfo.user.name : "Name not available"}
          </Row>
          <Row>
            Email: {modalInfo && modalInfo.user ? modalInfo.user.email : "Email not available"}
          </Row>
          <Row>
            Phone: {modalInfo && modalInfo.user ? modalInfo.user.phone_number : "Phone not available"}
          </Row>
          <Row>
            Type: {modalInfo && modalInfo.user ? modalInfo.user.user_type : "Type not available"}
          </Row>
          <Row className="pt-2">
            Vehicle History
          </Row>
          <Row className="pt-2">
            <Col>Date Reported</Col>
            <Col>Type</Col>
          </Row>
          {vehicleActions && vehicleActions.map((value) => (
            <Row key={value.created}>
              <Col>{formatDate(value.created)}</Col>
              <Col>{statusMapping[value.status]}</Col>
            </Row>
          ))}
        </div>
      </Container>
    </Popover>
  );

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        popoverOpen &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setPopoverOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [popoverOpen]);



  return (
    <div className="incident_back">
      <div className="side_nav">
        <Sidebar history={props.history} nav="List Of Vendors" />
      </div>
      <div className="incident_action_main_div">

        <div className="incident_action_main_page" >
          <div>
            <div className="list_of_officers_manage_div_main_page">
              <div>
                <span className="list_of_officers_text_main_page">
                  List of Vendors ({vehicles.length})
                </span>
              </div>
            </div>

            <div className="criminal_input_div">
              <input
                type="text"
                className="criminal_input"
                placeholder="Name or Company Name"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="criminal_search_btn">
                <img src={searchImage} />
              </div>
              <Form className="form_inline_criminal">
                <Button
                  className="save_btn_add_criminal"
                  variant="primary"

                  onClick={() => props.history.push("listofvendors/add")}
                >
                  ADD NEW VENDOR
                </Button>
              </Form>
            </div>

            <div className="pt-2 vehicleScroll">
              <div className="officer_license_number_main_page">
                <span className="vendorName">Name</span>
                <span className="vendorCompany">Company Name</span>
                <span className="vendorPhone">Phone Number</span>
                <span className="vendorEmail">Email</span>
                <span className="vendorTimeArival">Time Arival</span>
                <span className="vendorTimeDeparture">Time Departure</span>
                <span className="vendorEdit"></span>
              </div>
              <div className="bottom_border_main_page" />

              <div className="all_criminal_trespass_lists">
                {filtered.map((val, ind) => {
                  return (
                    <div
                      key={ind}
                      className="officer_license_number_white_back_main_page row_alternate"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >

                      {/* <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={popover}
                  rootClose
                  onToggle={() => {
                    setPopoverOpen(!popoverOpen)
                    setModalInfo(val)
                  }}
                  ref={popoverRef}
                > */}
                      <span className="vendorName">
                        {val.name}
                      </span>
                      {/* </OverlayTrigger> */}

                      <span className="vendorCompany">
                        {val.company}
                        {/* {val.user_details.name ? val.user_details.name : "-"} */}
                      </span>
                      <span className="vendorPhone">
                        {val.phone_number}
                      </span>

                      <span className="vendorEmail">
                        {val.email}
                      </span>
                      <span className="vendorTimeArival">
                        {val.arrival_time && val.arrival_time.substring(0, 5)}
                      </span>
                      <span className="vendorTimeDeparture">
                        {val.departure_time && val.departure_time.substring(0, 5)}
                      </span>
                      <span className="vendorEdit" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div onClick={() => {
                          localStorage.setItem("vendorInfoChange", JSON.stringify(val));
                          props.history.push("listofvendors/edit");
                        }}>
                          <img src={EditSvg} style={{ height: "16px", width: "16px" }} />
                        </div>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  );
};

export default ListOfVendorsPage;