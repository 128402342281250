import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

import { format, parseISO } from 'date-fns';
import searchImage from "../../../assets/Search.png"

import { Popover, OverlayTrigger, Row, Col, Container } from "react-bootstrap";
import getApi from "../../../config/ApiGet";
import callApi from "../../../config/callApi";

const ListOfVehiclesPage = ({ ...props }) => {
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");
  const [rentRoll, setRentRoll] = useState([])
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);
  const [modalInfo, setModalInfo] = useState(null);
  const managerToken = localStorage.getItem("token")
  const [vehicleInfo, setVehicleInfo] = useState(null);

  const formatDate = (date) => {
    const joinedDateISO = date; // "2023-06-25T21:20:45.302375Z"
    const parsedJoinedDate = parseISO(joinedDateISO);

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedJoinedDate = format(parsedJoinedDate, 'yyyy-MM-dd');
    return formattedJoinedDate
  }

  useEffect(() => {
    console.log(search)
    console.log("rentRoll", rentRoll);
    let filter = rentRoll.filter((val) => {
      if (search !== "" && val.name && val.name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
      if(search !== "" && val.apartment_number && val.apartment_number.number.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
    });
    if (filter.length < 1 && search === "") {
      setFiltered(rentRoll);
    } else {
      setFiltered(filter);
    }
  }, [search]);

  var rentApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/rent-roll/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  useEffect(() => {
    axios(rentApi).then((res) => {
      console.log(res.data)
      setRentRoll(res.data);
      setFiltered(res.data)
    })
  }, [])


  const popover = (
    // <>
    <Popover id="popover-basic borderVehicleModal">
      <Container className="vehicleInfo">
        <div className="p-3">
          <Row>
            {modalInfo && (
              <>
                  <p>Resident Info</p>
                  <Row>
                    <Col className="col-md-6">
                      Name
                      </Col>
                      <Col className="col-md-6">
                        {modalInfo?.name}
                      </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-6">
                      Email
                      </Col>
                      <Col className="col-md-6">
                        {modalInfo?.email}
                      </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-6">
                      Phone
                      </Col>
                      <Col className="col-md-6">
                        {modalInfo?.phone}
                      </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-6">
                      Resident Building
                      </Col>
                      <Col className="col-md-6">
                        {modalInfo?.residence_building[0]?.name}, {modalInfo?.residence_building[0]?.address}
                      </Col>
                  </Row>
              </>
            )}
            {modalInfo && modalInfo.vehicles.map((val, ind) => {
              return (
                <>
                  <div className="vehicleInfoDiv">
                    <p>Vehicle Info</p>
                    <Row>
                      <Col className="col-md-6">
                        Vehicle Number
                      </Col>
                      <Col className="col-md-6">
                        {val.vehicle_number}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-md-6">
                        Vehicle Make
                      </Col>
                      <Col className="col-md-6">
                        {val.make}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-md-6">
                        Vehicle Year
                      </Col>
                      <Col className="col-md-6">
                        {val.year}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-md-6">
                        Vehicle Status
                      </Col>
                      <Col className="col-md-6">
                        {val.status}
                      </Col>
                    </Row>
                  </div>
                </>
              )
            })}
          </Row>
        </div>
      </Container>
    </Popover>
  );



  return (
    <div>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">
            Rent Roll ({rentRoll.length})
          </span>
        </div>
      </div>

      <div className="criminal_input_div">
        <input
          type="text"
          className="criminal_input"
          placeholder="Name or Apartment No."
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="criminal_search_btn">
          <img src={searchImage} />
        </div>

      </div>

      <div className="pt-2 vehicleScroll">
        <div className="officer_license_number_main_page">
          <span className="rentName">Resident</span>
          <span className="rentApt">Apt. No</span>
          <span className="rentPhone">Phone</span>
          <span className="rentEmail">Email</span>
          <span className="rentVehicle">Vehicle Info</span>
        </div>
        <div className="bottom_border_main_page" />

        <div className="all_criminal_trespass_lists">
          {filtered.map((val, ind) => {
            return (
              <div
                key={ind}
                className="officer_license_number_white_back_main_page row_alternate"
                data-toggle="modal"
                data-target="#exampleModal"
              >

                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={popover}
                  rootClose
                  onToggle={() => {
                    setPopoverOpen(!popoverOpen)
                    setModalInfo(val)
                  }}
                  ref={popoverRef}
                >
                  <span style={{ cursor: "pointer" }} className="rentNameField">
                    {val?.name}
                  </span>
                </OverlayTrigger>

                <span className="rentApt">
                  {val?.apartment_number?.number}
                  {/* {val.user_details.name ? val.user_details.name : "-"} */}
                </span>
                <span className="rentPhone">
                  {val?.phone_number}
                </span>
                
                <span className="rentEmail">
                  {val?.email}
                </span>
                <span className="rentVehicle">
                  {val?.vehicles[0]?.vehicle_number}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ListOfVehiclesPage;