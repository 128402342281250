import React, { useState, useEffect } from "react";
import "./style.css";
import Sidebar from "../Sidebar/Sidebar";
import CriminalTrespassListPage from "./CriminalTrespassListPage/CriminalTrespassListPage";
import AddCriminal from "./AddCriminal/AddCriminal";
import { Col, Container, Row } from "react-bootstrap";

import { format, parseISO } from 'date-fns';
import ShowCriminal from "./ShowCriminal/ShowCriminal";


const CriminalTrespassList = ({ ...props }) => {
  const [addCriminal, setAddCriminal] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [userName, setUserName] = useState("")
  const [userAddedDate, setUserAddedDate] = useState("")
  const [userDateOfBirth, setUserDateOfBirth] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [issuerPhone, setIssuerPhone] = useState("")
  const [issuerName, setIssuerName] = useState("")

  const addCrim = () => {
    setAddCriminal(true)
  }
  const closeAddCrim = () => {
    setAddCriminal(false)
  }

  const showEm = () => {
    setShowEmail(true)
  }
  const closeShowEm = () => {
    setShowEmail(false)
  }

  if(!addCriminal && !showEmail) {
    return (
      <div className="incident_back">
        <div className="side_nav">

          <Sidebar history={props.history} nav="Criminal" />
        </div>
        <div className="incident_action_main_div">
          <div className="incident_action_main_page_criminalList">
            <CriminalTrespassListPage history={props.history} addCrim={addCrim} showEm={showEm}/>
          </div>
        </div>
      </div>
    );
  } else if(addCriminal) {
    return (
      <AddCriminal history={props.history} closeAddCrim={closeAddCrim}/>
    );
  } else if(showEm) {
    return(
      <ShowCriminal history={props.history} closeShowEm={closeShowEm} />
    )
  }
};

export default CriminalTrespassList;
