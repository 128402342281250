import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import Dashboard from "./components/Dashboard/DashboardScreen/Dashboard";
import Login from "./components/Login/Login";
import Welcome from "./components/Welcome/Welcome";
import Welcome2 from "./components/Welcome/Welcome2";
import ResetPassword from "./components/Welcome/ResetPassword";
import ListOfManagers from "./components/ListOfManagers/index";
import ListOfResidents from "./components/ListOfResidents";
import ListofTenants from "./components/ListofTenants";
import IncidentReports from "./components/IncidentReports";
import ListOfVendors from "./components/ListOfVendors";
import ListOfVehicles from "./components/ListOfVehicles";
import InsurancePolicy from "./components/InsurancePolicy";
import ListOfSecurityCompanies from "./components/ListOfSecurityCompanies";
import SexOffenders from "./components/SexOffenders";
import MakeASchedule from "./components/OfficersSchedule/MakeASchedule/MakeASchedule";
import UpdateLeaseInformation from "./components/UpdateLeaseInformation";
import MaintenanceRequests from "./components/MaintenanceRequests";
import OfficersSchedule from "./components/OfficersSchedule";
import ListOfOfficers from "./components/ListOfOfficers";
import RemoveLogo from "./components/RemoveLogo";
import CriminalTrespassList from "./components/CriminalTrespassList";
import PoliceLiasion from "./components/PoliceLiasion";
import TermsandConditions from "./components/Terms&Conditions";
import RentRoll from "./components/RentRoll";
import VehicleAction from "./components/VehicleAction";

// Manager
import AddManager from "./components/ListOfManagers/AddManager/AddManager";
import EditManager from "./components/ListOfManagers/EditManager/EditManager";

// Resident
import AddNewResident from "./components/ListOfResidents/AddNewResident/AddNewResident";
import EditResident from "./components/ListOfResidents/EditResident/EditResident";

// Tenant
import AddNewTenant from "./components/ListofTenants/AddNewTenant/AddNewTenant";
import EditTenantProfile from "./components/ListofTenants/EditTenantProfile/EditTenantProfile";

// List of Vehicles
import AddVehicle from "./components/ListOfVehicles/AddVehicle/AddVehicle";

// List of Vendors
import AddNewVendor from "./components/ListOfVendors/AddNewVendor/AddNewVendor";
import EditVendor from "./components/ListOfVendors/EditVendor/EditVendor";

// Security Company
import AddNewSecurityCompany from "./components/ListOfSecurityCompanies/AddSecurityCompany/AddSecurityCompany";
import EditSecurityCompany from "./components/ListOfSecurityCompanies/EditSecurityCompany/EditSecurityCompany";

// Criminal Trespasser
import AddCriminal from "./components/CriminalTrespassList/AddCriminal/AddCriminal";

//Incident Reports
import TowReportTemplate from "./components/IncidentReports/AddIncidentReport/TowReportTemplate";
import AutoReportTemplate from "./components/IncidentReports/AddIncidentReport/AutoReport";
import IncidentReportTemplate from "./components/IncidentReports/AddIncidentReport/IncidentReportTemplate";
import AddIncidentReport from "./components/IncidentReports/AddIncidentReport/AddIncidentReport";
import IncidentReportTemplatePt2 from "./components/IncidentReports/AddIncidentReport/AddIncidentReportPt2";
import ShowCriminal from "./components/CriminalTrespassList/ShowCriminal/ShowCriminal";
import EditMaintenance from "./components/MaintenanceRequests/EditMaintenance/EditMaintenance";
import MaintenanceProfile from "./components/MaintenanceRequests/MaintenanceProfile/MaintenanceProfile";
import MessageMain from "./components/Message";
import MessageDetails from "./components/Message/MessageDetails";

// Private Route
import PrivateRoute from "./components/Sidebar/PrivateRoute";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/welcome"
            render={(props) => <Welcome {...props} />}
          />
          <Route
            exact
            path="/welcome-visitor"
            render={(props) => <Welcome2 {...props} />}
          />
          <Route
            exact
            path="/reset-password"
            render={(props) => <ResetPassword {...props} />}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            component={(props) => <Dashboard {...props} />}
          />
          <PrivateRoute
            exact
            path="/list"
            component={(props) => <ListOfManagers {...props} />}
          />
          <PrivateRoute
            path="/list/add"
            component={(props) => <AddManager {...props} />}
          />
          <PrivateRoute
            path="/list/edit"
            component={(props) => <EditManager {...props} />}
          />
          <PrivateRoute
            exact
            path="/listofresidents"
            component={(props) => <ListOfResidents {...props} />}
          />
          <PrivateRoute
            path="/listofresidents/add"
            component={(props) => <AddNewResident {...props} />}
          />
          <PrivateRoute
            path="/listofresidents/edit"
            component={(props) => <EditResident {...props} />}
          />
          <PrivateRoute
            exact
            path="/listoftenants"
            component={(props) => <ListofTenants {...props} />}
          />
          <PrivateRoute
            path="/listoftenants/add"
            component={(props) => <AddNewTenant {...props} />}
          />
          <PrivateRoute
            path="/listoftenants/edit"
            component={(props) => <EditTenantProfile {...props} />}
          />
          <PrivateRoute
            exact
            path="/updateleaseinformation"
            component={(props) => <UpdateLeaseInformation {...props} />}
          />
          <PrivateRoute
            exact
            path="/incidentreports"
            component={(props) => <IncidentReports {...props} />}
          />
          <PrivateRoute
            path="/incidentreports/incident"
            component={(props) => <AddIncidentReport {...props} />}
          />
        
          <PrivateRoute
            path="/incidentreports/auto"
            component={(props) => <AutoReportTemplate {...props} />}
          />
          <PrivateRoute
            path="/incidentreports/tow"
            component={(props) => <TowReportTemplate {...props} />}
          />
          <PrivateRoute
            exact
            path="/listofvendors"
            component={(props) => <ListOfVendors {...props} />}
          />
          <PrivateRoute
            exact
            path="/listofvendors/add"
            component={(props) => <AddNewVendor {...props} />}
          />
          <PrivateRoute
            exact
            path="/listofvendors/edit"
            component={(props) => <EditVendor {...props} />}
          />
          <PrivateRoute
            exact
            path="/Listofvehicles"
            component={(props) => <ListOfVehicles {...props} />}
          />
          <PrivateRoute
            path="/Listofvehicles/add"
            component={(props) => <AddVehicle {...props} />}
          />
          <PrivateRoute
            exact
            path="/maintenancerequest"
            component={(props) => <MaintenanceRequests {...props} />}
          />
          <PrivateRoute
            path="/maintenancerequest/officer"
            component={(props) => <EditMaintenance {...props} />}
          />
          <PrivateRoute
            path="/maintenancerequest/edit"
            component={(props) => <MaintenanceProfile {...props} />}
          />
          <PrivateRoute
            exact
            path="/officersmanagement"
            component={(props) => <OfficersSchedule {...props} />}
          />
          <PrivateRoute
            path="/officersmanagement/add"
            component={(props) => <MakeASchedule {...props} />}
          />
          <PrivateRoute
            exact
            path="/ListOfSecurityCompanies"
            component={(props) => <ListOfSecurityCompanies {...props} />}
          />
          <PrivateRoute
            path="/ListOfSecurityCompanies/add"
            component={(props) => <AddNewSecurityCompany {...props} />}
          />
          <PrivateRoute
            path="/ListOfSecurityCompanies/edit"
            component={(props) => <EditSecurityCompany {...props} />}
          />
          <PrivateRoute
            exact
            path="/offenders"
            component={(props) => <SexOffenders {...props} />}
          />
          <PrivateRoute
            exact
            path="/insurancepolicy"
            component={(props) => <InsurancePolicy {...props} />}
          />
          <PrivateRoute
            exact
            path="/vehicleaction"
            component={(props) => <VehicleAction {...props} />}
          />
          <PrivateRoute
            exact
            path="/listofofficers"
            component={(props) => <ListOfOfficers {...props} />}
          />
          <PrivateRoute
            exact
            path="/addremovelogo"
            component={(props) => <RemoveLogo {...props} />}
          />
          <PrivateRoute
            exact
            path="/sexoffender"
            component={(props) => <SexOffenders {...props} />}
          />
          <PrivateRoute
            exact
            path="/criminal"
            component={(props) => <CriminalTrespassList {...props} />}
          />
          <PrivateRoute
            exact
            path="/criminal/add"
            component={(props) => <AddCriminal {...props} />}
          />
          <PrivateRoute
            exact
            path="/criminal/show"
            component={(props) => <ShowCriminal {...props} />}
          />
          <PrivateRoute
            exact
            path="/policeliasion"
            component={(props) => <PoliceLiasion {...props} />}
          />
          <PrivateRoute
            exact
            path="/termsandconditions"
            component={(props) => <TermsandConditions {...props} />}
          />
          <PrivateRoute
            exact
            path="/rentroll"
            component={(props) => <RentRoll {...props} />}
          />
          <PrivateRoute
            exact
            path="/message"
            component={(props) => <MessageMain {...props} />}
          />
          <PrivateRoute
            path="/message/details"
            component={(props) => <MessageDetails {...props} />}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
