import React, { useEffect, useState } from "react";
import "./ShiftGanttChart.css";

import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { format } from 'date-fns';
import calenderImage from "../../../assets/calendar.png";
import DatePicker from "react-datepicker";


const SVG_WIDTH = 620;
const SVG_HEIGHT = 320;
const SVG_FOR_EACH = 50;
const data = [
  {
    name: "Phoenix Schaefer",
    shifts: [
      { startTime: "2023-09-17T16:00:00.000Z", endTime: "2023-09-17T20:00:00.000Z" },
      { startTime: "2023-09-17T08:00:00.000Z", endTime: "2023-09-17T12:00:00.000Z" },
    ],
  },
  {
    name: "User 2",
    shifts: [
      { startTime: "2023-09-17T01:00:00.000Z", endTime: "2023-09-17T05:00:00.000Z" },
      { startTime: "2023-09-17T09:00:00.000Z", endTime: "2023-09-17T11:00:00.000Z" },
    ],
  },
  {
    name: "User 3",
    shifts: [
      { startTime: "2023-09-17T23:00:00.000Z", endTime: "2023-09-17T23:59:00.000Z" },
      { startTime: "2023-09-17T08:00:00.000Z", endTime: "2023-09-17T23:59:00.000Z" },
    ],
  },
  {
    name: "User 5",
    shifts: [
      { startTime: "2023-09-17T01:00:00.000Z", endTime: "2023-09-17T05:00:00.000Z" },
      { startTime: "2023-09-18T09:00:00.000Z", endTime: "2023-09-18T11:00:00.000Z" },
      { startTime: "2023-09-19T09:00:00.000Z", endTime: "2023-09-19T11:00:00.000Z" },
      { startTime: "2023-09-19T12:00:00.000Z", endTime: "2023-09-19T16:00:00.000Z" },
    ],
  },
  {
    name: "User 6",
    shifts: [
      { startTime: "2023-09-17T01:00:00.000Z", endTime: "2023-09-17T05:00:00.000Z" },
      { startTime: "2023-09-18T09:00:00.000Z", endTime: "2023-09-18T11:00:00.000Z" },
      { startTime: "2023-09-19T09:00:00.000Z", endTime: "2023-09-19T11:00:00.000Z" },
      { startTime: "2023-09-19T12:00:00.000Z", endTime: "2023-09-19T16:00:00.000Z" },
    ],
  },
  {
    name: "User 7",
    shifts: [
      { startTime: "2023-09-17T01:00:00.000Z", endTime: "2023-09-17T05:00:00.000Z" },
      { startTime: "2023-09-18T09:00:00.000Z", endTime: "2023-09-18T11:00:00.000Z" },
      { startTime: "2023-09-19T09:00:00.000Z", endTime: "2023-09-19T11:00:00.000Z" },
      { startTime: "2023-09-19T12:00:00.000Z", endTime: "2023-09-19T16:00:00.000Z" },
    ],
  },
  {
    name: "User 8",
    shifts: [
      { startTime: "2023-09-17T01:00:00.000Z", endTime: "2023-09-17T05:00:00.000Z" },
      { startTime: "2023-09-18T09:00:00.000Z", endTime: "2023-09-18T11:00:00.000Z" },
      { startTime: "2023-09-19T09:00:00.000Z", endTime: "2023-09-19T11:00:00.000Z" },
      { startTime: "2023-09-19T12:00:00.000Z", endTime: "2023-09-19T16:00:00.000Z" },
    ],
  },
  // {
  //   name: "Phoenix Schaefer",
  //   shifts: [
  //     { startTime: "2023-09-17T16:00:00.000Z", endTime: "2023-09-17T20:00:00.000Z" },
  //     { startTime: "2023-09-17T08:00:00.000Z", endTime: "2023-09-17T12:00:00.000Z" },
  //   ],
  // },
  // {
  //   name: "User 2",
  //   shifts: [
  //     { startTime: "2023-09-17T01:00:00.000Z", endTime: "2023-09-17T05:00:00.000Z" },
  //     { startTime: "2023-09-17T09:00:00.000Z", endTime: "2023-09-17T11:00:00.000Z" },
  //   ],
  // },
  // {
  //   name: "User 3",
  //   shifts: [
  //     { startTime: "2023-09-17T23:00:00.000Z", endTime: "2023-09-17T23:59:00.000Z" },
  //     { startTime: "2023-09-17T08:00:00.000Z", endTime: "2023-09-17T23:59:00.000Z" },
  //   ],
  // },
  // {
  //   name: "User 5",
  //   shifts: [
  //     { startTime: "2023-09-17T01:00:00.000Z", endTime: "2023-09-17T05:00:00.000Z" },
  //     { startTime: "2023-09-18T09:00:00.000Z", endTime: "2023-09-18T11:00:00.000Z" },
  //     { startTime: "2023-09-19T09:00:00.000Z", endTime: "2023-09-19T11:00:00.000Z" },
  //     { startTime: "2023-09-19T12:00:00.000Z", endTime: "2023-09-19T16:00:00.000Z" },
  //   ],
  // },
  // {
  //   name: "User 6",
  //   shifts: [
  //     { startTime: "2023-09-17T01:00:00.000Z", endTime: "2023-09-17T05:00:00.000Z" },
  //     { startTime: "2023-09-18T09:00:00.000Z", endTime: "2023-09-18T11:00:00.000Z" },
  //     { startTime: "2023-09-19T09:00:00.000Z", endTime: "2023-09-19T11:00:00.000Z" },
  //     { startTime: "2023-09-19T12:00:00.000Z", endTime: "2023-09-19T16:00:00.000Z" },
  //   ],
  // },
];

function ShiftGanttChart({ ...props }) {
  const currentDate = new Date();
  const startOfDay = new Date(currentDate);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(currentDate);
  endOfDay.setHours(23, 59, 59, 999);

  const [selectedStartDate, setSelectedStartDate] = useState(startOfDay);
  const [selectedEndDate, setSelectedEndDate] = useState(endOfDay);
  const [dynamicHight, setDynamicHight] = useState(400)
  const [mData, setMdata] = useState([])
  const [dateSelected, setDateSelected] = useState(new Date())
  const [dateSelected2, setDateSelected2] = useState(new Date())

  const [dataForGraph, setDataForGraph] = useState([])

  const [weekly, setWeekly] = useState(false)

  const managerToken = localStorage.getItem("token")
  const [userList, setUserList] = useState(null)
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    setDateSelected(props.dateSelected)
  }, [props.dateSelected])

  useEffect(() => {
    localStorage.setItem("weekly", JSON.stringify(weekly));
  }, [weekly])

  useEffect(() => {
    localStorage.setItem("selectedStartDate", JSON.stringify(selectedStartDate));
  }, [selectedStartDate])

  useEffect(() => {
    localStorage.setItem("selectedEndDate", JSON.stringify(selectedEndDate));
  }, [selectedEndDate])
  

  useEffect(() => {
      var config = {
        method: "get",
        url: `https://kepah-24275.botics.co/api/v1/admin/officer-schedule/`,
        headers: {
          Authorization: `token ${managerToken}`,
          "Content-Type": "application/json",
        },
      };

      axios(config).then((res) => {
        setUserList(res.data)
      });
    }, []);

    function convertData(rawData) {
      const userShiftsMap = new Map();
  
      // Iterate through the rawData
      rawData.forEach((item) => {
        const userId = item.user;
        const startTime = item.start_time;
        const endTime = item.end_time;
  
        // Create a userShifts object if it doesn't exist
        if (!userShiftsMap.has(userId)) {
          userShiftsMap.set(userId, {
            user: item.officer_details, // You can use any user information from the item
            shifts: [],
          });
        }
  
        // Add the shift to the userShifts object
        userShiftsMap.get(userId).shifts.push({
          startTime,
          endTime,
        });
      });
  
      // Convert the Map to an array of objects
      const result = Array.from(userShiftsMap.values());
      return result;
    }

    useEffect(() => {
      if(userList !== null && selectedEndDate && selectedStartDate) {
        console.log("List", userList)
        const filteredData = userList.filter((user) => {
          const shiftStartTime = new Date(user.start_time);
          const shiftEndTime = new Date(user.start_time);
          return shiftStartTime <= selectedEndDate && shiftEndTime >= selectedStartDate;
        })
        const formattedData = convertData(filteredData)
        setFormattedData(formattedData)
        console.log("Formated",formattedData)
      }
    }, [userList, selectedEndDate, selectedStartDate])

    useEffect(()=> {
      if (weekly) {
        const selectedDate = new Date(dateSelected.toISOString());
        selectedDate.setHours(0, 0, 0, 0);
        setSelectedStartDate(selectedDate);
  
        const selectedDate2 = new Date(selectedDate);
        selectedDate2.setDate(selectedDate2.getDate() + 6); // Set to the end of the week
        selectedDate2.setHours(23, 59, 0, 0);
        setSelectedEndDate(selectedDate2);
        
      } else {
        const selectedDate = new Date(dateSelected.toISOString());
        selectedDate.setHours(0, 0, 0, 0);
        setSelectedStartDate(selectedDate);
  
        const selectedDate2 = new Date(dateSelected.toISOString());
        selectedDate2.setHours(23, 59, 0, 0);
        setSelectedEndDate(selectedDate2);
      }
      
    },[ dateSelected, dateSelected2, weekly])

  useEffect(() => {
    const newFilteredData = formattedData.filter((user) =>
      user.shifts.some((shift) => {
        const shiftStartTime = new Date(shift.startTime);
        const shiftEndTime = new Date(shift.endTime);

        // Check if the shift overlaps with the selected date range
        return shiftStartTime <= selectedEndDate && shiftEndTime >= selectedStartDate;
      })
    );

    const updatedData = formattedData.map((user) => {
      const filteredShifts = user.shifts.filter((shift) => {
        const shiftStartTime = new Date(shift.startTime);
        const shiftEndTime = new Date(shift.endTime);
        
        // Check if the shift start or end date falls within the selected week
        return (
          (shiftStartTime >= selectedStartDate && shiftStartTime <= selectedEndDate) ||
          (shiftEndTime >= selectedStartDate && shiftEndTime <= selectedEndDate)
        );
      });
    
      return { ...user, shifts: filteredShifts };
    });

    if(!weekly) {
      setDataForGraph(newFilteredData);
    } else {
      setDataForGraph(updatedData);
    }
    // setMdata(newFilteredData);
    console.log("Filtered Data:", newFilteredData);
    console.log("Date Selected: ", selectedStartDate.toISOString())

  }, [selectedEndDate, selectedStartDate, weekly, formattedData]);

  useEffect(() => {
    const newFilteredData = formattedData.filter((user) =>
      user.shifts.some((shift) => {
        const shiftStartTime = new Date(shift.startTime);
        const shiftEndTime = new Date(shift.endTime);

        // Check if the shift overlaps with the selected date range
        return shiftStartTime <= selectedEndDate && shiftEndTime >= selectedStartDate;
      })
    );
    setDataForGraph(newFilteredData);
  }, [])

  useEffect(() => {
    props.setDateSelectedTwo(selectedEndDate)
  }, [selectedEndDate])

  // useEffect(() => {
  //   const usersLength = data.length 
  //   const addedLength =  ((usersLength - 1) * 100) + dynamicHight;
  //   setDynamicHight(addedLength) 
  // }, [])

  // const recHeight = data.length * SVG_FOR_EACH + SVG_HEIGHT;

  const userColors = ["#FB8787",
    "#71BB81",
    "#5D98DE",
    "#D7C2A3",
    "#FFCF88",
    "#40323e",
    "#d9340d",
    "#254473",
    "#EEEDED",
    "pink"];

  const x0 = 150;
  const xAxisLength = SVG_WIDTH - x0;

  const y0 = 0;
  const yAxisLength = SVG_HEIGHT - y0;

  const xAxisY = y0 + yAxisLength;

  const startTime = selectedStartDate;
  const endTime = selectedEndDate;

  const isSingleDayRange = startTime.getDate() === endTime.getDate();

  let timeInterval = 4 * 60 * 60 * 1000; // 4 hours in milliseconds

  if (!isSingleDayRange) {
    timeInterval = 24 * 60 * 60 * 1000;
  }

  // Calculate the number of X intervals based on the selected date range
  const numXIntervals = (endTime - startTime) / timeInterval;
  const xTickInterval = xAxisLength / numXIntervals;

  const labelStyle = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    fill: "black", // Customize the label color here
    textAnchor: "end",
  };

  // Function to generate date labels
  const getDateLabels = () => {
    const dateLabels = [];
    for (let i = 0; i <= numXIntervals; i++) {
      const time = new Date(startTime.getTime() + i * timeInterval);
      const month = time.getMonth() + 1;
      const day = time.getDate();
      dateLabels.push(`${month}/${day}`);
    }
    return dateLabels;
  };

  const getGridLines = () => {
    const gridLines = [];

    // Vertical grid lines for hours/days
    for (let i = 0; i <= numXIntervals; i++) {
      const x = x0 + i * xTickInterval;
      gridLines.push(
        <line key={`xGridLine-${i}`} x1={x} y1={y0} x2={x} y2={y0 + yAxisLength} stroke="lightgray" />
      );
    }



    // Horizontal grid lines for user rows
    // data.forEach((_, userIndex) => {
    //   const y = y0 + (userIndex + 1) * (yAxisLength / (data.length + 1));
    //   gridLines.push(
    //     <line key={`yGridLine-${userIndex}`} x1={x0} y1={y} x2={x0 + xAxisLength} y2={y} stroke="lightgray" />
    //   );
    // });

    return gridLines;
  };

  return (
    <div className="chart-container" style={{ overflowY: "auto", maxHeight: "400px" }}>
      <div className="pb-1 pt-1">
        <Row>
          <Col className="col-md-10">
            <div className="list_of_officers_manage_div_main_page">
              <div>
                <span className="list_of_officers_text_main_page">
                  {weekly ? (
                  <>
                    <div className="vehicle_action_from_to_div" style={{ margin: 0, padding: 0 }}>
                      <div className="from_vehicle_div">
                        <span>From: </span>
                        <div className="action_input_div">
                          <input className="action_input" value={format(selectedStartDate, 'MM-dd-yyyy')} />

                          <DatePicker
                            value={format(selectedStartDate, 'MM-dd-yyyy')}
                            // selected={startDate}
                            onChange={(date) => {
                              setSelectedStartDate(new Date(date))
                            }}
                            customInput={
                              <div className="action_search_btn">
                                <img
                                
                                  src={calenderImage}
                                />
                              </div>
                            }
                          />
                        </div>
                      </div>
                      <div className="to_vehicle_div">
                        <span>To: </span>
                        <div className="action_input_div">
                          <input value={format(selectedEndDate, 'MM-dd-yyyy')} type="text" className="action_input" />
                          <DatePicker
                            value={format(selectedEndDate, 'MM-dd-yyyy')}
                            // selected={dateTo}
                            onChange={(date) => {
                              setSelectedEndDate(new Date(date))
                            
                            }}
                            customInput={
                              <div className="action_search_btn">
                                <img
                                
                                  src={calenderImage}
                                />
                              </div>
                            }
                          />

                        </div>
                      </div>
                    </div>
                   </> 
                    ): <>Daily Schedule for <span style={{ color: "#1D7DEF" }}>{format(selectedStartDate, 'MM-dd-yyyy')}</span></>}
                </span>
              </div>
            </div>
          </Col>
          <Col className="col-md-2">
            {weekly ? 
            <Button
              variant="primary"
              onClick={() => {
                props.changeToDaily()
                setWeekly(false)
              }}
              style={{ width: "100%" }}
            >
               &nbsp;Daily&nbsp;
            </Button> : 
            <Button
              variant="primary"
              onClick={() => {
                setWeekly(true)
                props.changeToCustom()

              }}
            >
              Custom
            </Button>}

          </Col>
        </Row>

        <div>

        </div>
      </div>
      <svg width={SVG_WIDTH} height={dynamicHight}>
        {/* X axis */}
        <rect x="0" y="0" width={SVG_WIDTH} height={SVG_HEIGHT} fill="#EAEAEA" style={{ padding: "10px" }} />

        <line x1={x0} y1={xAxisY} x2={x0 + xAxisLength} y2={xAxisY} stroke="grey" />
        {isSingleDayRange ? (
          Array.from({ length: numXIntervals + 1 }).map((_, index) => {
            const x = x0 + index * xTickInterval;
            const time = new Date(startTime.getTime() + index * timeInterval);
            const hours = time.getHours().toString().padStart(2, "0");
            const minutes = time.getMinutes().toString().padStart(2, "0");
            return (
              <g key={index}>

                <line x1={x} y1={xAxisY} x2={x} y2={xAxisY + 5} stroke="grey" />
                <text x={x} y={xAxisY + 20} textAnchor="middle">
                  {`${hours}:${minutes}`}
                </text>
              </g>
            );
          })
        ) : (
          getDateLabels().map((label, index) => {
            const x = x0 + index * xTickInterval;
            return (
              <g key={index}>
                <line x1={x} y1={xAxisY} x2={x} y2={xAxisY + 5} stroke="grey" />
                <text x={x} y={xAxisY + 20} style={labelStyle}>
                  {label}
                </text>
              </g>
            );
          })
        )}

        {/* Y axis */}
        <line x1={x0} y1={y0} x2={x0} y2={y0 + yAxisLength} stroke="grey" />
        {dataForGraph.map((user, userIndex) => {
          const userColor = userColors[userIndex % userColors.length];
          const y = y0 + (userIndex + 1) * (yAxisLength / (dataForGraph.length + 1));

          const maxTextLength = 120; // Adjust this value as needed for your layout
          const nameLength = user.user.name.length;
          const dynamicTextLength = Math.min(maxTextLength, nameLength * 7); // Adjust the multiplier as needed

          return (
            <g key={user.user.id}>
              <rect x={x0 - 135} y={y - 15} width={135} height={32} fill="white" rx="5" ry="5" />
              <line x1={x0} y1={y} x2={x0 - 5} y2={y} stroke="grey" />
              <text x={x0 - 10 - 10} y={y} textLength={dynamicTextLength} style={labelStyle} textAnchor="end" lengthAdjust="spacing">
                <tspan x={x0 - 20} dy=".5em">
                  {user.user.name}
                </tspan>
              </text>
              {user && user.shifts.map((shift, shiftIndex) => {
                const shiftStartTime = new Date(shift.startTime);
                const shiftEndTime = new Date(shift.endTime);
                // const shiftX1 =
                //   x0 + (shiftStartTime - startTime) / (endTime - startTime) * xAxisLength;
                // const shiftX2 =
                //   x0 + (shiftEndTime - startTime) / (endTime - startTime) * xAxisLength;
                const shiftX1 =
                  x0 + Math.max(0, (shiftStartTime - startTime) / (endTime - startTime)) * xAxisLength;
                const shiftX2 =
                  x0 + Math.min(1, (shiftEndTime - startTime) / (endTime - startTime)) * xAxisLength;

                return (
                  <rect
                    key={shiftIndex}
                    x={shiftX1}
                    y={y - 14}
                    width={shiftX2 - shiftX1}
                    height={30}
                    fill={userColor}
                  />
                );
              })}
            </g>
          );
        })}
        {getGridLines()}
      </svg>
    </div>
  );
}

export default ShiftGanttChart;
