import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import BackButton from "../../parts/BackButton/BackButton";
import Sidebar from "../Sidebar/Sidebar";
import Frontbox from "./Frontbox/Frontbox";
import getManagerInfo from "../../config/ManagerInfo";
import getManagerToken from "../../config/ManagerToken";
import getApi from "../../config/ApiGet";
import startConversation from "./API/API";

const MessageMain = ({ ...props }) => {
  const closeFunction = () => {
    props.history.goBack();
  };

  const [residents, setResidents] = useState()
  const [conversation, setConversation] = useState()
  const [conversationDetails, setConversationDetails] = useState([])
  const [newMessageClick, setNewMessageClick] = useState(false)
  const [selectedResident, setSelectedResident] = useState()
  
  const managerInfo = getManagerInfo()

  useEffect(async() => {
      const managerToken = getManagerToken()
      const result = await getApi("https://kepah-24275.botics.co/api/v1/admin/residents/");
      setResidents(result);
      const convo = await getApi("https://kepah-24275.botics.co/api/v1/conversations/");
      
      // Assuming convo is an array of conversation objects with a last_message property

      // Sort the convo array based on last_message.timestamp
      const sortedConvo = convo.slice().sort((a, b) => {
        const timestampA = new Date(a.last_message.timestamp)
        const timestampB = new Date(b.last_message.timestamp);
        
        return timestampB - timestampA; // Sort in descending order, change - to + for ascending order
      });

      // Update the conversation state with the sorted array
      setConversation(sortedConvo);

  }, [])

  // useEffect(() => {
  //     const managerToken = getManagerToken()
  //     console.log("Conversation:", conversation);
  
  //     if(conversation) {
  //       const fetchConversationDetails = async () => {
  //         const conversationDetails = await Promise.all(
              
  //           conversation.map(async (convo) => {
  //                 const det = await getApi(`https://kepah-24275.botics.co/api/v1/conversations/${convo?.id}/messages/`, managerToken);
  //                 console.log("Det Results:", det?.results);
  //                 return {
  //                     read: det.results.read,
  //                     initiator: convo.initiator,
  //                     receiver: convo.receiver,
  //                     lastMessage: det?.results[0],
  //                     id: convo?.id,
  //                 };
  //             })
  //         );
  //         setConversationDetails(conversationDetails);
  //     };
  //     fetchConversationDetails();
  //     }
      
  // }, [conversation]);
  
  useEffect(() => {
      console.log("Conversation Details", conversationDetails)
  }, [conversationDetails])

  const handleDropdownChange = async (e) => {
    const id = e.target.value
    const value = await startConversation(id)
    props.history.push(`/message/details/${value}`)
  };

  const handleBoxClick = (id) => {
    console.log("Working")
    props.history.push(`/message/details/${id}`)
  }
  
  return (
    <div className={props.popup ? "incident_back_popup" : "incident_back"}>
      <div className="side_nav">
        <Sidebar history={props.history} nav="Message" />
      </div>

      <div className="rent_main_div">
        <div className="add_new_resident_back">
          <BackButton closeFunction={closeFunction} />
          <div className="bgMessage">
            <div className="add_new_resident_name_img_div">
              <h3 className="add_new_resident_text">Message Resident/Security</h3>
              {newMessageClick? (
                <div className="phone_input_div" style={{borderRadius: "5px", width: "92%"}}>
                  <div className="phone_input_field_div pb-2">
                    <select
                      className="phone_input_field"
                      onChange={handleDropdownChange}
                      defaultValue={selectedResident}
                    >
                      <option>Select A Resident</option>
                      {residents && residents.map((usr) => (
                        <option value={usr.id} > {usr.name}  ({usr.email}) </option>
                      ))}
                    </select>
                  </div>
                </div>
              ): (
                <label htmlFor="upload-csv" className="upload_csv_text pb-2" onClick={() => setNewMessageClick(true)}>
                  Start New Conversation
                </label>
              )}
            </div>

            <div className="pt-2" style={{ width: "100%", maxHeight: "50vh", minHeight: "50vh" }}>
              {conversation && conversation.map(convo => {
                console.log("convo Details", convo)
                return(
                  <div className="pb-2" onClick = {(() => {
                    handleBoxClick(convo?.id)
                  })}>
                    <Frontbox 
                      message={convo?.last_message?.text} 
                      date={convo?.last_message?.timestamp}
                      userName={convo?.initiator?.id === managerInfo?.id ? convo?.receiver?.name : convo?.initiator?.name} 
                      readStatus={convo?.last_message?.sender?.id === managerInfo?.id ? true : convo.last_message.read}
                      // sentByManager={convo?.last_message?.sender?.id === managerInfo?.id ? false : true}
                      timeStamp={convo?.last_message?.timestamp}
                    />
                  </div>
                )
              })}
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageMain;
