import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";

import _debounce from "lodash/debounce";
import BackButton from "../../parts/BackButton/BackButton";
import Sidebar from "../Sidebar/Sidebar";
import Messagebox from "./Messagebox/Messagebox";
import Messagebox2 from "./Messagebox/Messagebox2";
import getManagerToken from "../../config/ManagerToken";
import startConversation from "./API/API";
import WebSocketManager from "./API/WebSocketManager";
import getManagerInfo from "../../config/ManagerInfo";
import getApi from "../../config/ApiGet";
const SOCKET_URL = "wss://kepah-24275.botics.co";
const MAX_RECONNECT_ATTEMPTS = 1
const RECONNECT_DELAY = 50
const FETCH_MESSAGES_DELAY = 50

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MessageDetails = ({ ...props }) => {
    
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const managerToken = getManagerToken();
    const [chatSocket, setChatSocket] = useState(null);
    const [managerInfo, setManagerInfo] = useState(null);
    const messageContainerRef = useRef(null);
    const [residentInfo, setResidentInfo] = useState(null)
    const [hasMore, setHasMore] = useState(false)
    const [conversationId, setConversationId] = useState()
    const pathArray = location.pathname.split('/'); // Split the URL by '/'
    const id = pathArray[pathArray.length - 1]; // Get the last segment of the URL
    const [newMessageCount, setNewMessageCount] = useState(0)
    

    const [ws, setWs] = useState(React.useRef(new WebSocket(`wss://kepah-24275.botics.co/messenger/chat/${id}/?token=${managerToken}`)).current);
    
    const reconnectAttempts = useRef(0);

    const notifyError = () => toast.error('Please Refresh the screen and try again!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    useEffect(() => {
        const managerInfo = getManagerInfo()
        setManagerInfo(managerInfo)
    }, [])
    
    const markConversationAsRead = () => {
        const message = {
            "type": "read_messages"
        };
        ws.send(JSON.stringify(message));
    };

    useEffect(async () => {
        if(hasMore) {
            try {
                const response = await getApi(`https://kepah-24275.botics.co/api/v1/conversations/${id}/messages/`);
                const newMessages = response.results.reverse();
                newMessages.splice(-(50+newMessageCount));
                setMessages(prevMessages => [...newMessages, ...prevMessages])
            } catch (error) {
                console.error("Error fetching messages:", error);
            }
        }
    }, [hasMore]);

    useEffect(() => {
     
        ws.onopen = () => {
          console.log('Connected to the server')
        
        };

        ws.onclose = (e) => {
          console.log('Disconnected. Check internet or server.', e)
          if (reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
            setTimeout(() => {
                console.log('Attempting to reconnect...');
                setWs(new WebSocket(`wss://kepah-24275.botics.co/messenger/chat/${id}/?token=${managerToken}`));
                reconnectAttempts.current += 1;
            }, RECONNECT_DELAY);
            } else {
                console.log('Max reconnect attempts reached.');
            }
        };
        ws.onerror = (e) => {
          console.log("Error Object",e);
        };
        ws.onmessage = (event) => {
        markConversationAsRead()
        //   console.log("Event.data", event.data)
          const receivedMessage = JSON.parse(event.data);
          console.log("Received message:", receivedMessage);
          if (receivedMessage && receivedMessage.type === "message_history") {
            setHasMore(receivedMessage?.has_more)
            const newMessages = receivedMessage?.messages?.reverse();
            const uniqueMessages = newMessages.filter(newMessage => !messages.some(existingMessage => existingMessage.id === newMessage.id));
            if (uniqueMessages.length > 0) {
                setMessages(prevMessages => [...prevMessages, ...uniqueMessages]);
            }
          } else if (receivedMessage && receivedMessage?.type === "chat_message") {
              setNewMessageCount(newMessageCount + 1);
              // Append new messages to the messages array
              console.log("newMsg", receivedMessage)
              setMessages((prevMessages) => [...prevMessages, receivedMessage.message_data]);
          }
        };

        return () => {
          ws.close();
        };
    }, [ws]);

    useLayoutEffect(() => {
        console.log("MessageBox", messages)
        if (messageContainerRef.current) {
            // Set initial scroll position to the maximum value to start from the bottom
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const closeFunction = () => {
        props.history.goBack();
    };

    useEffect(() => {       
        console.log("Messages, Count", messages)
    }, [messages])

    const sendMessage = () => {
        if (newMessage.trim() === "") {
          return; // Do not send empty messages
        }
        console.log("Clicked", newMessage);

        console.log("New Message Structure", JSON.stringify(newMessage))
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({
                "type": "chat_message",
                'message': newMessage
            }));;
            setNewMessage("");
            // markConversationAsRead()
        } else {
            console.log("WebSocket connection is closed. Reconnecting...");
            notifyError()
            // Handle the reconnection logic here, such as calling a reconnect function.
            // For example: reconnectWebSocket();
        }
      };

    return (
        <div className={props.popup ? "incident_back_popup" : "incident_back"}>
            <div className="side_nav">
                <Sidebar history={props.history} nav="Message" />
            </div>

            <div className="rent_main_div">
                <div className="add_new_resident_back funUp">
                    <BackButton closeFunction={closeFunction} />
                    <div className="bgMessage2">
                        <div className="pt-2">
                            <h3 className="add_new_resident_text">Message Log</h3>
                            <p style={{ fontSize: "14px" }}>{residentInfo?.name}</p>
                        </div>

                        <div
                            className="messageList"
                            style={{
                                width: "100%",
                                height: "100%",
                                background: "#EAEAEA",
                                overflowY: "scroll",
                                maxHeight: "50vh", // Limit the height to 50vh and enable vertical scrollbar
                                paddingBottom: "20px", // Add space for the scrollbar
                                minHeight: "50vh"
                            }}
                            ref={messageContainerRef}
                        >
                            <div className="pt-2">
                                {messages.map((message) => (
                                    <div className="messageBox" key={message?.id}>
                                        {message?.sender?.id === managerInfo?.user.id ? (
                                            <Messagebox2 imageUrl={message?.sender?.profile_picture} timestamp={message.timestamp} message={message.text} />
                                        ) : (
                                            <Messagebox imageUrl={message?.receiver?.profile_picture} timestamp={message.timestamp} message={message.text} />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "auto",
                                padding: "2%",
                                borderRadius: "5px",
                                marginTop: "2%",
                                display: "flex",
                                flexDirection: "row",
                                background: "#EAEAEA",
                            }}
                        >
                            <div className="" style={{ width: "90%", marginTop: ".8vh" }}>
                                <div className="">
                                    <input
                                        className="phone_input_field"
                                        type="text"
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        placeholder="Enter your message"
                                    />
                                </div>
                            </div>
                            <div>
                                <Button onClick={() => sendMessage()}>SEND</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default MessageDetails;
