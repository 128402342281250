import axios from "axios";
import getManagerToken from "./ManagerToken";

export default function callApi(method, url, data, from) {
    
    console.log("callApi From:", from)

    const token = getManagerToken()
    var config = {
        method: method,
        url: url,
        headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
        },
        data: data ? data : null,
    };

    // Return the promise from the axios call
    return axios(config)
        .then((res) => {
            // Return the response data from the successful request
            console.log(`Response from API ${method}`, res)
            return res;
        })
        .catch((err) => {
            // Throw an error or handle it as needed
            console.log("Error from API Post", err.response.data);
            throw err;
        });
}
