import React, { useState } from "react";
import { useEffect } from "react";
import "./style.css";
import axios from "axios";
import { format, setDate } from 'date-fns';
import personPhoto from "../../../assets/profile.png";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import downloadIcon from "../../../assets/incident-download-icon.png"
import downloadDash from "../../../assets/incident-download-dash.png"

const ListOfOfficersSchedule = ({ ...props }) => {
  const [officers, setOfficers] = useState([]);
  const [search, setSearch] = useState("");
  const [workStatus, setWorkStatus] = useState(false);
  const [token, setToken] = useState(null);
  const [buildingno, setBuildingno] = useState(null);
  const [dateSelected, setDateSelected] = useState(new Date())

  const [userList, setUserList] = useState(null)
  const [filteredData, setFilteredData] = useState(null)

  const [formattedData, setFormattedData] = useState(null)
  const [dateSelected2, setDateSelected2] = useState(new Date())
  const [custom, setCustom] = useState(false)

  const currentDate = new Date();
  const startOfDay = new Date(currentDate);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(currentDate);
  endOfDay.setHours(23, 59, 59, 999);

  const [selectedStartDate, setSelectedStartDate] = useState(startOfDay);
  const [selectedEndDate, setSelectedEndDate] = useState(endOfDay);

  const weeklySelected = localStorage.getItem("weekly");
  const weeklySelect = JSON.parse(weeklySelected);

  const selectedSt = localStorage.getItem("selectedStartDate");
  const selectedStart = JSON.parse(selectedSt);

  const selectedNd = localStorage.getItem("selectedEndDate");
  const selectedEnd = JSON.parse(selectedNd);

  useEffect(() => {
    console.log("SelectedOpt:", weeklySelect)
    if(weeklySelect){
      setCustom(true)
    } else {
      setCustom(false)
    }
    setSelectedStartDate(new Date(selectedStart))
    setSelectedEndDate(new Date(selectedEnd))
  }, [weeklySelect, selectedStart, selectedEnd])


  useEffect(() => {
    const selectedDate = new Date(props.dateSelected.toISOString());
    selectedDate.setHours(0, 0, 0, 0);
    setDateSelected(selectedDate)
  }, [props.dateSelected])

  useEffect(() => {
    const selectedDate = new Date(props.dateSelected2.toISOString());
    selectedDate.setHours(23, 59, 0, 0);
    setDateSelected2(selectedDate)
  }, [props.dateSelected2])

  useEffect(() => {
    console.log("dateSeleX", dateSelected)
    const selectedDate = new Date(dateSelected.toISOString());
    selectedDate.setHours(0, 0, 0, 0);
    setSelectedStartDate(selectedDate);

    const selectedDate2 = new Date(dateSelected2.toISOString());
    selectedDate2.setHours(23, 59, 0, 0);
    setSelectedEndDate(selectedDate2);
  }, [dateSelected, dateSelected2])

  // Function to convert the data
  function convertData(rawData) {
    const userShiftsMap = new Map();

    // Iterate through the rawData
    rawData.forEach((item) => {
      const userId = item.user;
      const startTime = item.start_time;
      const endTime = item.end_time;

      // Create a userShifts object if it doesn't exist
      if (!userShiftsMap.has(userId)) {
        userShiftsMap.set(userId, {
          user: item.officer_details, // You can use any user information from the item
          shifts: [],
        });
      }

      // Add the shift to the userShifts object
      userShiftsMap.get(userId).shifts.push({
        startTime,
        endTime,
      });
    });

    // Convert the Map to an array of objects
    const result = Array.from(userShiftsMap.values());

    return result;
  }
  useEffect(() => {
    if(userList !== null) {
      console.log("List", userList)
      const filteredData = userList.filter((user) => {
        const shiftStartTime = new Date(user.start_time);
        const shiftEndTime = new Date(user.start_time);
        return shiftStartTime <= selectedEndDate && shiftEndTime >= selectedStartDate;
      })
      setFilteredData(filteredData)
      console.log(filteredData)
      const formattedData = convertData(filteredData)
      setFormattedData(formattedData)
      console.log("Formatted Data", formattedData)
    }

  }, [userList, selectedEndDate, selectedStartDate])

  const managerToken = localStorage.getItem("token")

  useEffect(() => {
    var config = {
      method: "get",
      url: `https://kepah-24275.botics.co/api/v1/admin/officer-schedule/`,
      headers: {
        Authorization: `token ${managerToken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config).then((res) => {
      setUserList(res.data)
    });
  }, []);

  const getSecurityOfficersList = (buildingno, _token) => {
    var config = {
      method: "get",
      url: `https://kepah-24275.botics.co/api/v1/security-officer/?residence_building=${buildingno}`,
      headers: {
        Authorization: `token ${_token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function(response) {
        console.log(response, "reeessponse");
        let officerList = response.data;
        if (officerList && officerList.length > 0) {
          // console.log(response, "reeessponse");

          officerList.reverse();
          setOfficers(officerList);
        }
      })
      .catch(function(error) {
        console.log(error, "It's a error!!!");
      });
  };

  var configDownload = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/officer-schedule/download-officers/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  const downloadClick = (e) => {
    e.preventDefault();
    axios(configDownload)
      .then((res) => {
        // Check if the response contains data
        if (res.data) {
          // Prepare the file data and create a Blob object
          const blob = new Blob([res.data], { type: "application/octet-stream" });
  
          // Create a temporary URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
  
          // Create a link element
          const link = document.createElement("a");
          link.href = blobUrl;
  
          // Set the download attribute and suggested file name
          link.download = "officers_schedule.csv"; // Replace with the desired file name
  
          // Simulate a click on the link to trigger the download
          link.click();
  
          // Clean up the created URL
          URL.revokeObjectURL(blobUrl);
        }
      })
      .catch((error) => {
        console.error("Error downloading:", error);
      });
  };
  
  return (
    <div className="list_of_officer_back">
      <div className="list_of_officer_text_input">
        <div>
          <span className="list_of_officers_text_main_page">Officers ({formattedData && formattedData.length}) for </span>
          <span className="list_of_officers_text_main_page" style={{ color: "#1D7DEF" }}>{format(selectedStartDate, 'MM-dd-yyyy')}</span>
          {custom ? <span className="list_of_officers_text_main_page" style={{ color: "#1D7DEF" }}> to {format(selectedEndDate, 'MM-dd-yyyy')}</span>: ""}
        </div>
        <div className="download_create_btns">
          <Form className="download_btn_form">
            <Button
              className="download_btn_incident"
              variant="primary"
              type="submit"
            >
              <div className="download_icon_div">
                <img
                  src={downloadIcon}
                />
                <img
                  src={downloadDash}
                />
              </div>
              <span onClick={e => downloadClick(e)} className="download_text">DOWNLOAD</span>
            </Button>
          </Form>
          <Form className="download_btn_form">
            <Button
              className="download_btn_incident"
              variant="primary"
              type="submit"
            >
              {/* <div className="download_icon_div">
                <img
                  src={downloadIcon}
                />
                <img
                  src={downloadDash}
                />
              </div> */}
              <span onClick={e => {
                e.preventDefault()
                props.history.push("officersmanagement/add")
              }} className="download_text">Add Schedule</span>
            </Button>
          </Form>
        </div>
   
      </div>
      <div className="">
        <div className="officer_license_number_main_page_schedule">
          <span className="officerScheduleName">Officer</span>
          <span className="officerScheduleArmed">Armed</span>
          <span className="officerSchedulePhone">Phone Number</span>
          <span className="officerScheduleEmail">Email</span>
          <span className="officerScheduleDate">Date</span>
          <span className="officerScheduleStartTime">Start</span>
          <span className="officerScheduleEndTime">End</span>
        </div>
        <div className="bottom_border_main_page" />

        <div className="scrollY">
          {formattedData && formattedData.map((val, ind) => {
            return (
              <div
                key={ind}
                className="officer_license_number_white_back_main_page_schedule"
                data-toggle="modal"
                data-target="#exampleModal"
                style={{ backgroundColor: ind % 2 === 0 ? "#ebf4fe" : "white" }}
              >
                <div style={{ cursor: "pointer" }} className="officerScheduleName" onClick={() => {
                  localStorage.setItem("officerId", JSON.stringify(val.user.id));
                  props.history.push("officersmanagement/add")
                }} >
                  {val.user && val.user.profile_picture !== null ? 
                  <img
                    src={val.user.profile_picture}
                    className="profile_img_main_page_schedule"
                  /> : 
                  <img
                    src={personPhoto}
                    className="profile_img_main_page_schedule"
                  />
                }
                  
                  <span className="" style={{ paddingLeft: "2%", color: "#257CFF" }}>
                    {val.user.name}
                  </span>
                </div>

                <span className="officerScheduleArmed">
                  {val.user.officer_armed_status === true ? "Yes" : "No"}
                </span>
                <span className="officerSchedulePhone">{val.user.phone_number}</span>

                <span className="officerScheduleEmail">{val.user.email}</span>
                <span className="officerScheduleDate">{format(new Date(val.shifts[0].startTime), 'MM-dd-yyyy')}</span>
                <span className="officerScheduleStartTime">{format(new Date(val.shifts[0].startTime), 'HH:mm')}</span>
                <span className="officerScheduleEndTime">{format(new Date(val.shifts[0].endTime), 'HH:mm')}</span>


                {/* <span
                  className="make_a_schedule"
                  onClick={() => props.makeSchedule(val.id)}
                >
                  Make a Schedule
                </span> */}
                {/* </div> */}
              </div>
            );
          })}
          
        </div>
      </div>
    </div>
  );
};

export default ListOfOfficersSchedule;
