import React, { useState, useEffect } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import EditSvg from "../../../assets/edit.svg"
import searchImage from "../../../assets/Search.png"
import personImage from "../../../assets/profile.png"
import { format, parseISO } from 'date-fns';


const ListOfTenants = ({ ...props }) => {
  const [searchOnAptVar, setSearchOnAptVar] = useState("");
  const [searchOnNameVar, setSearchOnNameVar] = useState("");
  const [userList, setUserList] = useState([]);
  const [updatedUserList, setUpdatedUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userListCheck, setUserListCheck] = useState(false);
  const [userListChanged, setUserListChanged] = useState(false); 
  const [searchOnApt, setSearchOnApt] = useState(false)
  const [searchOnName, setSearchOnName] = useState(false)
  const [userData, setUserData] = useState([])

  const [showResident, setShowResident] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [position, setSelectedUserPosition] = useState(null);

  const managerToken = localStorage.getItem("token")

  var config = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/tenants/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if(searchOnNameVar !=="" ) {
      setSearchOnName(true)
      setSearchOnApt(false)
    } else if(searchOnAptVar !== "" )  {
      setSearchOnApt(true)
      setSearchOnName(false)
    } else {
      setSearchOnApt(false)
      setSearchOnName(false)
    }
  }, [searchOnAptVar, searchOnNameVar])

  // useEffect(() => {
  //   axios(config).then((res) => {
  //     console.log(res.data)
  //     setUserList(res.data);
  //     setUpdatedUserList(res.data)
  //     setUserListCheck(true);
  //   })
  //   .then((res) => {
  //     userList.map(user => {
  //       var config2 = {
  //         method: "get",
  //         url: `https://kepah-24275.botics.co/api/v1/admin/tenants/${user.id}/vehicle/`,
  //         headers: {
  //           Authorization: `token ${managerToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       };
  //       axios(config2).then
  //     })
  //   }) 
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data
        const res = await axios(config);
        const userListData = res.data;
        console.log(res.data)
  
        // Fetch vehicle info for each user
        const updatedUserListData = await Promise.all(
          userListData.map(async (user) => {
            const config2 = {
              method: "get",
              url: `https://kepah-24275.botics.co/api/v1/admin/tenants/${user.id}/vehicle/`,
              headers: {
                Authorization: `token ${managerToken}`,
                "Content-Type": "application/json",
              },
            };
  
            const vehicleRes = await axios(config2);
            const vehicleData = vehicleRes.data;
            const vehicleNumber = vehicleData?.[0]?.vehicle_number || "";
            const updatedUser = { ...user, vehicle: vehicleNumber };
            return updatedUser;
            // Add vehicle info to the user object
          })
        );
        // Update the state with the updated user list containing vehicle info
        setUserList(updatedUserListData)
        setUpdatedUserList(updatedUserListData);
        setUserData(updatedUserListData);
        console.log(userData)
        setUserListCheck(true);
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  
  // const formatDate = (isoDate) => {
  //   const date = new Date(isoDate);
  //   return date.toLocaleString(); // Format as per the user's locale
  //   // Alternatively, you can use specific formatting options, e.g., date.toLocaleString('en-US')
  // };

  const formatDate = (date) => {
    const joinedDateISO = date; // "2023-06-25T21:20:45.302375Z"
    const parsedJoinedDate = parseISO(joinedDateISO);

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedJoinedDate = format(parsedJoinedDate, 'yyyy-MM-dd  h:mm a');
    return formattedJoinedDate
  }

  useEffect(() => {
    if (!searchOnName && !searchOnApt) {
      setUpdatedUserList(userData)
      setUserList(userData)
      setUserListCheck(true);
    } else {
      let filteredResults = []
      if (searchOnName) {
        filteredResults = userList.filter((user) => {
          console.log(user)
          if (user && user.name) {

            const nameMatch = user.name.toLowerCase().includes(searchOnNameVar.toLowerCase());
            console.log(nameMatch)
            console.log(searchOnNameVar)
            return nameMatch;
          }
          return false;
        });
      }
      if (searchOnApt) {
        filteredResults = userList.filter((user) => {
          if (user && user.apartment_number !== null) {
            const aptMatch = user.apartment_number.toString().includes(searchOnAptVar);
            return aptMatch;
          }
          return false;
        });
      }
      console.log(filteredResults)
      setFilteredUsers(filteredResults);
      setUpdatedUserList(filteredResults);
    }
  }, [userData, userList, searchOnName, searchOnApt]);
  
  useEffect(() => {
    if(!searchOnName && !searchOnApt) {
      setUpdatedUserList(userData)
      setUserList(userData)
      setUserListCheck(true);
    } else {
      axios(config).then((res) => {
        setUserList(res.data);
        setUserListCheck(true);
      });
      setUpdatedUserList(filteredUsers)
    }
  }, [userData, userList, searchOnName, searchOnApt]);

  return (
    <div>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">List of Occupants ({userList.length})</span>
        </div>
      </div>

      <div className="criminal_input_div">
        <input
          type="text"
          className="rent_input"
          placeholder="Full Name"
          onChange={(e) => {
            setSearchOnNameVar(e.target.value)
            setSearchOnName(true)
          }}
        />
        <input
          type="text"
          className="rent_input"
          placeholder="Apartment Number"
          onChange={(e) => {
            setSearchOnAptVar(e.target.value)
            setSearchOnApt(true)
          }}
        />
        <div className="criminal_search_btn">
          <img src={searchImage} />
        </div>
        <Form className="form_inline_criminal">
          <Button
            className="add_new_resident_btn"
            variant="primary"
            type="submit"
            onClick={() => props.history.push("listoftenants/add")}
          >
            ADD NEW OCCUPANT
          </Button>
        </Form>
      </div>

      <div 
        className="pt-4">
        <div className="list_of_tenants_div">
            <div>
              <Container>
                <div className="infoRow">
                  <div className="col_t_name">
                    Occupant
                  </div>
                  <div className="col_t_apt">
                    Apt. Number
                  </div>
                  <div className="col_t_date_added">
                    Date Added
                  </div>
                  <div className="col_t_email">
                    Email
                  </div>
                  <div className="col_t_vehicle">
                    Vehicle Info
                  </div>
                  <div className="col_t_edit">

                  </div>
                </div>
                <div className="bottom_border_manager mt-1 mb-1" />
                {/* <Row>
                  <div className="bottom_border_manager" />
                </Row> */}
                { userListCheck ? updatedUserList.map(user => (
                  <div className="infoRow p-1 mt-2 row_alternate" key={user.id}>
                    <div className="col_t_name">
                      <Row>
                        <Col className="col-md-1">
                          {user.profile_picture ? (
                            <img
                              style={{ height: "32px", width: "32px", borderRadius: "64px" }}
                              src={user.profile_picture}
                              alt="Profile Photo"
                            />
                          ) : (
                            <img
                              style={{ height: "32px", width: "32px", borderRadius: "64px" }}
                              src={personImage}
                              alt="Default Profile Photo"
                            />
                          )}
                        </Col>
                        <Col style={{ marginLeft: "23px", color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                          {user.name}
                        </Col>
                      </Row>
                    </div>
                    <div className="col_t_apt" style={{ color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                      {user.apartment_number}
                    </div>
                    <div className="col_t_date_added" style={{ fontSize: "14px", paddingTop: "6px"}} >
                      {formatDate(user.date_added)}
                    </div>
                    <div className="col_t_email" style={{ fontSize: "14px", paddingTop: "6px"}}>
                      {user.email}
                    </div>
                    <div className="col_t_vehicle" style={{ fontSize: "14px", paddingTop: "6px"}}>
                      {user.vehicle}
                    </div>
                    <div className="col_t_edit" style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}} onClick={() => {
                      localStorage.setItem("tenantInfoChange", JSON.stringify(user));
                      props.history.push("listoftenants/edit");
                    }}>
                      <img src={EditSvg} style={{ height: "16px", width: "16px" }}/>
                    </div>
                  </div>
                  
                )): <></> }
                {selectedResident && (
                  <div>
                   
                  </div>
                )}
              </Container>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfTenants;
