import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { ToastContainer, toast } from 'react-toastify';
import dateImage from "../../../assets/manager-calendar.png";
import { format } from 'date-fns';
import upload from "../../../assets/upload.svg"
import { notifySuccess, notifyError, notifyErrorRequired, notifyLoading } from "../../../config/Notifications";
import callApi from "../../../config/callApi";

const EditResident = ({ ...props }) => {
  const [insurancePolicy, setInsurancePolicy] = useState(null);
  const [insurancePolicySelected, setInsurancePolicySelected] = useState(false);
  const [postData, setPostData] = useState();

  useEffect(() => {
    const formData = new FormData();
    if(insurancePolicy) {
      formData.append("certificate", insurancePolicy);     
    }
    setPostData(formData)
  }, [insurancePolicy])

  const handlePostData = async (e) => {
    e.preventDefault();
    notifyLoading({ toastMessage: "Loading", autoClose: 5000 });
    const response = await callApi("post", "https://kepah-24275.botics.co/api/v1/admin/insurance-certificate/?residence_building=1", postData, "Security Insurance Certificates Put");
    if (response.status === 200 || response.status === 201) {
      notifySuccess({ toastMessage: "Security Insurance Certificates updated successfully", autoClose: 5000 });
      setTimeout(() => {
        props.history.push("/dashboard")
      }, 5000);
    } else { 
      notifyError({ toastMessage: "Error updating Security Insurance Certificates", autoClose: 5000 });
    } 
  };

  return (
    <div className="edit_manager_back">
      <div className="insurance_policy_background_color">
        <div className="">
          <h6>Security Insurance Certificates</h6>
        </div>

        <div className="input_fields_insurance_div">
          <div className="insurance_policy_field">
            <label className="phone_label">Upload date:</label>
            <div className="phone_input_div">
              <div className="phone_input_field_div">
                <span>{format(Date.now(), 'yyyy-MM-dd')}</span>
                <img
                  src={dateImage}
                />
              </div>
            </div>
          </div>

          <div className="insurance_policy_field">
            <label className="upload_pdf_label">Upload PDF file below:</label>
            <div className="upload_pdf_div">
              <div className="upload_pdf_field_div">
              <label htmlFor="upload-photo">
                  <input
                    type="file"
                    id="upload-photo"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      const selectedFile = event.target.files[0];
                      setInsurancePolicy(selectedFile)
                      setInsurancePolicySelected(true)
                      notifySuccess({ toastMessage: "Insurance file uploaded successfully", autoClose: 5000 });
                    }}
                    multiple
                  />
                  {!insurancePolicySelected ? (
                    <>
                      <img className="uploadLogo" src={upload} alt="Upload Icon" />
                    </>
                  ) : (
                    <>
                      <p className="pt-2" style={{ color: "blue", fontWeight: "700" }}>File Selected {insurancePolicy && insurancePolicy?.name}</p>
                    </>
                  )}
                </label>
              </div>
            </div>
          </div>

          <Form className="insurance_policy_profile_save_button_div">
            <Button
              disabled={!insurancePolicySelected}
              className="insurance-policy-btn"
              variant="primary"
              onClick={(e) => handlePostData(e)}
            >
              Save
            </Button>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditResident;
