import ShiftGanttChart from "../../VehicleAction/HorizontalBarChart/HorizontalBarChartCopy";

export default function DailySchedule2 ({ ...props }) {
    return(
        <div style={{ background: "white", padding: "2%", height: "100%" }}>
        <ShiftGanttChart 
          changeToCustom={props.changeToCustom}
          changeToDaily={props.changeToDaily}
          dateSelected={props.dateSelected}  
          setDateSelectedTwo={props.setDateSelectedTwo}
          />
      </div>
    )
}