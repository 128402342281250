import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import EditSvg from "../../../assets/edit.svg"
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";
import BackButton from "../../../parts/BackButton/BackButton";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import profileImage from "../../../assets/profile.png";

import Sidebar from "../../Sidebar/Sidebar";

const EditMaintenance = ({ ...props }) => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [apt, setApt] = useState("");
  const [updateUser, setUpdateUser] = useState(null);
  const [tenantNo, setTenantNo] = useState("");
  const [userEdit, setUserEdit] = useState(null);

  const [errorFlag, setErrorFlag] = useState(false)
  const [errorMail, setErrorMail] = useState("")
  const [errorApt, setErrorApt] = useState("")
  const [errorPhone, setErrorPhone] = useState("")
  const [errorName, setErrorName] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [errorTenant, setErrorTenant] = useState("")
  const [buttonClickedX, setButtonClickedX] = useState(false);

  const closeFunction = () => {
    props.history.goBack();
  }


  const notify = () => toast.success('Successfully Added!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notifyError = () => toast.error('Error Happened!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyLoading = () => toast.info('Loading', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });



  const validateFe = () => {
    if (email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErrorMail("Invalid Email");
      setErrorFlag(true);
    }
  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  useEffect(() => {
    if (buttonClickedX) {
      if (errorFlag != true) {
        handleResidentInfoClick()
      }
    }
  }, [errorFlag, buttonClickedX])

  useEffect(() => {
    setErrorFlag(false)
    setButtonClickedX(false)

    const updateUser = {
      name: name,
      email: email,
      phone: phone
    }
    setUserEdit(updateUser)
  }, [name, email, phone]);

  useEffect(() => {
    setUpdateUser(userEdit)
  }, [userEdit]);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return phoneNumber;

    let formattedNumber = "";

    if (match[1]) {
      formattedNumber += `(${match[1]})`;
    }

    if (match[2]) {
      formattedNumber += ` ${match[2]}`;
    }

    if (match[3]) {
      formattedNumber += `-${match[3]}`;
    }

    return formattedNumber;
  };


  useEffect(() => {
    const managerInfoChangeString = localStorage.getItem("maintenanceInfoChange");
    const managerInfoChange = JSON.parse(managerInfoChangeString);

    if (managerInfoChange) {
      setId(managerInfoChange.id)
      setName(managerInfoChange.name)
      setEmail(managerInfoChange.email)
      setPhone(managerInfoChange.phone)
    }
  }, []);

  const managerToken = localStorage.getItem("token")

  var config = {
    method: "patch",
    url: `https://kepah-24275.botics.co/api/v1/admin/maintenance/persons/${id}/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
    data: updateUser
  };

  const handleResidentInfoClick = (event) => {
    notifyLoading()

    console.log(updateUser);
    axios(config)
      .then(res => {
        if (res.status == 200) {
          notify()
          setTimeout(() => {
            props.history.goBack();
          }, 5000);
        }
      })
      .catch((err) => {
        notifyError()
      })
  };

  return (
    <div
      className={props.popup ? "incident_back_popup" : "incident_back"}
    >
      <div className="side_nav">
        <Sidebar history={props.history} nav="Maintenance Request" />
      </div>

      <div className="rent_main_div">
        <div className="edit_manager_back">

          <div className="edit_manager_background_color">
            <BackButton closeFunction={closeFunction} />
            <div className="edit_manager_name_img_div">
              <h3 className="edit_manager_text">Edit Maintenance Person</h3>
              <img
                className="manager_profile_img"
                src={profileImage}
              />
              <h4 className="manager_name_text">{name}</h4>
            </div>
            <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {errorMessage ? <p style={{ fontSize: "13px", paddingLeft: "5px", color: "red" }}>{errorMessage}</p> : ""}
            </div>
            <div className="input_fields_div">

              <div>
                <label className="phone_label">Name:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="text" value={name} onChange={event => setName(event.target.value)} />
                    <img src={EditSvg} style={{ height: "16px", width: "16px" }} />
                  </div>
                </div>
              </div>

              <div>
                <label className="phone_label">Email:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="text" value={email} onChange={(event) => setEmail(event.target.value)} />
                    <img src={EditSvg} style={{ height: "16px", width: "16px" }} />
                  </div>
                </div>
              </div>
              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorMail ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorMail}</p> : ""}
              </div>

              <div>
                <label className="phone_label">Phone:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="phone" value={phone} onChange={(e) => setPhone(formatPhoneNumber(e.target.value))} />
                    <img src={EditSvg} style={{ height: "16px", width: "16px" }} />
                  </div>
                </div>
              </div>
              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorPhone ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorPhone}</p> : ""}
              </div>

              <Form className="edit_resident_profile_save_button_div">
                <Button
                  className="edit_resident_profile_save_button"
                  variant="primary"
                  onClick={buttonClicked}
                >
                  Save
                </Button>
              </Form>
            </div>
          </div>
          <ToastContainer />

        </div>
      </div>
    </div>

  );
};

export default EditMaintenance;
