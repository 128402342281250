import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddVehicle = ({ ...props }) => {
  const [name, setName] = useState("");
  const [user, setUser] = useState([]);
  const [ten, setTen] = useState("");

  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [updateUser, setUpdateUser] = useState(null);

  const [showDropdown, setShowDropdown] = useState(true);


  const [errorFlag, setErrorFlag] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [buttonClickedX, setButtonClickedX] = useState(false);

  const [vehiclePhoto, setVehiclePhoto] = useState(null)
  const [licenceNumber, setLicenceNumber] = useState("")
  const [tagNumber, setTagNumber] = useState("")
  const [vehicleModel, setVehicleModel] = useState("")
  const [vehicleMake, setVehicleMake] = useState("")
  const [vehicleYear, setVehicleYear] = useState("")
  const [vehicleName, setVehicleName] = useState("")
  const [userSelect, setUserSelect] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState(null);

  const closeFunction = () => {
    props.history.goBack()
  }
  const notify = () => toast.success('Successfully Added!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError = () => toast.error('Error Happened!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyErrorRequired = () => toast.error('Fields marked * are required!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyLoading = () => toast.info('Loading', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notifyPhotoAdded = () => toast.info('Image successfully Added', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  const validateFe = () => {
    setErrorFlag(false)
    if (licenceNumber === "" || tagNumber === "" || vehicleYear === "" || vehicleMake === "" || vehicleModel === "" || userSelect === null) {
      setErrorFlag(true)
      notifyErrorRequired()
    }
  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  useEffect(() => {
    setErrorFlag(false)
    setButtonClickedX(false)
    const formData = new FormData();
    formData.append("vehicle_number", licenceNumber);
    formData.append("tag_number", tagNumber);
    formData.append("year", vehicleYear);
    formData.append("make", vehicleMake);
    formData.append("model", vehicleModel);
    formData.append("user", parseInt(userSelect));

    if (vehiclePhoto) {
      formData.append("media_file", vehiclePhoto);
    }

    setVehicleInfo(formData)
  }, [licenceNumber, tagNumber, vehicleYear, vehicleMake, vehicleModel, userSelect, vehiclePhoto]);

  const managerToken = localStorage.getItem("token")

  var userApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/users/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  useEffect(() => {
    axios(userApi).then((res) => {
      console.log("apt")
      console.log(res.data)
      // const numbers = res.data.map((apt) => apt.number);
      // setApartmentNumbers(numbers);
      setUser(res.data);
    })
  }, [])

  // useEffect(() => {
  //   aptValue.map((apt) => {
  //     if(apt.id == aptNo && apt.resident.length > 0) {
  //       setErrorMessage(`Apartment ${aptNo} already has a resident.`)
  //       setErrorFlag(true);
  //     }
  //   })
  // }, [aptNo])

  // useEffect(() => {
  //   aptValue.map((apt) => {
  //     if(apt.id == aptNo && apt.resident.length > 0) {
  //       setErrorMessage(`Apartment ${aptNo} already has a resident.`)
  //       setErrorFlag(true);
  //     }
  //   })
  // }, [aptNo])


  const handleDropdownChange = (e) => {
    console.log(e.target.value)
    setUserSelect(e.target.value);
  };


  var config = {
    method: "post",
    url: `https://kepah-24275.botics.co/api/v1/admin/vehicle/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
    data: vehicleInfo
  };

  useEffect(() => {
    if (buttonClickedX && !errorFlag) {
      notifyLoading()
      axios(config)
        .then((res) => {
          if (res.status == 201) {
            notify()
            setTimeout(() => {
              props.history.goBack()
            }, 5000);
          }
        })
        .catch((error) => {
          notifyError()
        });
    }
  }, [buttonClicked, errorFlag])



  const handleAddResident = () => {
    setErrorMessage("Loading")
    console.log(errorMessage)
    console.log(updateUser)
    axios(config)
      .then((res) => {
        if (res.status == 201) {
          props.closeAddNewResident()
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.non_field_errors) {
          setErrorMessage(error.response.data.non_field_errors);
          setErrorFlag(true)
        }
      });
  }

  return (
    <div className="incident_back">
      <div className="side_nav">
        <Sidebar history={props.history} nav="List Of Vehicles" />
      </div>
      <div className="incident_action_main_div">
        <div className="vehicleAddNew">
          <div className="add_new_resident_back">
            <BackButton closeFunction={closeFunction} />
            <div className="add_new_resident_background_color">
              <div className="add_new_resident_name_img_div">
                <h3 className="add_new_resident_text">Add New Vehicle</h3>
                <label htmlFor="upload-photo" className="upload_photo_text pt-1">
                  {vehiclePhoto != null ? vehiclePhoto.name : "Upload Vehicle Photo"}
                </label>
                <input
                  type="file"
                  id="upload-photo"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    const selectedFile = event.target.files[0];
                    setVehiclePhoto(selectedFile);
                    notifyPhotoAdded();
                  }}
                />
              </div>
              {/* <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
         {errorMessage? <p style={{fontSize: "13px", paddingLeft: "5px", color: "red"}}>{errorMessage}</p>: ""}
        </div> */}
              <div className="input_fields_div">

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Vehicle Owner:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>
                  <div className="phone_input_div">
                    <div className="phone_input_field_div">

                      <select
                        className="phone_input_field"
                        onChange={handleDropdownChange}
                      >
                        {/* Render the dropdown options based on the fetched data */}
                        <option> Select An User </option>
                        {user.map((usr) => (
                          <option value={usr.id} > {usr.name} </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">License Number:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input className="phone_input_field" type="text" onChange={(e) => setLicenceNumber(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Tag:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input className="phone_input_field" type="text" onChange={(e) => setTagNumber(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Model:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input className="phone_input_field" type="text" onChange={(e) => setVehicleModel(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Make:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input className="phone_input_field" type="text" onChange={(e) => setVehicleMake(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Year:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input className="phone_input_field" type="phone" value={vehicleYear} onChange={(e) => setVehicleYear(e.target.value)} />
                    </div>
                  </div>
                </div>

                <Form className="edit_resident_profile_save_button_div">
                  <Button
                    className="buttonSave"
                    variant="primary"
                    onClick={buttonClicked}
                  >
                    Save
                  </Button>
                </Form>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>

  );
};

export default AddVehicle;
