import React, { useState, useEffect } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";

import searchImage from "../../../assets/Search.png"
import EditSvg from "../../../assets/edit.svg"


import "bootstrap/dist/css/bootstrap.min.css";

import { Popover, OverlayTrigger } from "react-bootstrap";
const ListOfSecurityCompaniesPage = ({ ...props }) => {

  const [filtered, setFiltered] = useState([]);
  const [securityCompany, setSecurityCompany] = useState([])
  const [search, setSearch] = useState("");

  const managerToken = localStorage.getItem("token")

  var userApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/security-company/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  useEffect(()=> {

    axios(userApi).then((res) => {
      console.log("apt")
      console.log(res.data)
      setSecurityCompany(res.data)
      setFiltered(res.data)
    })
  }, [])

  useEffect(() => {
    console.log(securityCompany)
    let filter = securityCompany.filter((val) => {
      if (search !== "" && val.name && val.name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
      if(search !== "" && val.license_number && val.license_number.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
    });
    if (filter.length < 1 && search === "") {
      setFiltered(securityCompany);
    } else {
      setFiltered(filter);
    }
  }, [search]);

  return (
    <div>
      <div className="list_of_officers_manage_div_main_page ">
        <div>
          <span className="list_of_officers_text_main_page">
            Security Companies ({securityCompany.length})
          </span>
        </div>
      </div>

      <div className="list_of_officers_input_div">
        <input
          type="text"
          className="list_of_officers_input"
          placeholder="Name or License No...."
          onChange={(e) => setSearch(e.target.value)}
          // value={(e) => e.target.val}
        />
        <div className="list_of_officers_search_btn">
          <img src={searchImage} />
        </div>
        <Form className="form_inline_criminal">
          <Button
            className="save_btn_add_criminal"
            variant="primary"
            type="submit"
            onClick={(e) =>{
              e.preventDefault()
              props.history.push("listofsecuritycompanies/add")
            }}
          >
            ADD NEW SECURITY COMPANY
          </Button>
        </Form>
      </div>

      <div>
        <div className="officer_license_number_main_page">
          <span className="securityCompanyName">Compny Name</span>
          <span className="securityCompanyEmail">Email</span>
          <span className="securityCompanyAddress">Address</span>
          <span className="securityCompanyPhone">Phone</span>
          <span className="securityCompanyLicence">Licence Number</span>
          <span className="securityCompanyEdit"></span>
        </div>
        <div className="bottom_border_main_page" />

        <div className="all_security_companies_lists ">
          {filtered.map((val, ind) => {
            return (
              <div
                className="officer_license_number_white_back_main_page row_alternate"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                  <span className="securityCompanyName">
                    {val.name ? val.name : ""}
                  </span>

                <span className="securityCompanyEmail">
                  {val.email ? val.email : ""}
                </span>
                <span className="securityCompanyAddress">
                  {val.address ? val.address : ""}
                </span>

                <span className="securityCompanyPhone">{val.phone}</span>
                <span className="securityCompanyLicence">
                  {val.license_number}
                </span>

                <span className="securityCompanyEdit" style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <div onClick={() => {
                      localStorage.setItem("securityCompanyInfoChange", JSON.stringify(val));
                      props.history.push("listofsecuritycompanies/edit")
                    }}>
                      <img src={EditSvg} style={{ height: "16px", width: "16px", cursor: "pointer" }}/>
                    </div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ListOfSecurityCompaniesPage;
