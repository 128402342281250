import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Popover, OverlayTrigger, Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dropdown from "../../../assets/dropdown.svg"
import "bootstrap/dist/css/bootstrap.min.css";

import upload from "../../../assets/upload.svg"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";

const TowReportTemplate = ({ ...props }) => {
    const [role, setRole] = useState("")
    const [incidentType, setIncidentType] = useState("")
    const [timeOfTow, setTimeOfTow] = useState("")
    const [towCompany, setTowCompany] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")
    const [removeVehicleFrom, setRemoveVehicleFrom] = useState("")
    const [reason, setReason] = useState("")
    const [amountOfTime, setAmountOfTime] = useState("")
    const [nameOfLawEnforce, setNameOfLawEnforce] = useState("")
    const [signatureOfLawEnforce, setSignatureOfLawEnforce] = useState("")
    const [nameOfPrivetPropertyOwner, setNameOfPrivetPropertyOwner] = useState("")
    const [year, setYear] = useState("")
    const [make, setMake] = useState("")
    const [model, setModel] = useState("")
    const [vehicleType, setVehicleType] = useState("")
    const [vehicleColor, setVehicleColor] = useState("")
    const [vinNumber, setVinNumber] = useState("")
    const [llcNumber, setLlcNumber] = useState("")
    const [llcState, setLlcState] = useState("")
    const [vehicleDamage, setVehicleDamage] = useState("")
    const [dateField, setDateField] = useState("")
    const [progressMessage, setProgressMessage] = useState("")
    const [towReport, setTowReport] = useState([])
    const [errorState, setErrorState] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)
    const [userPhoto, setUserPhoto] = useState(null)

    const vehicleInf = JSON.parse(localStorage.getItem("vehicleReportInfo"));

    const closeFunction = () => {
        props.history.goBack()
    }



    const notifyErrorRequired = () => toast.error('Fields marked * are required!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const notifyPhotoSelected = () => toast.info('Photos Selected', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    useEffect(async () => {
        if (vehicleInf && vehicleInf.license_plate != null) {
            setLlcNumber(vehicleInf.license_plate)
            setMake(vehicleInf.vehicleMake)
            setModel(vehicleInf.vehicleModel)
            setYear(vehicleInf.vehicleYear)
        }
    }, [])

    useEffect(() => {
        console.log(vehicleInf);

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {

                localStorage.setItem("vehicleReportInfo", JSON.stringify(null))
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);


    const notify = () => toast.success('Successfully Added!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const notifyError = () => toast.error('Error Happened!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const notifyErrorX = () => toast.error('A vehicle with this license number does not exist in our system', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyErrorInput = () => toast.error('Fields marked * are required', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const notifyLoading = () => toast.info('Loading', {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const validate = () => {
        setButtonClicked(true)
        setErrorState(false);
        if (dateField === "") {
            setErrorState(true);

        }
        if (timeOfTow === "") {
            setErrorState(true);

        }
        if (towCompany === "") {
            setErrorState(true);

        }
        if (phone === "") {
            setErrorState(true);

        }
        if (address === "") {
            setErrorState(true);

        }
        if (removeVehicleFrom === "") {
            setErrorState(true);

        }
        if (reason === "") {
            setErrorState(true);

        }
        if (amountOfTime === "") {
            setErrorState(true);

        }
        if (nameOfLawEnforce === "") {
            setErrorState(true);

        }
        // if (signatureOfLawEnforce === "") {
        //     setErrorState(true);

        // }
        if (year === "") {
            setErrorState(true);

        }
        if (make === "") {
            setErrorState(true);
        }
        if (model === "") {
            setErrorState(true);

        }
        if (vehicleType === "") {
            setErrorState(true);

        }
        if (vehicleColor === "") {
            setErrorState(true);

        }
        if (vinNumber === "") {
            setErrorState(true);

        }
        if (llcNumber === "") {
            setErrorState(true);

        }
        if (llcState === "") {
            setErrorState(true);

        }
        if (errorState) {
            notifyErrorRequired()
        }

    };

    const imageChangeHandler = () => {

    }

    const handleReportTypeChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === "incident") {
            props.history.push("incident")
        } else if (selectedValue === "auto") {
            props.history.push("auto")
        } else if (selectedValue === "tow") {
            props.history.push("tow")
        }
      };
    

    useEffect(() => {
        setButtonClicked(false)
        // const updateTowReport = {
        //     date: dateField,
        //     time: timeOfTow,
        //     tow_company: towCompany,
        //     phone_number: phone,
        //     address: address,
        //     vehicle_location: removeVehicleFrom,
        //     tow_reason: reason,
        //     amount_of_time: parseInt(amountOfTime),
        //     tow_authorizer_name: nameOfLawEnforce,
        //     signature_of_tow_authorizer: signatureOfLawEnforce,
        //     vehicle_year: year,
        //     vehicle_make: make,
        //     vehicle_model: model,
        //     vehicle_type: vehicleType,
        //     vehicle_color: vehicleColor,
        //     vin_no: vinNumber,
        //     lic_no: llcNumber,
        //     lic_state: llcState,
        //     vehicle_damage: vehicleDamage,
        //     private_property_owner: nameOfPrivetPropertyOwner
        // };
        const formData = new FormData();
        formData.append("lic_no", llcNumber);
        formData.append("date", dateField);
        formData.append("time", timeOfTow);
        formData.append("tow_company", towCompany);
        formData.append("phone_number", phone);
        formData.append("address", address);
        formData.append("vehicle_location", removeVehicleFrom);
        formData.append("tow_reason", reason);
        formData.append("amount_of_time", parseInt(amountOfTime));
        formData.append("tow_authorizer_name", nameOfLawEnforce);
        formData.append("signature_of_tow_authorizer", signatureOfLawEnforce);
        formData.append("vehicle_year", year);
        formData.append("vehicle_make", make);
        formData.append("vehicle_model", model);
        formData.append("vehicle_type", vehicleType);
        formData.append("vehicle_color", vehicleColor);
        formData.append("vin_no", vinNumber);
        formData.append("lic_no", llcNumber);
        formData.append("lic_state", llcState);
        formData.append("vehicle_damage", vehicleDamage);
        formData.append("private_property_owner", nameOfPrivetPropertyOwner);
        console.log("userPhoto", userPhoto)
        if (userPhoto) {
            userPhoto.forEach((photo, index) => {
                formData.append(`uploaded_images`, photo);
            });
        } else {
            formData.append("uploaded_images", "");
        }
        setTowReport(formData);
    }, [
        towCompany,
        phone,
        address,
        removeVehicleFrom,
        reason,
        amountOfTime,
        nameOfLawEnforce,
        signatureOfLawEnforce,
        year,
        make,
        model,
        vehicleType,
        vehicleColor,
        vinNumber,
        llcNumber,
        llcState,
        vehicleDamage,
        nameOfPrivetPropertyOwner,
        userPhoto
    ]);

    const managerToken = localStorage.getItem("token")

    var config = {
        method: "post",
        url: `https://kepah-24275.botics.co/api/v1/admin/tow-report/`,
        headers: {
            Authorization: `token ${managerToken}`
        },
        data: towReport
    };

    useEffect(() => {
        if (buttonClicked && !errorState) {
            notifyLoading()
            axios(config).then((res) => {
                if (res.status == 201) {
                    notify()
                    localStorage.setItem("vehicleReportInfo", JSON.stringify(null))
                    console.log(res.data)
                    setTimeout(() => {
                        props.closeAddTow()
                        props.history.push("/vehicleaction")
                    }, 5000);
                }
            }).catch((err) => {
                if (err.response.data.lic_no) {
                    notifyErrorX()
                }
                else {
                    notifyError()
                }
                console.log(err.response.data);

            })
        } else if (buttonClicked && errorState) {
            notifyErrorInput()
        }
    }, [buttonClicked, errorState])

    const handleContinue = () => {
        setProgressMessage("Loading")
        console.log("date:", dateField)
        //console.log("timeOfTow:", timeOfTow);
        //console.log("towCompany:", towCompany);
        //console.log("phone:", phone);
        //console.log("address:", address);
        //console.log("removeVehicleFrom:", removeVehicleFrom);
        //console.log("reason:", reason);
        //console.log("amountOfTime:", amountOfTime);
        //console.log("nameOfLawEnforce:", nameOfLawEnforce);
        //console.log("signatureOfLawEnforce:", signatureOfLawEnforce);
        //console.log("nameOfPrivatePropertyOwner:", nameOfPrivetPropertyOwner);
        //console.log("year:", year);
        //console.log("make:", make);
        //console.log("model:", model);
        //console.log("vehicleType:", vehicleType);
        //console.log("vehicleColor:", vehicleColor);
        //console.log("vinNumber:", vinNumber);
        //console.log("llcNumber:", llcNumber);
        //console.log("llcState:", llcState);
        //console.log("vehicleDamage:", vehicleDamage);
        if (!errorState) {
            axios(config).then((res) => {
                if (res.status == 201) {
                    setProgressMessage("Successfully Added!")
                }
            }).catch((err) => {
                console.log(err.response.data);
                setProgressMessage("Error, Please try again!")

            })
        }
    }

    function handleTimeChange(e) {
        let inputValue = e.target.value;

        // Remove any non-digit characters from the input
        inputValue = inputValue.replace(/\D/g, '');

        // Format the input as HH:MM
        if (inputValue.length > 2) {
            inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2);
        }

        // Update the input field's value
        e.target.value = inputValue;
        setTimeOfTow(inputValue)
    }

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, "");
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (!match) return phoneNumber;

        let formattedNumber = "";

        if (match[1]) {
            formattedNumber += `(${match[1]})`;
        }

        if (match[2]) {
            formattedNumber += ` ${match[2]}`;
        }

        if (match[3]) {
            formattedNumber += `-${match[3]}`;
        }

        return formattedNumber;
    };

    return (
        <div className="incident_back">
            <div className="side_nav">
                <Sidebar history={props.history} nav="Incident Reports" />
            </div>
            <div className="incident_action_main_div">
                <div className="incident_action_main_page newFlow">
                    <div className="mgmg pt-3">
                        <div className="title_add_incident_dd">
                            <Row className="">
                                <Col className="col-md-3">
                                    <select className="titleDropdown" id="reportType" onChange={handleReportTypeChange}>
                                        <option value="tow">Tow Form Template</option>
                                        <option value="incident">Incident Report Template</option>
                                        <option value="auto">Auto Report</option>
                                    </select>
                                </Col>
                                <Col className="col-md-2">
                                    <div class="svg-icon">
                                        <img style={{ width: "10px", height: "10px" }} src={dropdown} alt="Icon" />
                                    </div>
                                </Col>
                                <Col>
                                </Col>
                                <Col className="col-md-2 textO">

                                </Col>
                                <Col className="col-md-2">

                                </Col>
                            </Row>
                        </div>
                        <div className="pt-3">
                            <div>
                                <BackButton closeFunction={closeFunction} />
                                <div className="newFlow">
                                    <Container>

                                        <Row>
                                            <Col className="col-md-4">
                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Date:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field_date" type="date" onChange={(event) => setDateField(event.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Time:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" value={timeOfTow} placeholder="12:00" type="text" onChange={(e) => handleTimeChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Name of company performing tow:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="text" onChange={(e) => setTowCompany(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Phone:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="text" value={phone} onChange={(e) => setPhone(formatPhoneNumber(e.target.value))} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Address:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="text" onChange={(e) => setAddress(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Remove vehicle from:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="text" onChange={(e) => setRemoveVehicleFrom(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Reason:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="text" onChange={(e) => setReason(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="col-md-4">

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Amount of time the motor vehicle has been on the private property:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="number" value={amountOfTime} onChange={(e) => setAmountOfTime(e.target.value)} />
                                                            <span className="time_unit">Hours</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Name of the law enforcement officer or private property owner authorizing tow:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="text" onChange={(e) => setNameOfLawEnforce(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 
                            <div className="inputWidth">
                                <Row>
                                    <Col className="col-md-10">
                                        <label className="incident_report_label">Signature of person above:</label>
                                    </Col>
                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                        {errorState ? "*" : ""}
                                    </Col>
                                </Row>

                                <div className="phone_input_div bg_of_drop">
                                    <div className="phone_input_field_div">
                                        <input className="phone_input_field" type="text" onChange={(e) => setSignatureOfLawEnforce(e.target.value)} />
                                    </div>
                                </div>
                            </div> */}

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">Name of the private property owner (if different from above)</label>
                                                        </Col>

                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="text" onChange={(e) => setNameOfPrivetPropertyOwner(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="header_incident_report">
                                                    <p className="incidentInfoTitleMg">Vehicle Information</p>
                                                </div>
                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label_type">Year:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div bg_of_drop">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="text" value={year} onChange={(e) => setYear(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-md-4 d-flex flex-column">

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-6">
                                                            <div>
                                                                <Row>
                                                                    <Col className="col-md-10">
                                                                        <label className="incident_report_label">Make:</label>
                                                                    </Col>
                                                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                                        {errorState ? "*" : ""}
                                                                    </Col>
                                                                </Row>

                                                                <div className="phone_input_div">
                                                                    <div className="phone_input_field_div">
                                                                        <input className="phone_input_field" type="text" value={make} onChange={(e) => setMake(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-md-6">
                                                            <div>
                                                                <Row>
                                                                    <Col className="col-md-10">
                                                                        <label className="incident_report_label">Model:</label>
                                                                    </Col>
                                                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                                        {errorState ? "*" : ""}
                                                                    </Col>
                                                                </Row>

                                                                <div className="phone_input_div">
                                                                    <div className="phone_input_field_div">
                                                                        <input className="phone_input_field" type="text" value={model} onChange={(e) => setModel(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-6">
                                                            <div>
                                                                <Row>
                                                                    <Col className="col-md-10">
                                                                        <label className="incident_report_label">Type:</label>
                                                                    </Col>
                                                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                                        {errorState ? "*" : ""}
                                                                    </Col>
                                                                </Row>

                                                                <div className="phone_input_div">
                                                                    <div className="phone_input_field_div">
                                                                        <input className="phone_input_field" type="text" onChange={(e) => setVehicleType(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-md-6">
                                                            <div>
                                                                <Row>
                                                                    <Col className="col-md-10">
                                                                        <label className="incident_report_label">Color:</label>
                                                                    </Col>
                                                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                                        {errorState ? "*" : ""}
                                                                    </Col>
                                                                </Row>

                                                                <div className="phone_input_div">
                                                                    <div className="phone_input_field_div">
                                                                        <input className="phone_input_field" type="text" onChange={(e) => setVehicleColor(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label">VIN Number:</label>
                                                        </Col>
                                                        <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                            {errorState ? "*" : ""}
                                                        </Col>
                                                    </Row>

                                                    <div className="phone_input_div">
                                                        <div className="phone_input_field_div">
                                                            <input className="phone_input_field" type="text" onChange={(e) => setVinNumber(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-6">
                                                            <div>
                                                                <Row>
                                                                    <Col className="col-md-10">
                                                                        <label className="incident_report_label">LIC. No.:</label>
                                                                    </Col>
                                                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                                        {errorState ? "*" : ""}
                                                                    </Col>
                                                                </Row>

                                                                <div className="phone_input_div">
                                                                    <div className="phone_input_field_div">
                                                                        <input className="phone_input_field" type="text" value={llcNumber} onChange={(e) => setLlcNumber(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-md-6">
                                                            <div>
                                                                <Row>
                                                                    <Col className="col-md-10">
                                                                        <label className="incident_report_label">State:</label>
                                                                    </Col>
                                                                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                                        {errorState ? "*" : ""}
                                                                    </Col>
                                                                </Row>

                                                                <div className="phone_input_div">
                                                                    <div className="phone_input_field_div">
                                                                        <input className="phone_input_field" type="text" onChange={(e) => setLlcState(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="inputWidth">
                                                    <Row>
                                                        <Col className="col-md-10">
                                                            <label className="incident_report_label_type">Vehicle damage (list if any):</label>
                                                        </Col>

                                                    </Row>

                                                    <div className="phone_input_div">
                                                        <div className="phone_input_field_div">
                                                            <textarea className="phone_input_field" rows={3} type="text" onChange={(e) => setVehicleDamage(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <Row>
                                                            <Col className="col-md-10">
                                                                <label className="incident_report_label">Upload photo verification:</label>
                                                            </Col>
                                                            <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                                                                {errorState ? "*" : ""}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="phone_input_field_div_xx inputWidth">
                                                        <label htmlFor="upload-photo">

                                                            <input
                                                                type="file"
                                                                id="upload-photo"
                                                                style={{ display: "none" }}
                                                                onChange={(event) => {
                                                                    const selectedFile = event.target.files[0];
                                                                    console.log(selectedFile)
                                                                    const selectedFiles = Array.from(event.target.files);
                                                                    setUserPhoto(selectedFiles)
                                                                    notifyPhotoSelected()

                                                                }}
                                                                multiple
                                                            />
                                                            <img className="uploadImg" src={upload} alt="Upload Icon" />
                                                        </label>
                                                    </div>
                                                    <div className="mt-3 inputWidth" >
                                                        <Row>
                                                            {userPhoto ? userPhoto.map((phto) => (
                                                                <Col className="col-md-4">
                                                                    <img className="m-1" style={{ width: "100%", height: "80px" }} src={URL.createObjectURL(phto)} alt="Upload Icon" />
                                                                </Col>
                                                            )) : ""}
                                                        </Row>
                                                    </div>

                                                </div>


                                                <div className="mt-auto inputWidth">
                                                    <Button className="buttonStyleAddTow" onClick={validate}>CONTINUE</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default TowReportTemplate;