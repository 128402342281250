import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Popover, OverlayTrigger, Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dropdown from "../../../assets/dropdown.svg"
import dropdown_up from "../../../assets/dropdown_up.svg"
import "bootstrap/dist/css/bootstrap.min.css";
import IncidentReportTemplate from "./IncidentReportTemplate";
import IncidentReportTemplatePt2 from "./AddIncidentReportPt2";
import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";

const AddIncidentReport = ({ ...props }) => {
    const [incidentReportTemplate, setIncidentReportTemplate] = useState(true)
    const [autoReport, setAutoReport] = useState(false)
    const [towReport, setTowReport] = useState(false)
    const [incidentReportPt2, setIncidentReportPt2] = useState(false)
    const [dropdownOn, setDropdownOn] = useState(false)

    const closeFunction = () => {
        props.history.goBack()
    }

    const handleContinue = () => {
        setIncidentReportPt2(true)
        setIncidentReportTemplate(false)
    }

    const handleBack = () => {
        setIncidentReportPt2(false)
        setIncidentReportTemplate(true)
    }

    const handleReportTypeChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === "incident") {
            props.history.push("incident")
        } else if (selectedValue === "auto") {
            props.history.push("auto")
        } else if (selectedValue === "tow") {
            props.history.push("tow")
        }
      };

    if (incidentReportTemplate) {
        return (
            <div className="incident_back">
                <div className="side_nav">
                    <Sidebar history={props.history} nav="Incident Reports" />
                </div>
                <div className="incident_action_main_div">
                    <BackButton closeFunction={closeFunction} />
                    <div className="incident_action_main_page">
                        <div className="mgmg">
                            <div className="title_add_incident_dd">
                                <Row className="">
                                    <Col className="col-md-3">
                                        <select className="titleDropdown" id="reportType" onChange={handleReportTypeChange}>
                                            <option value="incident">Incident Report Template</option>
                                            <option value="auto">Auto Report</option>
                                            <option value="tow">Tow Form Template</option>
                                        </select>
                                    </Col>
                                    <Col className="col-md-2">
                                        <div class="svg-icon">
                                            {dropdownOn ? <img style={{ width: "10px", height: "10px" }} src={dropdown_up} alt="Icon" /> : <img style={{ width: "10px", height: "10px" }} src={dropdown} alt="Icon" />}
                                        </div>
                                    </Col>
                                    <Col>
                                    </Col>
                                    <Col className="col-md-2 textO">
                                        <div className="">
                                            {incidentReportPt2 ? <span className="spanGo"> &lt; Go Back </span> : ""}
                                        </div>
                                    </Col>
                                    <Col className="col-md-2">
                                        <div className="textOnTop">
                                            {incidentReportPt2 ? "2 of 2" : "1 of 2"}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div >
<IncidentReportTemplate history={props.history} closeAddIncidentReport={props.closeAddIncidentReport} handleContinue={handleContinue} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (incidentReportPt2) {
        return (
            <div className="incident_back">
                <div className="side_nav">
                    <Sidebar history={props.history} nav="Incident Reports" />
                </div>
                <div className="incident_action_main_div">
                    <BackButton closeFunction={closeFunction} />
                    <div className="incident_action_main_page" style={{ marginTop: "15%", height: "45%" }}>
                        <div className="mgmg">
                            <div className="title_add_incident_dd">
                                <Row className="">
                                    <Col className="col-md-3">
                                        <select className="titleDropdown" id="reportType" onChange={handleReportTypeChange}>
                                            <option value="incident">Incident Report Template</option>
                                            <option value="auto">Auto Report</option>
                                            <option value="tow">Tow Form Template</option>
                                        </select>
                                    </Col>
                                    <Col className="col-md-2">
                                        <div class="svg-icon">
                                            {dropdownOn ? <img style={{ width: "10px", height: "10px" }} src={dropdown_up} alt="Icon" /> : <img style={{ width: "10px", height: "10px" }} src={dropdown} alt="Icon" />}
                                        </div>
                                    </Col>
                                    <Col>
                                    </Col>
                                    <Col className="col-md-2 textO">
                                        <div className="">
                                            {incidentReportPt2 ? <span className="spanGo" onClick={handleBack}> &lt; Go Back </span> : ""}
                                        </div>
                                    </Col>
                                    <Col className="col-md-2">
                                        <div className="textOnTop">
                                            {incidentReportPt2 ? "2 of 2" : "1 of 2"}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div >
                                <IncidentReportTemplatePt2 handleBack={handleBack} history={props.history} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default AddIncidentReport;
