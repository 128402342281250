import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { format, parseISO } from 'date-fns';

import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";


const AddNewVendor = ({ ...props }) => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("")

  const [user, setUser] = useState([]);
  const [ten, setTen] = useState("");

  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [updateUser, setUpdateUser] = useState(null);

  const [showDropdown, setShowDropdown] = useState(true);


  const [errorFlag, setErrorFlag] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [buttonClickedX, setButtonClickedX] = useState(false);

  const [vehiclePhoto, setVehiclePhoto] = useState(null)
  const [licenceNumber, setLicenceNumber] = useState("")
  const [tagNumber, setTagNumber] = useState("")
  const [vehicleModel, setVehicleModel] = useState("")
  const [vehicleMake, setVehicleMake] = useState("")
  const [vehicleYear, setVehicleYear] = useState("")
  const [vehicleName, setVehicleName] = useState("")
  const [userSelect, setUserSelect] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState(null);

  const formatDate = (date) => {
    const joinedDateISO = date; // "2023-06-25T21:20:45.302375Z"
    const parsedJoinedDate = parseISO(joinedDateISO);

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedJoinedDate = format(parsedJoinedDate, 'yyyy-MM-dd  h:mm a');
    return formattedJoinedDate
  }

  const notify = () => toast.success('Successfully Added!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError = () => toast.error('Error Happened!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyErrorRequired = () => toast.error('Fields marked * are required!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyLoading = () => toast.info('Loading', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notifyPhotoAdded = () => toast.info('Image successfully Added', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const validateFe = () => {
    setErrorFlag(false)
    if (name === "" || email === "" || companyName === "" || phone === "") {
      setErrorFlag(true)
      notifyErrorRequired()
    }
  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return phoneNumber;

    let formattedNumber = "";

    if (match[1]) {
      formattedNumber += `(${match[1]})`;
    }

    if (match[2]) {
      formattedNumber += ` ${match[2]}`;
    }

    if (match[3]) {
      formattedNumber += `-${match[3]}`;
    }

    return formattedNumber;
  };

  useEffect(() => {
    const timeNow = new Date();

    // Format the parsed date as 'yyyy-MM-dd'
    const formattedTime = format(timeNow, 'h:mm a');

    setErrorFlag(false)
    setButtonClickedX(false)
    const info = {
      name: name,
      email: email,
      phone_number: phone,
      company: companyName,
      arrival_time: formattedTime,
    }

    setVehicleInfo(info)
  }, [name, email, phone, companyName]);

  const managerToken = localStorage.getItem("token")

  var userApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/users/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  const handleDropdownChange = (e) => {
    console.log(e.target.value)
    setUserSelect(e.target.value);
  };

  const closeFunction = () => {
    props.history.goBack()
  }


  var config = {
    method: "post",
    url: `https://kepah-24275.botics.co/api/v1/admin/vendors/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
    data: vehicleInfo
  };

  useEffect(() => {
    if (buttonClickedX && !errorFlag) {
      notifyLoading()
      axios(config)
        .then((res) => {
          if (res.status == 201) {
            notify()
           
            setTimeout(() => {
              props.history.goBack()
            }, 5000);
          }
        })
        .catch((error) => {
          notifyError()
          console.log(error.response.data)
        });
    }
  }, [buttonClicked, errorFlag])

  return (
    <div className="incident_back">
      <div className="side_nav">
        <Sidebar history={props.history} nav="List Of Vendors" />
      </div>
      <div className="incident_action_main_div">
        <div className="vendorAdd">
          <div className="add_new_resident_back">
            <div className="add_new_resident_background_color">
              <div className="add_new_resident_name_img_div">
                <h3 className="add_new_resident_text">Add New Vendor</h3>
                <BackButton closeFunction={closeFunction} />
                {/* <label htmlFor="upload-photo" className="upload_photo_text pt-1">
            {vehiclePhoto != null ? vehiclePhoto.name : "Upload Vehicle Photo"}
          </label>
          <input
            type="file"
            id="upload-photo"
            style={{ display: "none" }}
            onChange={(event) => {
              const selectedFile = event.target.files[0];
              setVehiclePhoto(selectedFile);
              notifyPhotoAdded();
            }}
          /> */}

              </div>
              {/* <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
         {errorMessage? <p style={{fontSize: "13px", paddingLeft: "5px", color: "red"}}>{errorMessage}</p>: ""}
        </div> */}
              <div className="input_fields_div">

                {/* <div>
            <Row>
              <Col className="col-md-10">
                <label className="phone_label">Vehicle Owner:</label>
              </Col>
              <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                {errorFlag ? "*" : ""}
              </Col>
            </Row>
            <div className="phone_input_div">
              <div className="phone_input_field_div">

                <select
                  className="phone_input_field"
                  onChange={handleDropdownChange}
                >
                  <option> Select An User </option>
                  {user.map((usr) => (
                    <option value={usr.id} > {usr.name} </option>
                  ))}
                </select>
              </div>
            </div>
          </div> */}

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Name:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={name} className="phone_input_field" type="text" onChange={(e) => setName(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Email:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={email} className="phone_input_field" type="text" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Phone:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input value={phone} className="phone_input_field" type="text" onChange={(e) => setPhone(formatPhoneNumber(e.target.value))} />
                    </div>
                  </div>
                </div>

                <div>
                  <Row>
                    <Col className="col-md-10">
                      <label className="phone_label">Company:</label>
                    </Col>
                    <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                      {errorFlag ? "*" : ""}
                    </Col>
                  </Row>

                  <div className="phone_input_div">
                    <div className="phone_input_field_div">
                      <input className="phone_input_field" type="text" onChange={(e) => setCompanyName(e.target.value)} />
                    </div>
                  </div>
                </div>
                {/* 
          <div>
            <Row>
              <Col className="col-md-10">
                <label className="phone_label">Year:</label>
              </Col>
              <Col style={{ color: "red" }} className="incident_report_label col-md-2">
                {errorFlag ? "*" : ""}
              </Col>
            </Row>

            <div className="phone_input_div">
              <div className="phone_input_field_div">
                <input className="phone_input_field" type="phone" value={vehicleYear} onChange={(e) => setVehicleYear(e.target.value)} />
              </div>
            </div>
          </div> 
          */}

                <Form className="edit_resident_profile_save_button_div">
                  <Button
                    className="buttonSave"
                    variant="primary"
                    onClick={buttonClicked}
                  >
                    Save
                  </Button>
                </Form>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>

      </div>
    </div>

  );
};

export default AddNewVendor;
