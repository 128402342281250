import axios from 'axios';
import getManagerToken from './ManagerToken';

export default async function isAuthenticated() {
  try {
    const getToken = getManagerToken(); // Assuming this function retrieves the token
    const config = {
      method: 'get',
      url: 'https://kepah-24275.botics.co/rest-auth/user/',
      headers: {
        Authorization: `token ${getToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(config);
    // Log the response data from the successful request
    // console.log('Response from auth user', response.data);
    // Return true to indicate authentication success
    return true;
  } catch (error) {
    // Log the error or handle it as needed
    console.log('Error from auth user', error);

    // Return false to indicate authentication failure
    return false;
  }
}