import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ToastContainer, toast } from 'react-toastify';

import upImage from "../../../assets/manager-arrow-up.png";
import barImage from "../../../assets/manager-arrow-bottom.png"
import logoImage from "../../../assets/forest-logo-manager.png"
import upload from "../../../assets/upload.svg"
import transparentLogo from "../../../assets/transparent2.png"
import postApi from "../../../config/ApiPost"
import "./style.css";

import { notifySuccess, notifyError, notifyErrorRequired, notifyLoading } from "../../../config/Notifications";
import callApi from "../../../config/callApi";

const EditResident = ({ ...props }) => {
  const [logo, setLogo] = useState(null)
  const [logoSelected, setLogoSelected] = useState(null)
  const [postData, setPostData] = useState()
  const [logoUrl, setLogoUrl] = useState()
  const [removeLogoChecked, setRemoveLogoChecked] = useState(false)

  const closeFunction = () => {
    props.history.goBack()
  }

  useEffect(() => {
    console.log("Props", props)
    if (logoSelected) {
      // Create a URL for the selected image
      const imageURL = URL.createObjectURL(logoSelected);
      setLogoUrl(imageURL);
    }
  }, [logoSelected]);


  useEffect(() => {
    (async () => {
      try {
        const logo = await callApi('get', `https://kepah-24275.botics.co/api/v1/admin/logo/?residence_building=1`, null, 'RemoveLogoPage GET')
        setLogo(logo.data.logo)
        console.log('logo', logo)
      } catch (error) {
        console.error('Error checking logo:', error);
      }
    }) ();
  }, []);
  

  useEffect(() => {
    // console.log("logo", logo)
    const formData = new FormData();
    if(logoSelected) {
      setRemoveLogoChecked(false)
      formData.append("logo", logoSelected); 
      formData.append("building_id", parseInt(1));      
    }
    setPostData(formData)
  }, [logoSelected])

  const handlePostData = async (e) => {
    e.preventDefault();
    notifyLoading({ toastMessage: "Loading", autoClose: 5000 });
    const response = await callApi("put", "https://kepah-24275.botics.co/api/v1/admin/logo/?residence_building=1", postData, "RemoveLogoPage");
    if (response.status === 200 || response.status === 201) {
      notifySuccess({ toastMessage: "Logo updated successfully", autoClose: 5000 });
      setTimeout(() => {
        props.history.push("/dashboard")
      }, 5000);
    } else {
      notifyError({ toastMessage: "Error updating logo", autoClose: 5000 });
    }
  };

  const removeLogo = () => {
    setRemoveLogoChecked(true)
    notifySuccess({ toastMessage: "Logo removed successfully", autoClose: 5000 });
    setLogo(null);
  };
  
  return (
    <div className="edit_manager_back">
      <div className="insurance_policy_background_color">
        <div className="">
          <h6>Add/Remove Logo</h6>
        </div>

        <div className="input_fields_insurance_div">
          <div className="insurance_policy_field">
            <div className="current_logo_remove_div">
              <label className="upload_pdf_label">Current logo:</label>
              <span onClick={removeLogo} className="remove_text">Remove</span>
            </div>

            <div className="forest_cove_img_div">
              <div className="upload_pdf_field_div">
                {removeLogoChecked && logo === null && (
                   <>
                    {/* <img
                      src={logoImage}
                      style={{ width: "300px", height: "100px" }}
                    /> */}
                  </>
                  )}
                {!removeLogoChecked && (
                  <>
                    <img
                      src={logo ? logo : logoUrl}
                      style={{ width: "300px", height: "100px" }}
                    />
                  </>
                )}
                
              </div>
            </div>
          </div>
          <div className="insurance_policy_field">
            <label className="upload_pdf_label">Upload image file below:</label>
            <div className="upload_pdf_div">
              <div className="upload_pdf_field_div">
                <label htmlFor="upload-photo">
                  <input
                    type="file"
                    id="upload-photo"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      const selectedFile = event.target.files[0];
                      setLogoSelected(selectedFile)
                      notifySuccess({ toastMessage: "Logo uploaded successfully", autoClose: 5000 });
                      setRemoveLogoChecked(false)
                      setLogo(null)
                    }}
                    multiple
                  />
                  <img className="uploadLogo" src={upload} alt="Upload Icon" />
                </label>
              </div>
            </div>
          </div>

          <Form className="insurance_policy_profile_save_button_div">
            <Button
              disabled={logoSelected === null || removeLogoChecked === true ? true: false}
              className="insurance-policy-btn"
              variant="primary"
              onClick={(e) => handlePostData(e)}
            >
              Save
            </Button>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditResident;
