import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";

import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { notifyError, notifySuccess, notifyErrorRequired, notifyLoading } from "../../Toast/Toast";

const AddNewResident = ({ ...props }) => {
  const [name, setName] = useState("");
  const [aptNo, setAptNo] = useState("");
  const [resBuild, setResBuild] = useState("");
  const [number, setNumber] = useState("")
  const [ten, setTen] = useState("");

  const [userCSV, setUserCSV] = useState(null);
  const [csvForm, setCSVForm] = useState(null);
  const [CSVCheck, setCSVCheck] = useState(false);

  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [updateUser, setUpdateUser] = useState(null);

  const [selectedAptNo, setSelectedAptNo] = useState(0);
  const [apartmentNumbers, setApartmentNumbers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [aptValue, setAptValue] = useState([]);
  const [filteredAptValues, setFilteredAptValues] = useState([]);

  const [errorFlag, setErrorFlag] = useState(false)
  const [errorMail, setErrorMail] = useState("")
  const [errorApt, setErrorApt] = useState("")
  const [errorPhone, setErrorPhone] = useState("")
  const [errorName, setErrorName] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [errorTenant, setErrorTenant] = useState("")
  const [buttonClickedX, setButtonClickedX] = useState(false);

  const closeFunction = () => {
    props.history.goBack()
  }

  useEffect(() => {
    console.log("APt values", JSON.stringify(aptValue, null, 2))
  }, [aptValue])

  const validateFe = () => {
    if (email === "") {
      setErrorMail("Email field is required")
      setErrorFlag(true)
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErrorMail("Invalid Email");
      setErrorFlag(true);
    }
    const parsedApt = parseInt(aptNo);
    if (aptNo === "" || isNaN(parsedApt)) {
      setErrorApt("Apartment Number is required")
      setErrorFlag(true)
    }
    const parsedTen = parseInt(ten);
    if (ten === "" || isNaN(parsedTen)) {
      setErrorTenant("Tenant Number is required");
      setErrorFlag(true);
    }
    if (name === "") {
      setErrorName("Name is required")
      setErrorFlag(true)
    } if (phone === "") {
      setErrorPhone("Phone number is required")
      setErrorFlag(true)
    } else if (!/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phone)) {
      setErrorPhone("Invalid phone number");
      setErrorFlag(true);
    }

  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    if (!CSVCheck) {
      validateFe()
    }
  }

  useEffect(() => {
    setErrorFlag(false)
    setButtonClickedX(false)
    setErrorMail("")
    setErrorName("")
    setErrorApt("")
    setErrorTenant("")
    setErrorMessage("")
    const updateUser = {
      name: name,
      apartment_number: parseInt(aptNo),
      email: email,
      tenants: parseInt(ten),
      phone_number: phone
    }

    console.log("updateUser", JSON.stringify(updateUser, null, 2))
    setUpdateUser(updateUser)
  }, [name, aptNo, email, phone, resBuild, number]);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return phoneNumber;

    let formattedNumber = "";

    if (match[1]) {
      formattedNumber += `(${match[1]})`;
    }

    if (match[2]) {
      formattedNumber += ` ${match[2]}`;
    }

    if (match[3]) {
      formattedNumber += `-${match[3]}`;
    }

    return formattedNumber;
  };

  const managerToken = localStorage.getItem("token")

  var aptValues = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/apartments/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }

  useEffect(() => {
    setAptNo("");
    axios(aptValues).then((res) => {
      console.log("apt")
      console.log(res.data)
      // const numbers = res.data.map((apt) => apt.number);
      // setApartmentNumbers(numbers);
      setAptValue(res.data);
    })
  }, [])

  useEffect(() => {
    aptValue.map((apt) => {
      if (apt.id == aptNo && apt.resident.length > 0) {
        setErrorMessage(`Apartment ${aptNo} already has a resident.`)
        setErrorFlag(true);
      }
    })
  }, [aptNo])

  useEffect(() => {
    aptValue.map((apt) => {
      if (apt.id == aptNo && apt.resident.length > 0) {
        setErrorMessage(`Apartment ${aptNo} already has a resident.`)
        setErrorFlag(true);
      }
    })
  }, [aptNo])

  const handleDropdownChange = (e) => {
    setAptNo(e.target.value);
    setShowDropdown(false);
  };

  var config = {
    method: "post",
    url: `https://kepah-24275.botics.co/api/v1/admin/residents/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
    data: updateUser
  };

  var configCSV = {
    method: "post",
    url: `https://kepah-24275.botics.co/api/v1/admin/bulk-create/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
    data: csvForm
  };

  useEffect(() => {
    if (buttonClickedX) {
      if (CSVCheck) {
        handleCSV()
      }
      if (errorFlag != true && !CSVCheck) {
        handleAddResident()
      }
    }
  }, [errorFlag, buttonClickedX])

  useEffect(() => {
    if (userCSV) {
      const formData = new FormData()
      formData.append("csv_file", userCSV)
      setCSVForm(formData)
      setErrorMessage("CSV added " + userCSV.name)
      setCSVCheck(true);
    } else {
      setCSVForm(null);
      setCSVCheck(false);
    }
  }, [userCSV])

  const handleCSV = () => {
    notifyLoading({ toastMessage: 'Loading', autoClose: 5000 })
    console.log(errorMessage)
    console.log(updateUser)
    axios(configCSV)
      .then((res) => {
        console.log("CSV RES")
        console.log(res)
        // if (res.status == 201) {
        //   props.hisory.goBack()
        // }
        notifySuccess({ toastMessage: 'Residents Added Successfully', autoClose: 5000 })
        setTimeout(() => {
          props.history.push("/listofresidents");
        }, 5000);
      })
      .catch((err) => {
        console.log(res.data)
        notifyError({ toastMessage: 'Error, Please try again!', autoClose: 5000 })
      })
  }

  const handleAddResident = () => {
    notifyLoading({ toastMessage: 'Loading', autoClose: 5000 })
    console.log(errorMessage)
    console.log(updateUser)
    axios(config)
      .then((res) => {
        notifySuccess({ toastMessage: 'Resident Added Successfully', autoClose: 5000 })
        setTimeout(() => {
          props.history.push("/listofresidents");
        }, 5000);
        
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.non_field_errors) {
          setErrorMessage(error.response.data.non_field_errors);
          notifyError({ toastMessage: 'Error, Please try again!', autoClose: 5000 })
          setErrorFlag(true)
        }

      });
  }

  return (
    <div
      className={props.popup ? "incident_back_popup" : "incident_back"}
    >
      <div className="side_nav">
        <Sidebar history={props.history} nav="ListofResidents" />
      </div>

      <div className="rent_main_div">
        <div className="add_new_resident_back">
          <BackButton closeFunction={closeFunction} />
          <div className="add_new_resident_background_color">
            <div className="add_new_resident_name_img_div">
              <h3 className="add_new_resident_text">Add New Resident</h3>
              <label htmlFor="upload-csv" className="upload_csv_text">
                Upload CSV file
              </label>
              <input
                type="file"
                id="upload-csv"
                style={{ display: "none" }}
                onChange={(event) => {
                  const selectedFile = event.target.files[0];
                  setUserCSV(selectedFile);
                  setCSVCheck(true);
                }}
              />
            </div>
            <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {errorMessage ? <p style={{ fontSize: "13px", paddingLeft: "5px", color: "red" }}>{errorMessage}</p> : ""}
            </div>
            <div className="input_fields_div">
              <div>
                <label className="phone_label">Name:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="text" onChange={(e) => setName(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorName ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorName}</p> : ""}
              </div>

              <div>
                <label className="phone_label">Apartment number:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">

                    <select
                      className="phone_input_field"
                      value={aptNo}
                      onChange={handleDropdownChange}
                    >
                      <option value="" disabled>Select An Apartment</option>
                      {/* Render the dropdown options based on the fetched data */}
                      {Object.keys(aptValue).map((id) => (
                        <option key={id} value={aptValue[id].id}
                          disabled={ aptValue[id]?.resident?.length > 0 ? true : false}
                
                        >
                          {aptValue[id].number ? aptValue[id].number : ""}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorApt ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorApt}</p> : ""}
              </div>

              <div>
                <label className="phone_label">Occupants:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="text" onChange={(e) => setTen(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorTenant ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorTenant}</p> : ""}
              </div>

              <div>
                <label className="phone_label">Email:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="text" onChange={(e) => setEmail(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorMail ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorMail}</p> : ""}
              </div>

              <div>
                <label className="phone_label">Phone:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="phone" value={phone} onChange={(e) => setPhone(formatPhoneNumber(e.target.value))} />
                  </div>
                </div>
              </div>
              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorPhone ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorPhone}</p> : ""}
              </div>

              <Form className="edit_resident_profile_save_button_div">
                <Button
                  className="edit_resident_profile_save_button"
                  variant="primary"
                  onClick={buttonClicked}
                >
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddNewResident;
