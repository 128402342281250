import React, { useState, useEffect } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Container, Col, Row, ScrollView } from "react-bootstrap";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import EditSvg from "../../../assets/edit.svg"
import getApi from "../../../config/ApiGet";

import searchImage from "../../../assets/Search.png"
import personImage from "../../../assets/profile.png"

const HistoryofthePayments = ({ ...props }) => {
  const [search, setSearch] = useState("");
  const [searchOnNameVar, setSearchOnNameVar] = useState("");
  const [userList, setUserList] = useState([]);
  const [updatedUserList, setUpdatedUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userListCheck, setUserListCheck] = useState(false);
  const [userListChanged, setUserListChanged] = useState(false);
  const [searchOn, setSearchOn] = useState(false)
  const [searchOnName, setSearchOnName] = useState(false)

  const [showResident, setShowResident] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [position, setSelectedUserPosition] = useState(null);

  const managerToken = localStorage.getItem("token")

  useEffect(() => {
  (async () => {
      const violationType = await getApi("https://kepah-24275.botics.co/api/v1/admin/payment/history/?page=1&page_size=20");
      // console.log("LeaseInfo", JSON.stringify(violationType, null, 2))
      setUserList(violationType.results);
      setUpdatedUserList(violationType.results)
  })();
  }, []);
  
  useEffect(() => {
    if (userList.length > 0 && userList !== null) {
      const filteredResults = userList.filter((userx) => {
        if (userx) {
          const nameMatch =
            userx.user.name && userx.user.name.toLowerCase().includes(search.toLowerCase());
          const createdOnMatch =
            userx.created_on && userx.created_on.includes(search);
  
          return nameMatch || createdOnMatch;
        }
        return false;
      });
      setFilteredUsers(filteredResults);
    }
  }, [search, userList]);

  useEffect(() => {
    if(search.length > 0) {
      setSearchOn(true)
    } else {
      setSearchOn(false)
    }
  }, [search])

  useEffect(() => {
    setUpdatedUserList(filteredUsers)
  }, [filteredUsers])

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString(); // Format as per the user's locale
    // Alternatively, you can use specific formatting options, e.g., date.toLocaleString('en-US')
  };

  return (
    <div
      onClick={(event) => {
      // console.log(event)
      const rect = event.currentTarget.getBoundingClientRect();
      const position2 = { x: rect.left, y: rect.top + rect.height };
      // console.log("position2")
      // console.log(position2)
      if (selectedResident && position && (position.x !== position2.x || position.y !== position2.y)) {
        setSelectedResident(null); // Close the resident info div
      }
    }}>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">History of Payments</span>
        </div>
      </div>

      <div className="criminal_input_div">
        <input
          type="text"
          className="search_input_list_of_managers"
          placeholder="Search by Name or Date"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="criminal_search_btn">
          <img src={searchImage} />
        </div>
        {/* <Form className="form_inline_criminal">
          <Button
            className="add_new_resident_btn"
            variant="primary"
            type="submit"
            onClick={() => props.addNewResident()}
          >
            ADD NEW RESIDENT
          </Button>
        </Form> */}
      </div>

      <div className="pt-4">
        <div className="list_of_apt_div">
          <div className="apt_list_scroll_history">
            <Container>
              <div className="infoRow">
                <div className="col_apt_name">
                  Name
                </div>
                <div className="col_apt_apt">
                  Amount Paid
                </div>
                <div className="col_apt_email">
                  Date
                </div>
                <div className="col_apt_status">
                  Transaction ID
                </div>
              </div>
              <div className="bottom_border_manager mt-1 mb-1" />
              {/* <Row>
                  <div className="bottom_border_manager" />
                </Row> */}
              {updatedUserList && updatedUserList.map(userx => (
                <div className="infoRow p-1 mt-2 row_alternate" key={userx.id}>
                  <div className="col_apt_name">
                    <Row>
                      <Col className="col-md-1">
                        <img
                          style={{ height: "32px", width: "32px", borderRadius: "64px" }}
                          src={personImage}
                        />
                      </Col>
                      <Col style={{ marginLeft: "23px", color: "#1d7def", fontSize: "14px", paddingTop: "6px" }}>
                        {userx.user.name? userx.user.name : ""}
                      </Col>
                    </Row>
                  </div>
                  <div onClick={(event) => console.log("Apt No clicked")} className="col_apt_apt" style={{ color: "#1d7def", fontSize: "14px", paddingTop: "6px" }}>
                    {userx.amount_paid}
                  </div>
                  <div className="col_apt_email" style={{ fontSize: "14px", paddingTop: "6px" }}>
                    {formatDate(userx.created_on)}
                  </div>
                  <div className="col_apt_status" style={{ fontSize: "14px", paddingTop: "6px" }}>
                    {userx.transaction_id}
                  </div>
                  
                </div>

              ))}
              {selectedResident && (
                <div>
                  <ResidentInfo
                    className=""
                    resident={selectedResident}
                    position={position}
                    onClose={() => setSelectedResident(null)} // Function to close ResidentInfo
                  />
                </div>
              )}
            </Container>
          </div>
        </div>
      </div>

    </div>
  );
};

export default HistoryofthePayments;