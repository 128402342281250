import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";
import BackButton from "../../../parts/BackButton/BackButton";
import defaultImage from "../../../assets/profile.png";
import Sidebar from "../../Sidebar/Sidebar";

const EditManager = ({ ...props }) => {
  const [id, setId] = useState("")
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [badgeID, setBadgeID] = useState("");
  const [photo, setPhoto] = useState("")
  const [photoCheck, setPhotoCheck] = useState(false);
  const [updateUser, setUpdateUser] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [photoForm, setPhotoForm] = useState(null);
  const [errorMessage, setErrorMessage] = useState("")
  const [buttonClickedX, setButtonClickedX] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false)
  const [errorMail, setErrorMail] = useState("")
  const [errorPhone, setErrorPhone] = useState("")
  const [errorName, setErrorName] = useState("")
  
  const closeFunction = () => {
    props.history.goBack();
  }

  const validateFe = () => {
    if ( email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErrorMail("Invalid Email");
      setErrorFlag(true);
    }
    if ( phone && !/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phone)) {
      setErrorPhone("Invalid phone number");
      setErrorFlag(true);
    }
    if (name.trim().split(' ').length === 1) {
      setErrorName("Please enter your full name");
      setErrorFlag(true);
    }
  }

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return phoneNumber;

    let formattedNumber = "";

    if (match[1]) {
      formattedNumber += `(${match[1]})`;
    }

    if (match[2]) {
      formattedNumber += ` ${match[2]}`;
    }

    if (match[3]) {
      formattedNumber += `-${match[3]}`;
    }

    return formattedNumber;
  };

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  useEffect(()=> {
    if(buttonClickedX) {
      if(errorFlag != true){
        manageInfoChangeAPICall()
      }
    }
  }, [errorFlag, buttonClickedX])

  useEffect(() => {
    if(userPhoto != null) {
      const formData = new FormData()
      formData.append("profile_photo", userPhoto, userPhoto.name)
      setPhotoForm(formData)
    } else {
      setPhotoForm(null);
    }
  }, [userPhoto])

  useEffect(() => {
    setErrorFlag(false)
    setButtonClickedX(false)
    setErrorMail("")
    setErrorPhone("")
    setErrorMessage("")
    setErrorName("")
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("badge_id", badgeID);
    formData.append("phone_number", phone);
    
    if(userPhoto) {
      formData.append("profile_picture", userPhoto);
      setPhotoCheck(true)
      setPhoto("")
    } else {
      formData.append("profile_picture", "");
    }
    
    setPhotoForm(formData);
  }, [userPhoto, name, email, badgeID, phone]);

  useEffect(() => {
    const managerInfoChangeString = localStorage.getItem("managerInfoChange");
    const managerInfoChange = JSON.parse(managerInfoChangeString);

    if (managerInfoChange) {
      setId(managerInfoChange.id)
      setName(managerInfoChange.name)
      setEmail(managerInfoChange.email)
      setPhone(managerInfoChange.phone_number)
      setBadgeID(managerInfoChange.badge_id)
      setPhoto(managerInfoChange.profile_picture)
    }
    console.log(managerInfoChange.profile_picture)

  }, []);

  const managerToken = localStorage.getItem("token")

  var config = {
    method: "put",
    url: `https://kepah-24275.botics.co/api/v1/admin/managers/${id}/`,
    headers: {
      Authorization: `token ${managerToken}`
    },
    data: photoForm
  };

  const manageInfoChangeAPICall = () => {
    setErrorMessage("Loading")
    console.log(config.data.get("name"))
    console.log(photoForm.get("profile_picture"))
    console.log(photoForm.get("email"))
    axios(config).then((res) => {
      if(res.status == 200){
        props.history.goBack()
      }
    })
      .catch((error)=> {
        setErrorMessage("Error, Please try again!")
        console.log(error)
      })
  }

  return (
    <div
      className={props.popup ? "incident_back_popup" : "incident_back"}
      >
        <div className="side_nav">
          <Sidebar history={props.history} nav="ListOfManagers" />
        </div>

        <div className="rent_main_div">
        <div className="edit_manager_back">
      <BackButton closeFunction={closeFunction} />
      <div className="edit_manager_background_color">
        <div className="edit_manager_name_img_div">
          <h3 className="edit_manager_text">Edit Manager</h3>
          {photoCheck && userPhoto && (
            <img
              className="manager_profile_img"
              src={URL.createObjectURL(userPhoto)}
              alt="Uploaded Profile"
            />
          )}
          {photo && (
            <img
            className="manager_profile_img"
            src={photo}
            alt="Uploaded Profile"
            />
          )}
          {!photoCheck && !photo && (
            <img
              className="manager_profile_img"
              src={defaultImage}
              alt="Default Profile"
            />
          )}
          <h4 className="manager_name_text">{name}</h4>
          <label htmlFor="upload-photo" className="upload_photo_text">
            Upload new photo
          </label>
          <input
            type="file"
            id="upload-photo"
            style={{ display: "none" }}
            onChange={(event) => {
              const selectedFile = event.target.files[0];
              setUserPhoto(selectedFile);
            }}
          />
        </div>
        <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
         {errorMessage? <p style={{fontSize: "13px", paddingLeft: "5px", color: "red"}}>{errorMessage}</p>: ""}
        </div>
        <div className="input_fields_div">
          <div className="email_input_div">
            <label className="email_label">Name:</label>
            <div className="email_input_field_div">
              <input name="name" className="email_input_field" type="text" value={name} onChange={(event) => setName(event.target.value)}/>
            </div>
            {errorName?<p className="errorMessage">{errorName}</p> : ""}
          </div>

          <div className="email_input_div">
            <label className="email_label">Email:</label>
            <div className="email_input_field_div">
              <input name="email" className="email_input_field" type="email" value={email} onChange={(event) => setEmail(event.target.value)}/>
            </div>
            {errorMail?<p className="errorMessage">{errorMail}</p> : ""}
          </div>

          <div className="email_input_div">
            <label className="email_label">Badge ID:</label>
            <div className="email_input_field_div">
              <input name="badgeId" className="email_input_field" type="text" value={badgeID} onChange={(event) => setBadgeID(event.target.value)}/>
            </div>
          </div>
          <div>
            <label className="phone_label">Phone:</label>
            <div className="phone_input_div">
              <div className="phone_input_field_div">
                <input name="phone" className="phone_input_field" type="phone" value={phone} onChange={(event) => setPhone(formatPhoneNumber(event.target.value))}/>
                <img src={require("../../../assets/edit.png").default} />
              </div>
            </div>
          </div>
          <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
            {errorPhone?<p style={{fontSize: "13px", color: "red", paddingTop: "4px"}}>{errorPhone}</p> : ""}
          </div>
          <Form className="edit_profile_save_button_div">
            <Button
              className="edit_profile_save_button"
              variant="primary"
              onClick={buttonClicked}
            >
              Save
            </Button>
          </Form>
        </div>
      </div>
    </div>
        </div>
      </div>
  );
};

export default EditManager;
