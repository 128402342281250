import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import { Container, Col, Row, Button } from "react-bootstrap";

import DoneSvg from "../../../assets/done.svg"
import UnDoneSvg from "../../../assets/undone.svg"

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackButton from "../../../parts/BackButton/BackButton";

import Cyclist from "../../../assets/cyclist.jpg";

const RequestX = ({ ...props }) => {
  const [maintenancePerson, setMaintenancePerson] = useState([])
  const [maintenanceUserRequestX, setMaintenanceUserRequestX] = useState([])
  const [updatedRequest, setUpdatedRequest] = useState([])

  const [maintenanceId, setMaintenanceId] = useState("")
  const [maintenancePersonId, setMaintenancePersonId] = useState("")
  const [requestType, setRequestType] = useState("")
  const [requestTypeX, setRequestTypeX] = useState("")
  const [details, setDetails] = useState("")
  const [asignedTo, setAsignedTo] = useState([])
  const [asignedToNow, setAsignedToNow] = useState([])
  const [responseMessage, setResponseMessage] = useState("")

  const [createdAt, setCreatedAt] = useState()
  const [resolvedAt, setResolvedAt] = useState()

  const maintenanceUserRequest = JSON.parse(localStorage.getItem("maintenanceRequestUser"));
  const showProf = JSON.parse(localStorage.getItem("showProfile"));

  const [maintenanceImages, setMaintenanceImages] = useState(null)

  const closeFunction = () => {
    props.history.goBack();
  }

  const notify = () => toast.success('Successfully Added!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notifyError = () => toast.error('Error Happened!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyLoading = () => toast.info('Loading', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  useEffect(() => {
    console.log("maintenanceUserRequest", JSON.stringify(maintenanceUserRequest, null, 2))
    setMaintenanceUserRequestX(maintenanceUserRequest)
  }, [])

  useEffect(() => {

    setDetails(maintenanceUserRequestX.details);
    setCreatedAt(maintenanceUserRequestX.created_at);
    setAsignedToNow(maintenanceUserRequest.asignee)
    if (maintenanceUserRequestX.resolved != false) {
      setResolvedAt(maintenanceUserRequestX.resolved_at)
    }
    setRequestType(maintenanceUserRequestX.request_type)
    setMaintenanceId(maintenanceUserRequestX.id)
    setMaintenanceImages(maintenanceUserRequestX.maintenance_request_media)

  }, [maintenanceUserRequestX])

  useEffect(() => {
    console.log(asignedToNow)
    asignedToNow.map(res => {
      if (!asignedTo.includes(res.id) && res.id !== null) {
        const newAssignees = [...asignedTo, res.id];
        setAsignedTo(newAssignees);
      }
    })
  }, [asignedToNow])

  useEffect(() => {
    console.log(asignedTo)
  }, [asignedTo])

  const managerToken = localStorage.getItem("token");

  var config = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/maintenance/persons/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  var config_edit = {
    method: "put",
    url: `https://kepah-24275.botics.co/api/v1/admin/maintenance/request/${maintenanceId}/`,
    headers: {
      Authorization: `token ${managerToken}`
    },
    data: updatedRequest
  };

  const handleUpdatedRequest = () => {
    notifyLoading()
    console.log(config_edit.url)
    console.log(updatedRequest)
    axios(config_edit).then(e => {
      notify()
      setTimeout(() => {
        props.history.goBack();
      }, 5000);
    })
    .catch(error => {
      console.log(error.response.data)
    })
  }

  useEffect(() => {
    setResponseMessage("")
    
      const updateRequestX = {
        user: maintenanceUserRequest.user.id,
        request_type: requestType,
        details: details,
        asignee: asignedTo,
        request_status: "assigned"
      }
      setUpdatedRequest(updateRequestX)

  }, [requestType, details, asignedTo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data
        const res = await axios(config);
        const userListData = res.data;
        console.log(res.data)

        setMaintenancePerson(userListData)

      } catch (error) {
        // Handle any errors here
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString(); // Format as per the user's locale
    // Alternatively, you can use specific formatting options, e.g., date.toLocaleString('en-US')
  };

  const handleDropdownChange = (e) => {
    const selectedId = e.target.selectedOptions[0].getAttribute("data-id");
    if (!asignedTo.includes(parseInt(selectedId)) && selectedId !== null) {
      const newAssignees = [...asignedTo, parseInt(selectedId)];
      setAsignedTo(newAssignees);
    }
  };

  const dropdownReqType = (e) => {
    const selectedId = e.target.selectedOptions[0].getAttribute("data");
    console.log(selectedId)
    setRequestType(selectedId)
  }

  if (showProf) {
    return (
      <div className="requests_main_page_background">
        <div className="again">
          <Container>
            <Row>
              User Type: {maintenanceUserRequest.user.user_type}
            </Row>
            <Row>
              Name: {maintenanceUserRequest.user.name}
            </Row>
            <Row>
              Apartment No: {maintenanceUserRequest.user.apartment_number != null ? maintenanceUserRequest.user.apartment_number : ""}
            </Row>
            <Row>
              Email: {maintenanceUserRequest.user.email}
            </Row>
            <Row>
              Phone Number: {maintenanceUserRequest.user.phone_number}
            </Row>
            <Row>
              Criminal Status: {maintenanceUserRequest.user.criminal_status ? maintenanceUserRequest.user.criminal_status : "No Criminal Record"}
            </Row>
          </Container>

        </div>
      </div>
    );
  } else {
    return (
      <div className="requests_main_page_background">
        <BackButton closeFunction={closeFunction} />
        <div className="">
          <div className="request_no_status_div">
            <p className="request_no_text">Request #{maintenanceUserRequest.id}</p>
            <div className="request_status">
              <span className="status_text">Status: </span>
              <span className="status_completed">{maintenanceUserRequest.resolved ? "Completed" : "Not Completed"}</span>
              <div className="checked_img_div">
                {maintenanceUserRequest.resolved ? <img style={{ width: "12px", height: "12px" }} src={DoneSvg} /> : <img style={{ width: "12px", height: "12px" }} src={UnDoneSvg} />}
              </div>
            </div>
          </div>
          <div className="imagesInMaintenance">
            <Row>
              {maintenanceImages && maintenanceImages.map(req => (
                <Col>
                  <Zoom>
                    <img
                      alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                      src={req.media_file}
                      width="200"
                      height="100"
                      style={{ padding: 2}}
                    />
                  </Zoom>
                </Col>
              ))}
            </Row>
            
          </div>

          <div className="request_fields_main_div">
            <div className="request_input_div">
              <label className="phone_label">Request:</label>
              <div className="phone_input_div">
                <div className="phone_input_field_div">
                  <select
                    className="phone_input_field"
                    value={requestType}
                    onChange={dropdownReqType}
                  >
                    {requestType == "general" ? (
                      <>
                        <option >Select An Option</option>
                        <option value="general" data="general">General</option>
                        <option value="emergency" data="emergency">Emergency</option>
                      </>
                    ) : (
                      <>
                        <option >Select An Option</option>
                        <option value="emergency" data="emergency">Emergency</option>
                        <option value="general" data="general">General</option>
                      </>
                    )}
                  </select>
                </div>
              </div>
            </div>

            <div className="request_input_div">
              <label className="details_label">Details:</label>
              <div className="details_input_div">
                <div className="details_input_field_div">
                  <textarea
                    className="details_input_field"
                    value={details}
                    type="textarea"
                    rows={5}
                    onChange={(e) => setDetails(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div>
              <label className="phone_label">Date Requested:</label>
              <div className="company_dropdown">
                <div className="phone_input_field_div">
                  <p>{formatDate(createdAt)}</p>
                  <img
                    className="manager-calendar"
                    src={
                      require("../../../assets/manager-calendar.png").default
                    }
                  />
                </div>
              </div>
            </div>

            {maintenanceUserRequest.resolved ? (<div>
              <label className="phone_label">Resolved At:</label>
              <div className="company_dropdown">
                <div className="phone_input_field_div">
                  <p>{formatDate(resolvedAt)}</p>
                  <img
                    className="manager-calendar"
                    src={
                      require("../../../assets/manager-calendar.png").default
                    }
                  />
                </div>
              </div>
            </div>) : ""}

            <div>
              <label className="phone_label">Assign to:</label>
              <div className="company_dropdown">
                <div className="phone_input_field_div">
                  <select className="phone_input_field" onChange={handleDropdownChange}>
                    <option>Select An Option</option>
                    {maintenancePerson.map((man) => (

                      <option key={man.id} value={man.name} data-id={man.id}>
                        {man.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div>
              <label className="phone_label">Assigned to:</label>
              <div >
                <div className="">
                  <ol className="" >
                    {asignedToNow && asignedToNow.map((man) => (
                      <li key={man.id} value={man.name} className="assignee_guy">
                        {man.name}
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>

            <div className="">
              <Row>

                <Col className="col-md-6 d-flex justify-content-end pt-5">
                  <Button onClick={handleUpdatedRequest}>Update</Button>
                </Col>
              </Row>

            </div>

          </div>
          <div className="border_bottom_requests" />
          <ToastContainer />
        </div>

      </div>
    );
  }
};

export default RequestX;
