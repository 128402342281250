import React, { useState, useEffect } from "react";
import { format } from 'date-fns';
import imgFile from "../../../assets/profile.png"
import { Container, Row, Col } from "react-bootstrap";
import imgFile2 from "../../../assets/message2.svg"
import formatTimestamp from "../Timebox/TimeAPI";

export default function Messagebox2 ({...props}) {

    return(
        <div style={{ height: "auto", background: "#EAEAEA", borderRadius: "5px", width: "100%"}}>
            <Container>
                <Row>
                    <div className="datePart3" style={{ fontSize: ".9em", display: "flex", justifyContent:"flex-start", color: "#1D7DEF", alignItems: "flex-end"  }}>
                        {formatTimestamp(props.timestamp)}
                    </div>
                    <div className="messagePart3">
                        <Row>
                            <div className="fullCov">
                            <div className="messageStyle2">
                                <div className="messageDet2">
                                    <p className="textMessageClass">
                                        {props.message}
                                   </p>
                                </div>
                            </div>
                            </div>
                        </Row>
                    </div>
                    <div className="imagePart3">
                        <img
                            style={{ height: "48px", width: "48px", borderRadius: "64px" }}
                            src={props?.imageUrl ? props.imageUrl : imgFile}
                            className="profile_img_main_page"
                        />
                    </div>
                </Row>
            </Container>
        </div>
    )
}