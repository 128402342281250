import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import Button from "react-bootstrap/Button";
import { Container, Col, Row, Form } from "react-bootstrap";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import EditSvg from "../../../assets/edit.svg"
import DoneSvg from "../../../assets/done.svg"
import UnDoneSvg from "../../../assets/undone.svg"

import downloadIcon from "../../../assets/incident-download-icon.png"
import downloadDash from "../../../assets/incident-download-dash.png"
import messageImage from "../../../assets/message.svg"

import { Popover, OverlayTrigger } from "react-bootstrap";

const ListOfMaintenance = ({ ...props }) => {
  const [searchOnAptVar, setSearchOnAptVar] = useState("");
  const [searchOnNameVar, setSearchOnNameVar] = useState("");
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [updatedUserList, setUpdatedUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userListCheck, setUserListCheck] = useState(false);
  const [userListChanged, setUserListChanged] = useState(false); 
  const [searchOnApt, setSearchOnApt] = useState(false)
  const [searchOnName, setSearchOnName] = useState(false)

  const [showResident, setShowResident] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [position, setSelectedUserPosition] = useState(null);
  const [userUpdated, setUserUpdated] = useState(false);
  const [maintenanceRequestId, setMaintenanceRequestId] = useState("")

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);
  const [modalInfo, setModalInfo] = useState(null)

  const showResidentInfo = ({ ...props }) => {
    // Get the position of the clicked apartment number
    const rect = event.currentTarget.getBoundingClientRect();
    const position = { x: rect.left, y: rect.top + rect.height };
    setShowResident(true);
    setSelectedResident(resident);
    setSelectedUserPosition(position);
  };

  const managerToken = localStorage.getItem("token")

  var config = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/maintenance/request/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  var configDownload = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/maintenance/request/download/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };
  var configDownload2

  useEffect(() => {
    configDownload2= {
      method: "get",
      url: `https://kepah-24275.botics.co/api/v1/admin/maintenance/request/${maintenanceRequestId}/download/`,
      headers: {
        Authorization: `token ${managerToken}`,
        "Content-Type": "application/json",
      },
    };
  }, [maintenanceRequestId])

  const downloadClick2 = (e) => {
    e.preventDefault();
    axios(configDownload2)
      .then((res) => {
        // Check if the response contains data
        if (res.data) {
          // Prepare the file data and create a Blob object
          const blob = new Blob([res.data], { type: "application/octet-stream" });
  
          // Create a temporary URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
  
          // Create a link element
          const link = document.createElement("a");
          link.href = blobUrl;
  
          // Set the download attribute and suggested file name
          link.download = "maintenance_single_request.csv"; // Replace with the desired file name
  
          // Simulate a click on the link to trigger the download
          link.click();
  
          // Clean up the created URL
          URL.revokeObjectURL(blobUrl);
        }
      })
      .catch((error) => {
        console.error("Error downloading:", error);
      });
  };
  
  const downloadClick = (e) => {
    e.preventDefault();
    axios(configDownload)
      .then((res) => {
        // Check if the response contains data
        if (res.data) {
          // Prepare the file data and create a Blob object
          const blob = new Blob([res.data], { type: "application/octet-stream" });
  
          // Create a temporary URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
  
          // Create a link element
          const link = document.createElement("a");
          link.href = blobUrl;
  
          // Set the download attribute and suggested file name
          link.download = "maintenance_list.csv"; // Replace with the desired file name
  
          // Simulate a click on the link to trigger the download
          link.click();
  
          // Clean up the created URL
          URL.revokeObjectURL(blobUrl);
        }
      })
      .catch((error) => {
        console.error("Error downloading:", error);
      });
  };
  
  
  const popover = (
    // <>
    <Popover id="popover-basic borderVehicleModal">
      <Container className="vehicleInfo">
        <div className="p-3">
          {/* <Row className="pb-3">
            {modalInfo.media_file ? (
              <img
                style={{ height: "200px", width: "100%", borderRadius: "5px" }}
                src={modalInfo.media_file}
              />
            ) : ""}
          </Row> */}
          <Row>
            Requested By: {modalInfo && modalInfo.user ? modalInfo.user.name : "Name not available"}
          </Row>
          <Row>
            Email: {modalInfo && modalInfo.user ? modalInfo.user.email : "Email not available"}
          </Row>
          <Row>
            Phone: {modalInfo && modalInfo.user ? modalInfo.user.phone_number : "Phone not available"}
          </Row>
          <Row>
            Details: {modalInfo && modalInfo.details ? modalInfo.details : "Details not available"}
          </Row>
          <Row>
            Status: {modalInfo && modalInfo.request_status === "assigned" ? "Assigned" : "Not Assigned"}
          </Row>
          <Row>
            Request Type: {modalInfo && modalInfo.request_type === "general" ? "General" : "Imergency"}
          </Row>
          <Row>
            Resolved: {modalInfo && modalInfo.request_type === true ? "Resolved" : "Not Resolved"}
          </Row>
        </div>
      </Container>
    </Popover>
  );

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        popoverOpen &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setPopoverOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [popoverOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data
        const res = await axios(config);
        const userListData = res.data;
        console.log(res.data)

        // const x = userListData.filter(user => user.image !== null)
        
        // console.log("With Image maintenance req", JSON.stringify(x, null, 2))

        // Update the state with the updated user list containing vehicle info
        
        setUpdatedUserList(userListData);
        
        setUserListCheck(true);
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div onClick={(event) => {
      const rect = event.currentTarget.getBoundingClientRect();
      const position2 = { x: rect.left, y: rect.top + rect.height };
      console.log("position2")
      console.log(position2)
      if (selectedResident && position && (position.x !== position2.x || position.y !== position2.y)) {
        setSelectedResident(null); // Close the resident info div
      }
    }}>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">Maintenance Request ({updatedUserList.length})</span>

        </div>
        <div className="download_create_btns">
          <Form className="download_btn_form">
            <Button
              className="download_btn_incident"
              variant="primary"
              type="submit"
            >
              <div className="download_icon_div">
                <img
                  src={downloadIcon}
                />
                <img
                  src={downloadDash}
                />
              </div>
              <span onClick={e => downloadClick(e)} className="download_text">DOWNLOAD</span>
            </Button>
          </Form>
        </div>
      </div>
      <div 
        className="pt-4">
        <div className="list_of_residents_div">
            <div>
              <Container>
                <div className="infoRow">
                  <div style={{paddingLeft: "20px"}} className="col_maintenance_name">
                    Name
                  </div>
                  <div className="col_maintenance_apt">
                    Apartment
                  </div>
                  <div className="col_maintenance_tenants">
                    Status
                  </div>
                  <div className="col_maintenance_email">
                    Phone
                  </div>
                  <div className="col_maintenance_edit">

                  </div>
                </div>
                <div className="bottom_border_manager mt-1 mb-1" />
                {/* <Row>
                  <div className="bottom_border_manager" />
                </Row> */}
                { updatedUserList ? updatedUserList.map(user => (
                  <div className="infoRow p-1 mt-2 row_alternate" key={user.id}>
                    <div className="col_maintenance_name">
                      <Row>
                      <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={popover}
                  rootClose
                  onToggle={() => {
                    setPopoverOpen(!popoverOpen)
                    setModalInfo(user)
                  }}
                  ref={popoverRef}
                >
                        <Col style={{ marginLeft: "15px", color: "#1d7def", fontSize: "14px", paddingTop: "6px", cursor: "pointer"}}>
                          {user.user.name}
                        </Col>
                        </OverlayTrigger>
                      </Row>
                    </div>
                    <div className="col_maintenance_apt" style={{ color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                      {user.user.apartment_number?  user.user.apartment_number : ""}
                    </div>
                    <div className="col_maintenance_tenants" style={{ fontSize: "14px", paddingTop: "6px"}} >
                        {user.request_status && user.request_status === "assigned" ? (
                            <Row>
                                <Col className="col-md-1">
                                    <img style={{width: "16px", height:"16px"}} src={DoneSvg} />
                                </Col>
                                <Col>
                                    Assigned
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col className="col-md-1">
                                    <img style={{width: "16px", height:"16px"}} src={UnDoneSvg} />
                                </Col>
                                <Col>
                                    Waiting
                                </Col>
                            </Row>
                        )}
                    </div>
                    <div className="col_maintenance_email" style={{ fontSize: "14px", paddingTop: "6px"}}>
                      {user.user.phone_number ? user.user.phone_number : ""}
                    </div>
                    <div className="col_maintenance_edit" style={{display: "flex", alignItems: "center", justifyContent: "flex-end", cursor: "pointer"}} onClick={() => {
                      localStorage.setItem("maintenanceRequestUser", JSON.stringify(user));
                      console.log("maintenanceRequestUser", JSON.stringify(user, null, 2));
                      props.history.push("maintenancerequest/edit")
                    }}>
                      <img src={EditSvg} style={{ height: "16px", width: "16px" }}/>
                    </div>
                    <div className="col_maintenance_edit" style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginLeft: "7px" , cursor: "pointer"}} onClick={() => {
                      localStorage.setItem("maintenanceRequestUser", JSON.stringify(user));
                      props.history.push("maintenancerequest/edit")
                    }}>
                      <img src={messageImage} style={{ height: "16px", width: "16px" }}/>
                    </div>
                    <div className="col_maintenance_edit" style={{display: "flex", alignItems: "center", justifyContent: "flex-end" }} 
                      
                      onClick={e => {
                        e.preventDefault()
                        setMaintenanceRequestId(user.id)
                        downloadClick2(e)
                      }}
                    >
                      <div className="download_icon_div" style={{ padding: 0, margin: 0, cursor: "pointer" }}>
                        <img
                          src={downloadIcon}
                        />
                        <img
                          src={downloadDash}
                        />
                      </div>
                    </div>
                    
                  </div>
                  
                )): <></> }
                {selectedResident && (
                  <div>
                    <ResidentInfo
                      className=""
                      resident={selectedResident}
                      position={position}
                      onClose={() => setSelectedResident(null)} // Function to close ResidentInfo
                    />
                  </div>
                )}
              </Container>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfMaintenance;
