import React, { useState } from "react";
import "./style.css";
import Sidebar from "../Sidebar/Sidebar";
import ListOfVehiclesPage from "./ListOfVehiclesPage/ListOfVehiclesPage";
import AddVehicle from "./AddVehicle/AddVehicle";

const ListOfVehicles = ({ ...props }) => {
  const [addVehicle, setAddVehicle] = useState(false);

  const newVehicle = () => {
    setAddVehicle(true)
  }

  const closeNewVehicle = () => {
    setAddVehicle(false)
  }

  if (!addVehicle) {
    return (
      <div className="incident_back">
        <div className="side_nav">
          <Sidebar history={props.history} nav="List Of Vehicles" />
        </div>
        <div className="incident_action_main_div">
          <div className="incident_action_main_page">
            <ListOfVehiclesPage history={props.history} newVehicle={newVehicle} />
          </div>
        </div>
      </div>
    );
  } else {
    return (

      <AddVehicle history={props.history} closeNewVehicle={closeNewVehicle} />

    );
  }

};

export default ListOfVehicles;
