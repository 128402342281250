import React, { useState } from "react";
import "./style.css";
import Sidebar from "../Sidebar/Sidebar";
import ListofTenantsPage from "./ListofTenantsPage/ListofTenantsPage";
import EditTenantProfile from "./EditTenantProfile/EditTenantProfile";
import AddNewTenant from "./AddNewTenant/AddNewTenant";
const RentRoll = ({ ...props }) => {
  const [addTenant, setAddTenant] = useState(false);
  const [editTenant, setEditTenant] = useState(false);

  const tenant = () => {
    setEditTenant(true);
  };

  const add_tenant = () => {
    setAddTenant(true);
  }

  const closeAddNewTenant = () => {
    setAddTenant(false);
  };

  const closeEditTenant = () => {
    setEditTenant(false);
  }

  if (!addTenant && !editTenant) {
    return (
      <div
        // className="incident_back"
        className={props.popup ? "incident_back_popup" : "incident_back"}
      >
        <div className="side_nav">
          <Sidebar history={props.history} nav="ListofTenants" />
        </div>
        <div className="rent_main_div">
          <div className="incident_action_main_page">
            <ListofTenantsPage history={props.history} setTenant={tenant} setAddTenant={add_tenant} />
          </div>
        </div>
      </div>
    )
  } else if (editTenant) {
    return (

      <EditTenantProfile history={props.history} closeEditTenant={closeEditTenant} />

    )
  } else if (addTenant) {
    return (
      <AddNewTenant history={props.history} closeAddNewTenant={closeAddNewTenant} />
    )
  }
};

export default RentRoll;
