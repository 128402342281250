import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import { Col, Row } from "react-bootstrap";

const ListOfOfficers = ({ ...props }) => {
  const [officerList, setOfficerList] = useState([]);
  const [token, setToken] = useState(null);
  const [vehicles, setVehicles] = useState([])

  const managerToken = localStorage.getItem("token")

  var vehicleApi = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/vehicle/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  }


  useEffect(() => {
    axios(vehicleApi).then((res) => {
      console.log(res.data)
      setVehicles(res.data);
    })
  }, [])

  const tagInformationArray = [
    {
      name: "Alice Johnson",
      tagNumber: "#1234567"
    },
    {
      name: "Bob Smith",
      tagNumber: "#9876543"
    },
    {
      name: "Eve Davis",
      tagNumber: "#5678901"
    },
    {
      name: "John Doe",
      tagNumber: "#3456789"
    },
    {
      name: "Jane Smith",
      tagNumber: "#7654321"
    },
    {
      name: "Michael Johnson",
      tagNumber: "#8765432"
    },
    {
      name: "Emily Davis",
      tagNumber: "#2345678"
    },
    {
      name: "Robert Wilson",
      tagNumber: "#8901234"
    },
    {
      name: "Sarah Brown",
      tagNumber: "#4567890"
    },
    {
      name: "Daniel Martinez",
      tagNumber: "#6789012"
    }
  ];
  

  return (
    <div className="list_of_officers_main trespass_sc">
      <div className="list_of_officers_manage_div">
        <div>
          <span className="list_of_officers_text">List of Vehicles ({vehicles.length}) </span>
        </div>
        <div
          onClick={() => {
            props.history.push("Listofvehicles");
          }}
        >
          <span className="manage_officers_text">Manage Vehicles</span>
        </div>
      </div>
      <div className="license_scroll_div">
        <div className="officer_license_number  pt-2">
          <Row>
            <Col className="col-md-6">
              Name
            </Col>
            <Col className="col-md-6">
              Tag Number
            </Col>
          </Row>
        </div>
        <div className="bottom_border" />

        <div className="list_of_vehicle_sc">
          {vehicles.map((val) => {
            return (
              <div key={val.id} className="p-1 row_alternate"
              >
                <Row>
                  <Col className="col-md-6">
                    {val.user.name}
                  </Col>
                  <Col className="col-md-6">
                    {val.tag_number}
                  </Col>
                </Row>
              </div>
            );
          })}
          
        </div>
      </div>
    </div>
  );
};

export default ListOfOfficers;
