import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";
import personImg from "../../../assets/profile.png"

import BackButton from "../../../parts/BackButton/BackButton";
import Sidebar from "../../Sidebar/Sidebar";

const EditTenantProfile = ({ ...props }) => {
  const [id, setId] = useState("")
  const [name, setName] = useState("");
  const [aptNo, setAptNo] = useState("");
  const [photo, setPhoto] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [userPhoto, setUserPhoto] = useState(null);
  const [photoForm, setPhotoForm] = useState(null);
  const [photoCheck, setPhotoCheck] = useState(false);


  const [errorFlag, setErrorFlag] = useState(false)
  const [errorMail, setErrorMail] = useState("")
  const [errorPhone, setErrorPhone] = useState("")
  const [errorApt, setErrorApt] = useState("")
  const [errorBadgeId, setErrorBadgeId] = useState("")
  const [errorName, setErrorName] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [errorTenant, setErrorTenant] = useState("")
  const [buttonClickedX, setButtonClickedX] = useState(false);

  const closeFunction = () => {
    props.history.goBack()
  }

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return phoneNumber;

    let formattedNumber = "";

    if (match[1]) {
      formattedNumber += `(${match[1]})`;
    }

    if (match[2]) {
      formattedNumber += ` ${match[2]}`;
    }

    if (match[3]) {
      formattedNumber += `-${match[3]}`;
    }

    return formattedNumber;
  };

  const validateFe = () => {
    const parsedApt = parseInt(aptNo);
    if (aptNo && isNaN(parsedApt)) {
      setErrorApt("Apartment should be a Number")
      setErrorFlag(true)
    }
    if (!/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phone)) {
      setErrorPhone("Invalid phone number");
      setErrorFlag(true);
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErrorMail("Invalid Email");
      setErrorFlag(true);
    }
  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  useEffect(() => {
    if (buttonClickedX) {
      if (errorFlag != true) {
        manageInfoChangeAPICall()
      }
    }
  }, [errorFlag, buttonClickedX])

  useEffect(() => {
    const managerInfoChangeString = localStorage.getItem("tenantInfoChange");
    const managerInfoChange = JSON.parse(managerInfoChangeString);

    if (managerInfoChange) {
      setId(managerInfoChange.id)
      setName(managerInfoChange.name)
      setAptNo(managerInfoChange.apartment_number)
      setPhoto(managerInfoChange.profile_picture)
      setEmail(managerInfoChange.email)
      setPhone(managerInfoChange.phone_number)
    }

  }, []);

  useEffect(() => {
    if (userPhoto != null) {
      const formData = new FormData()
      formData.append("profile_photo", userPhoto, userPhoto.name)
      setPhotoForm(formData)
    } else {
      setPhotoForm(null);
    }
  }, [userPhoto])

  useEffect(() => {
    setErrorFlag(false)
    setButtonClickedX(false)
    setErrorApt("")
    setErrorMessage("")
    const formData = new FormData();
    formData.append("name", name);
    formData.append("apartment_number", parseInt(aptNo));
    formData.append("email", email);
    formData.append("phone", phone);

    if (userPhoto) {
      formData.append("profile_picture", userPhoto);
      setPhotoCheck(true)
      setPhoto("")
    }
    setPhotoForm(formData);
  }, [userPhoto, name, aptNo, email, phone]);


  const managerToken = localStorage.getItem("token")

  var config = {
    method: "patch",
    url: `https://kepah-24275.botics.co/api/v1/admin/tenants/${id}/`,
    headers: {
      Authorization: `token ${managerToken}`
    },
    data: photoForm
  };

  const manageInfoChangeAPICall = () => {
    setErrorMessage("Loading")
    console.log(config.data.get("name"))
    console.log(photoForm.get("profile_picture"))
    axios(config)
      .then(res => {
        if (res.status == 200) {
          props.history.goBack()
        }
      })
      .catch((err) => {
        setErrorMessage("Error, Please try again!")
      })
  }

  return (
    <div
      // className="incident_back"
      className={props.popup ? "incident_back_popup" : "incident_back"}
    >
      <div className="side_nav">
        <Sidebar history={props.history} nav="ListofTenants" />
      </div>
      <div className="rent_main_div">
        <div className="edit_manager_back">
          <BackButton closeFunction={closeFunction} />
          <div className="edit_manager_background_color">
            <div className="edit_manager_name_img_div">
              <h3 className="edit_manager_text">Edit/Update Occupants</h3>
              {photoCheck && userPhoto && (
                <img
                  className="manager_profile_img"
                  src={URL.createObjectURL(userPhoto)}
                  alt="Uploaded Profile"
                />
              )}
              {photo && (
                <img
                  className="manager_profile_img"
                  src={photo}
                  alt="Uploaded Profile"
                />
              )}
              {!photoCheck && !photo && (
                <img
                  className="manager_profile_img"
                  src={personImg}
                  alt="Default Profile"
                />
              )}
              <h4 className="manager_name_text">{name}</h4>
              <label htmlFor="upload-photo" className="upload_photo_text">
                Upload new photo
              </label>
              <input
                type="file"
                id="upload-photo"
                style={{ display: "none" }}
                onChange={(event) => {
                  const selectedFile = event.target.files[0];
                  setUserPhoto(selectedFile);
                  setPhotoCheck(true);
                }}
              />
            </div>
            <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {errorMessage ? <p style={{ fontSize: "13px", paddingLeft: "5px", color: "red" }}>{errorMessage}</p> : ""}
            </div>
            <div className="input_fields_div">
              <div>
                <label className="phone_label">Name:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="text" value={name} onChange={(event) => setName(event.target.value)} />
                    <img src={require("../../../assets/edit.png").default} />
                  </div>
                </div>
              </div>

              <div>
                <label className="phone_label">Apartment number:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="text" value={aptNo} onChange={(event) => setAptNo(event.target.value)} />
                    <img src={require("../../../assets/edit.png").default} />
                  </div>
                </div>
              </div>

              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorApt ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorApt}</p> : ""}
              </div>

              <div>
                <label className="phone_label">Email:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorMail ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorMail}</p> : ""}
              </div>

              <div>
                <label className="phone_label">Phone:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="phone" value={phone} onChange={(e) => setPhone(formatPhoneNumber(e.target.value))} />
                  </div>
                </div>
              </div>
              <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {errorPhone ? <p style={{ fontSize: "13px", color: "red", paddingTop: "4px" }}>{errorPhone}</p> : ""}
              </div>

              <div>
                <label className="phone_label">Date Added:</label>
                <div className="phone_input_div">
                  <div className="phone_input_field_div">
                    <input className="phone_input_field" type="date" onChange={(event) => console.log(event.target.value)} />
                  </div>
                </div>
              </div>

              <Form className="edit_resident_profile_save_button_div">
                <Button
                  className="edit_resident_profile_save_button"
                  variant="primary"
                  onClick={buttonClicked}
                >
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default EditTenantProfile;
