import axios from "axios";
import getManagerToken from "./ManagerToken";

export default function getApi(url) {
    const token = getManagerToken()
    var config = {
        method: "get",
        url: url,
        headers: {
            Authorization: `token ${token}`,
            "Content-Type": "application/json",
        },
    };

    // Return the promise from the axios call
    return axios(config)
        .then((res) => {
            // Return the response data from the successful request
            return res.data;
        })
        .catch((err) => {
            // Throw an error or handle it as needed
            console.log("Error from API get", err.response.data);
            throw err;
        });
}
