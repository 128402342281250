import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HorizontalBarChart from "../HorizontalBarChart/HorizontalBarChart";
import DatePicker from "react-datepicker";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import axios from "axios";
import calenderImage from "../../../assets/calendar.png";
import getApi from "../../../config/ApiGet";

const VehicleActionLogs = ({
  statusLabels,
  status,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [vehicleActionLogs, setVehicleActionLogs] = useState([])
  const [filtered, setFiltered] = useState([])
  const [violationType, setViolationType] = useState([])

  const sampleData = [
    {
      "id": 1,
      "vehicle_number": "vehicle-1",
      "tag": null,
      "status": "damaged",
      "image": null,
      "reporter": 5,
      "created": "2023-08-19T21:29:42.950964Z",
      "updated": "2023-08-19T21:29:43.117187Z"
    },
    {
      "id": 2,
      "vehicle_number": "vehicle-2",
      "tag": null,
      "status": "no_action_taken",
      "image": null,
      "reporter": 8,
      "created": "2023-08-20T15:45:12.950964Z",
      "updated": "2023-08-20T15:45:12.117187Z"
    },
    {
      "id": 3,
      "vehicle_number": "vehicle-3",
      "tag": null,
      "status": "illegally_parked",
      "image": null,
      "reporter": 12,
      "created": "2023-08-21T09:10:25.950964Z",
      "updated": "2023-08-21T09:10:26.117187Z"
    },
    {
      "id": 4,
      "vehicle_number": "vehicle-4",
      "tag": null,
      "status": "warning",
      "image": null,
      "reporter": 15,
      "created": "2023-08-22T12:35:47.950964Z",
      "updated": "2023-08-22T12:35:48.117187Z"
    },
    {
      "id": 5,
      "vehicle_number": "vehicle-5",
      "tag": null,
      "status": "booted",
      "image": null,
      "reporter": 10,
      "created": "2023-08-23T18:50:58.950964Z",
      "updated": "2023-08-23T18:50:59.117187Z"
    },
    {
      "id": 6,
      "vehicle_number": "vehicle-6",
      "tag": null,
      "status": "remove_car",
      "image": null,
      "reporter": 7,
      "created": "2023-08-24T09:15:30.950964Z",
      "updated": "2023-08-24T09:15:31.117187Z"
    },
    {
      "id": 7,
      "vehicle_number": "vehicle-7",
      "tag": null,
      "status": "damaged",
      "image": null,
      "reporter": 18,
      "created": "2023-08-25T14:20:22.950964Z",
      "updated": "2023-08-25T14:20:23.117187Z"
    },
    {
      "id": 8,
      "vehicle_number": "vehicle-8",
      "tag": null,
      "status": "illegally_parked",
      "image": null,
      "reporter": 9,
      "created": "2023-08-26T20:25:15.950964Z",
      "updated": "2023-08-26T20:25:16.117187Z"
    },
    {
      "id": 9,
      "vehicle_number": "vehicle-9",
      "tag": null,
      "status": "warning",
      "image": null,
      "reporter": 11,
      "created": "2023-08-27T11:30:08.950964Z",
      "updated": "2023-08-27T11:30:09.117187Z"
    },
    {
      "id": 10,
      "vehicle_number": "vehicle-10",
      "tag": null,
      "status": "no_action_taken",
      "image": null,
      "reporter": 14,
      "created": "2023-08-28T16:35:01.950964Z",
      "updated": "2023-08-28T16:35:02.117187Z"
    },
    {
      "id": 31,
      "vehicle_number": "vehicle-31",
      "tag": null,
      "status": "towed",
      "image": null,
      "reporter": 22,
      "created": "2023-08-15T08:45:14.950964Z",
      "updated": "2023-08-15T08:45:15.117187Z"
    },
    {
      "id": 32,
      "vehicle_number": "vehicle-32",
      "tag": null,
      "status": "booted",
      "image": null,
      "reporter": 10,
      "created": "2023-08-16T13:50:07.950964Z",
      "updated": "2023-08-16T13:50:08.117187Z"
    },
    {
      "id": 33,
      "vehicle_number": "vehicle-33",
      "tag": null,
      "status": "warning",
      "image": null,
      "reporter": 15,
      "created": "2023-08-17T18:55:00.950964Z",
      "updated": "2023-08-17T18:55:01.117187Z"
    },
    {
      "id": 34,
      "vehicle_number": "vehicle-34",
      "tag": null,
      "status": "no_action_taken",
      "image": null,
      "reporter": 23,
      "created": "2023-08-18T00:00:53.950964Z",
      "updated": "2023-08-18T00:00:54.117187Z"
    },
    {
      "id": 35,
      "vehicle_number": "vehicle-35",
      "tag": null,
      "status": "remove_car",
      "image": null,
      "reporter": 12,
      "created": "2023-08-19T05:05:46.950964Z",
      "updated": "2023-08-19T05:05:47.117187Z"
    },
    {
      "id": 36,
      "vehicle_number": "vehicle-36",
      "tag": null,
      "status": "damaged",
      "image": null,
      "reporter": 20,
      "created": "2023-08-20T10:10:39.950964Z",
      "updated": "2023-08-20T10:10:40.117187Z"
    },
    {
      "id": 37,
      "vehicle_number": "vehicle-37",
      "tag": null,
      "status": "illegally_parked",
      "image": null,
      "reporter": 27,
      "created": "2023-08-21T15:15:32.950964Z",
      "updated": "2023-08-21T15:15:33.117187Z"
    },
    {
      "id": 38,
      "vehicle_number": "vehicle-38",
      "tag": null,
      "status": "booted",
      "image": null,
      "reporter": 13,
      "created": "2023-08-22T20:20:25.950964Z",
      "updated": "2023-08-22T20:20:26.117187Z"
    },
    {
      "id": 39,
      "vehicle_number": "vehicle-39",
      "tag": null,
      "status": "warning",
      "image": null,
      "reporter": 25,
      "created": "2023-08-23T01:25:18.950964Z",
      "updated": "2023-08-23T01:25:19.117187Z"
    },
    {
      "id": 40,
      "vehicle_number": "vehicle-40",
      "tag": null,
      "status": "no_action_taken",
      "image": null,
      "reporter": 30,
      "created": "2023-08-24T06:30:11.950964Z",
      "updated": "2023-08-24T06:30:12.117187Z"
    },
    {
      "id": 41,
      "vehicle_number": "vehicle-41",
      "tag": null,
      "status": "towed",
      "image": null,
      "reporter": 18,
      "created": "2023-08-25T11:35:04.950964Z",
      "updated": "2023-08-25T11:35:05.117187Z"
    },
    {
      "id": 42,
      "vehicle_number": "vehicle-42",
      "tag": null,
      "status": "booted",
      "image": null,
      "reporter": 7,
      "created": "2023-08-26T16:39:57.950964Z",
      "updated": "2023-08-26T16:39:58.117187Z"
    },
    {
      "id": 43,
      "vehicle_number": "vehicle-43",
      "tag": null,
      "status": "warning",
      "image": null,
      "reporter": 29,
      "created": "2023-08-27T21:44:50.950964Z",
      "updated": "2023-08-27T21:44:51.117187Z"
    },
    {
      "id": 44,
      "vehicle_number": "vehicle-44",
      "tag": null,
      "status": "no_action_taken",
      "image": null,
      "reporter": 16,
      "created": "2023-08-28T02:49:43.950964Z",
      "updated": "2023-08-28T02:49:44.117187Z"
    },
    {
      "id": 45,
      "vehicle_number": "vehicle-45",
      "tag": null,
      "status": "remove_car",
      "image": null,
      "reporter": 24,
      "created": "2023-08-29T07:54:36.950964Z",
      "updated": "2023-08-29T07:54:37.117187Z"
    },
    {
      "id": 46,
      "vehicle_number": "vehicle-46",
      "tag": null,
      "status": "damaged",
      "image": null,
      "reporter": 14,
      "created": "2023-08-30T12:59:29.950964Z",
      "updated": "2023-08-30T12:59:30.117187Z"
    },
    {
      "id": 47,
      "vehicle_number": "vehicle-47",
      "tag": null,
      "status": "illegally_parked",
      "image": null,
      "reporter": 19,
      "created": "2023-08-31T18:04:22.950964Z",
      "updated": "2023-08-31T18:04:23.117187Z"
    },
    {
      "id": 48,
      "vehicle_number": "vehicle-48",
      "tag": null,
      "status": "booted",
      "image": null,
      "reporter": 28,
      "created": "2023-08-01T23:09:15.950964Z",
      "updated": "2023-08-01T23:09:16.117187Z"
    },
    {
      "id": 49,
      "vehicle_number": "vehicle-49",
      "tag": null,
      "status": "warning",
      "image": null,
      "reporter": 11,
      "created": "2023-08-02T04:14:08.950964Z",
      "updated": "2023-08-02T04:14:09.117187Z"
    },
    {
      "id": 50,
      "vehicle_number": "vehicle-50",
      "tag": null,
      "status": "no_action_taken",
      "image": null,
      "reporter": 21,
      "created": "2023-08-03T09:19:01.950964Z",
      "updated": "2023-08-03T09:19:02.117187Z"
    },
  ];

  const managerToken = localStorage.getItem("token")

  var config = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/vehicle/action-logs/`,
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    axios(config)
    .then(function (response) {
      let actionLogs = response.data;
      console.log(response, "reeessponse");
      setVehicleActionLogs(actionLogs);
      setFiltered(actionLogs);
    })
    .catch(function (error) {
      console.log(error, "It's a error!!!");
    });
  }, [])

  const [statusCounts, setStatusCounts] = useState({}); // Initialize as empty object
  const [statusMapping, setStatusMapping] = useState(); // Initialize as empty object

  useEffect(() => {
    resetStatusCounts();
    (async () => {
      const violationType = await getApi("https://kepah-24275.botics.co/api/v1/vehicle/action-log-statuses/");
      console.log("Violation Types", JSON.stringify(violationType, null, 2))
      setViolationType(violationType);
    })();
  }, []);

  useEffect(() => {
    console.log(violationType, "violationType");
    if(violationType.length > 0) {
      const newStatusCounts = violationType.reduce((acc, item) => {
        acc[item.label] = 0; // Initialize counts for each incident type
        return acc;
      }, {});
      const newStatusMaping = violationType.reduce((acc, item) => {
        acc[item.value] = item.label; // Initialize counts for each incident type
        return acc;
      }, {});
      console.log("Status Maping", newStatusMaping)
      setStatusMapping(newStatusMaping);
      setStatusCounts(newStatusCounts); // Update statusCounts state
    }
  }, [violationType]); // This effect runs only once on initial component mount

  const resetStatusCounts = () => {
    if(violationType?.length > 0) {
      const newStatusCounts = violationType.reduce((acc, item) => {
        acc[item.label] = 0; // Initialize counts for each incident type
        return acc;
      }, {});
      setStatusCounts(newStatusCounts); // Update statusCounts state
    }
  }

  useEffect(() => {
    const updatedStatusCounts = { ...statusCounts }; // Copy the statusCounts object

    filtered.forEach(item => {
      const status = item.status;
      const translatedStatus = statusMapping[status];
      
      if (translatedStatus) {
        updatedStatusCounts[translatedStatus] = (updatedStatusCounts[translatedStatus] || 0) + 1;
      }
    });
    
    setStatusCounts(updatedStatusCounts); // Update the state with the new counts
  }, [filtered]);

  useEffect(() => {
    
    const filteredStatusData = vehicleActionLogs.filter((item) => {
      const plainDate = new Date(item.created).toISOString().split('T')[0];
      if (!startDate && !endDate) {
        return true;
      }
  
      if (startDate && endDate) {
        return plainDate >= startDate && plainDate <= endDate;
      }
  
      if (startDate) {
        return plainDate >= startDate;
      }
  
      if (endDate) {
        return plainDate <= endDate;
      }
  
    });
    console.log("Filtered Status", filteredStatusData)
    setFiltered(filteredStatusData);
    
  }, [startDate, endDate, vehicleActionLogs]);


  useEffect(() => {
    console.log("Final statusCounts:", statusCounts);


    console.log(filtered)

    console.log(startDate)
    console.log(endDate)
  }, [startDate, endDate])

  return (
    <div>
      <div className="action_div_main_page">
        <div>
          <span className="action_text_main_page">Reported Vehicle Data</span>
        </div>
      </div>

      <div className="vehicle_action_from_to_div">
        <div className="from_vehicle_div">
          <span>From: </span>
          <div className="action_input_div">
            <input className="action_input" value={startDate} />

            <DatePicker
              value={dateFrom}
              // selected={startDate}
              onChange={(date) => {
                setStartDate(
                  format(date, 'yyyy-MM-dd') // Format the date with 'yyyy-MM-dd'
                );
                setDateFrom(date);
              }}
              customInput={
                <div className="action_search_btn">
                  <img
                    onClick={() => setStartDate()}
                    src={calenderImage}
                  />
                </div>
              }
            />
          </div>
        </div>
        <div className="to_vehicle_div">
          <span>To: </span>
          <div className="action_input_div">
            <input value={endDate} type="text" className="action_input" />
            <DatePicker
              value={dateTo}
              // selected={dateTo}
              onChange={(date) => {
                setEndDate(
                  format(date, 'yyyy-MM-dd') // Format the date with 'yyyy-MM-dd'
                );
                setDateTo(date);
              }}
              customInput={
                <div className="action_search_btn">
                  <img
                    onClick={() => setEndDate()}
                    src={calenderImage}
                  />
                </div>
              }
            />

          </div>
        </div>
      </div>

      <div>

        <HorizontalBarChart
          statusLabels={Object.keys(statusCounts)}
          status={Object.values(statusCounts)}
        />
      </div>
    </div>
  );
};

export default VehicleActionLogs;
