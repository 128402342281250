import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import ListoftheAppartments from "./ListoftheAppartments/ListoftheApartments";
import HistoryofthePayments from "./HistoryofthePayments/HistoryofthePayments";

import "./style.css";
import ListOfResidents from "../ListOfResidents/ListOfResidentsPage/ListOfResidentsPage";
import ListOfApartments from "./ListoftheAppartments/ListoftheApartments";
import EditResident from "../ListOfResidents/EditResident/EditResident";
import AddNewApartment from "./AddNewApartment/AddNewApartment";
import EditApartment from "./EditApartment/EditApartment";

const UpdateLeaseInformation = ({ ...props }) => {
  const [addApartment, setAddApartment] = useState(false);
  const [editApartment, setEditApartment] = useState(false);

  const apartment = () => {
    setEditApartment(true)
  }
  
  
  const add_apartment = () => {
    setAddApartment(true);
  }
  
  const closeAddNewApartment = () => {
    setAddApartment(false);
  };
  
  const closeEditApartment = () => {
    setEditApartment(false);
  }

  if(!addApartment && !editApartment) {
    return (
      <div className="main-officer_schedule">
        <div className="side_nav">
          <Sidebar history={props.history} nav="Update Lease Information" />
        </div>
  
        <div className="update_lease_information">
          <div className="list_of_appartments_lease">
            <ListOfApartments setApartment={apartment} addNewApartment = {add_apartment}/>
          </div>
          <div className="history_of_payments">
            <HistoryofthePayments />
          </div>
        </div>
      </div>
    );
  } else if (editApartment) {
    return (
      <div className="main-officer_schedule">
        <div className="side_nav">
          <Sidebar history={props.history} nav="Update Lease Information" />
        </div>
        <div className="rent_main_div">
          <EditApartment closeEditApartment={closeEditApartment} />
        </div>
      </div>
    );
  } else if (addApartment) {
    return (
      <div className="main-officer_schedule">
        <div className="side_nav">
          <Sidebar history={props.history} nav="Update Lease Information" />
        </div>
        <div className="rent_main_div">
          <AddNewApartment closeAddNewApartment={closeAddNewApartment} />
        </div>
      </div>
    );
  }
};

export default UpdateLeaseInformation;
