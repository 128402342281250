import { format } from 'date-fns';

export default function formatTimestamp(timestamp) {
    const messageDate = new Date(timestamp); // Parse the timestamp into a Date object
    const currentDate = new Date();

    const isToday =
        currentDate.getDate() === messageDate.getDate() &&
        currentDate.getMonth() === messageDate.getMonth() &&
        currentDate.getFullYear() === messageDate.getFullYear();
    if (isToday) {
        return format(messageDate, 'h:mm a'); // Format time for today
    } else {
        return format(messageDate, 'd MMM'); // Format date for other days
    }
}