import React, { useEffect, useState } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

import logoImage from "../../assets/forestCove.png";
import vectorLogo from "../../assets/vector.png"
import kepahImage from "../../assets/kepah.png"

const Welcome = ({ ...props }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMail, setErrorMail] = useState("")
  const [errorPass, setErrorPass] = useState("")
  const [errorFlag, setErrorFlag] = useState(false)
  const [buttonClickedX, setButtonClickedX] = useState(false);

  const validateFe = () => {
    if(email === "") {
      setErrorMail("Email field is required")
      setErrorFlag(true)
    }
    if(password === "") {
      setErrorPass("Password field is required")
      setErrorFlag(true)
    }
  }

  const buttonClicked = () => {
    setButtonClickedX(true)
    validateFe()
  }

  useEffect(()=> {
    if(buttonClickedX) {
      if(errorFlag != true){
        login()
      }
    }
  }, [errorFlag, buttonClickedX])

  useEffect(() => {
    setErrorMessage("")
    setErrorMail("")
    setErrorPass("")
    setErrorFlag(false)
    setButtonClickedX(false)
  }, [password, email])

  const login = () => {
    console.log(errorMail)
    console.log(errorPass)
    console.log(errorFlag)
    setErrorMessage("Loading")
    // var data = JSON.stringify({
      // username: "zubair_12",
      // password: "zub123456",
    // });

    var data = JSON.stringify({
      username: email,
      password: password,
    });

    var config = {
      method: "post",
      url: "https://kepah-24275.botics.co/api/v1/admin/login/",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
    .then(function(response) {
      localStorage.setItem("token", response.data.token)
      localStorage.setItem("userInfo", JSON.stringify(response.data));
      console.log("userInfo", JSON.stringify(response.data, null, 2))
      localStorage.setItem("id", response.data.user.id)
      localStorage.setItem("buildingno", 1)
      props.history.push("dashboard")
    })
    .catch(function(error) {
      setErrorMessage("Email or Password didn't match")
    });
  };


  return (
    <div className="main">
      <div className="img_div">
        <img
          className="forest_img"
          src={logoImage}
        />
      </div>
      <div className="white_box">
        <div className="all_texts">
          <h5 className="welcome_text">Welcome</h5>

          {/* <p className="security_text">Security Company Name</p> */}
          <p className="email">{email ? email : ""}</p>
        </div>

        <div className="errorMsgLogin" style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
         {errorMessage? <p style={{fontSize: "13px", paddingLeft: "5px", color: "red"}}>{errorMessage}</p>: ""}
        </div>

        <div>
          <div>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="label_create_password">Email:</Form.Label>
              <div className="email_div">
                <Form.Control
                  className="input_create_email_login"
                  type="email"
                  placeholder="Enter Email"
                  onChange={(event) => {
                    setEmail(event.target.value)
                    console.log(email);
                  }}
                />
              </div>
              {errorMail?<p className="errorMessage">{errorMail}</p> : ""}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="label_create_password">
                Password:
              </Form.Label>
              <div className="input_div_login">
                <Form.Control
                  className="input_create_password_login"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                />
                <div className="eye_div">
                  <span className="dot" />
                  <img 
                    src={vectorLogo} 
                    onClick={()=> {
                      setShowPassword(!showPassword);
                      console.log(showPassword);
                    }}
                  />
                </div>
              </div>
              {errorPass? <p className="errorMessage">{errorPass}</p> : ""}
            </Form.Group>
            
            <Button
              onClick={buttonClicked}
              className="save_btn"
              variant="primary"
            >
              LOG IN
            </Button>
          </div>
        </div>
      </div>
      <div>
        <img
          className="kepah_img"
          src={kepahImage}
        />
      </div>
    </div>
  );
};

export default Welcome;
