import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

import profPhoto from "../../../assets/profile.png"

import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditResident = ({ ...props }) => {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [userPhoto, setUserPhoto] = useState(null)
  const [photoCheck, setPhotoCheck] = useState(false)
  const [formInfo, setFormInfo] = useState(null)
  const [policeId, setPoliceId] = useState(parseInt(8))
  const [photo, setPhoto] = useState("")

  const notify = () => toast.success('Successfully updated the informations!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyError = () => toast.error('Error Happened!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyErrorRequired = () => toast.error('Fields marked * are required!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyLoading = () => toast.info('Loading', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return phoneNumber;

    let formattedNumber = "";

    if (match[1]) {
      formattedNumber += `(${match[1]})`;
    }

    if (match[2]) {
      formattedNumber += ` ${match[2]}`;
    }

    if (match[3]) {
      formattedNumber += `-${match[3]}`;
    }

    return formattedNumber;
  };

  const managerToken = localStorage.getItem("token")

  var config = {
    method: "put",
    url: `https://kepah-24275.botics.co/api/v1/admin/police-liaison/${policeId}/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
    data: formInfo,
    id: 1
  };

  var configGet = {
    method: "get",
    url: `https://kepah-24275.botics.co/api/v1/admin/police-liaison/${policeId}/`,
    headers: {
      Authorization: `token ${managerToken}`,
    },
  };

  useEffect(() => {
    axios(configGet).then((res) => {
      console.log(res.data)
      setName(res.data.name)
      setEmail(res.data.email)
      setPhone(res.data.phone_number)
      setPhoto(res.data.profile_picture)
    })
    .catch((err)=> {
      notifyError()
      console.log(err.response.data)
    })
  }, [])

  useEffect(() => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone_number", phone);
    formData.append("residence_building", parseInt(1))
    
    if(userPhoto) {
      formData.append("profile_picture", userPhoto);
    }
    
    setFormInfo(formData)
  }, [name, email, phone, userPhoto])

  const manageAPICall = () => {
    notifyLoading()
    axios(config).then((res) => {
      if(res.status == 200){
        notify()
      }
    })
    .catch((err)=> {
      notifyError()
      console.log(err.response.data)
    })
  }



  return (
    <div className="edit_manager_back">
      <div className="pt-4 pointBlank">
        <div className="edit_manager_name_img_div">
          <h3 className="edit_manager_text pb-2">Police Contact Information</h3>
          {photoCheck && userPhoto && (
            <img
              className="manager_profile_img"
              src={URL.createObjectURL(userPhoto)}
              alt="Uploaded Profile"
            />
          )}
          {!photoCheck && !photo && (
            <img
              className="manager_profile_img"
              src={profPhoto}
              alt="Default Profile"
            />
          )}
          {photo && !photoCheck && (
            <img
              className="manager_profile_img"
              src={photo}
              alt="Default Profile"
            />
          )}
          <label htmlFor="upload-photo" className="upload_photo_text pt-4">
            Upload new photo
          </label>
          <input
            type="file"
            id="upload-photo"
            style={{ display: "none" }}
            onChange={(event) => {
              const selectedFile = event.target.files[0];
              setUserPhoto(selectedFile);
              setPhotoCheck(true);
            }}
          />
        </div>
        <div className="input_fields_div">

          <div>
            <label className="phone_label">Name:</label>
            <div className="phone_input_div">
              <div className="phone_input_field_div">
                <input value={name} className="phone_input_field" onChange={(e)=> setName(e.target.value)} type="text" />
                <img src={require("../../../assets/edit.png").default} />
              </div>
            </div>
          </div>

          <div>
            <label className="phone_label">Phone Number:</label>
            <div className="phone_input_div">
              <div className="phone_input_field_div">
                <input className="phone_input_field" value={phone} onChange={(e)=> setPhone(formatPhoneNumber(e.target.value))} type="text" />
                <img src={require("../../../assets/edit.png").default} />
              </div>
            </div>
          </div>

          <div>
            <label className="phone_label">Email:</label>
            <div className="phone_input_div">
              <div className="phone_input_field_div">
                <input value={email} className="phone_input_field" onChange={(e)=> setEmail(e.target.value)} type="text" />
                <img src={require("../../../assets/edit.png").default} />
              </div>
            </div>
          </div>

          <Form className="edit_resident_profile_save_button_div">
            <Button
              className="edit_resident_profile_save_button"
              variant="primary"
              onClick={manageAPICall}
            >
              Save
            </Button>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditResident;
