import React, { useState } from "react";
import "./style.css";
import Sidebar from "../Sidebar/Sidebar";
import ListOfResidentsPage from "./ListOfResidentsPage/ListOfResidentsPage";
import EditResident from "./EditResident/EditResident";
import AddNewResident from "./AddNewResident/AddNewResident";

const ResidentManagement = ({ ...props }) => {

  const [addResident, setAddResident] = useState(false);
  const [editResident, setEditResident] = useState(false);

  const resident = () => {
    setEditResident(true);
  };

  const add_resident = () => {
    setAddResident(true);
  }

  const closeAddNewResident = () => {
    setAddResident(false);
  };
  
  const closeEditResident = () => {
    setEditResident(false);
  }

  if(!addResident && !editResident) {
    return (
      <div
      className={props.popup ? "incident_back_popup" : "incident_back"}
      >
        <div className="side_nav">
          <Sidebar history={props.history} nav="ListofResidents" />
        </div>

        <div className="rent_main_div">
          <div className="incident_action_main_page">
            <ListOfResidentsPage history={props.history} setResident={resident} addNewResident={add_resident}/>
          </div>
        </div>
      </div>
    )
  } else if(editResident) {
    return(

          <EditResident history={props.history} closeEditResident={closeEditResident} />
    )
  } else if(addResident) {
    return(

          <AddNewResident history={props.history} closeAddNewResident={closeAddNewResident} />

    )
  }

//   return (
//     <div
//       // className="incident_back"
//       className={props.popup ? "incident_back_popup" : "incident_back"}
//     >
//       <div className="side_nav">
//         <Sidebar history={props.history} nav="ListofResidents" />
//       </div>
//       <div className="rent_main_div">
//         {addResident === false ? (
//           <div className="incident_action_main_page">
//             <ListOfResidentsPage setResident={resident} />
//           </div>
//         ) : (
//           <AddNewResident closeAddNewResident={closeAddNewResident} />
//         )}
//       </div>
//     </div>
//   );
};

export default ResidentManagement;
