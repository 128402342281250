import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";

const CriminalTrespassFrag = ({ ...props }) => {
  const [officerList, setOfficerList] = useState([]);
  const [token, setToken] = useState(null);
  const [criminalTrespass, setCriminalTrespass] = useState([])

  const statusArray = [
    {
      name: "Alice Johnson",
      status: "active"
    },
    {
      name: "Bob Smith",
      status: "inactive"
    },
    {
      name: "Eve Davis",
      status: "active"
    },
    {
      name: "John Doe",
      status: "inactive"
    },
    {
      name: "Jane Smith",
      status: "active"
    },
    {
      name: "Michael Johnson",
      status: "inactive"
    },
    {
      name: "Emily Davis",
      status: "active"
    },
    {
      name: "Robert Wilson",
      status: "inactive"
    },
    {
      name: "Sarah Brown",
      status: "active"
    },
    {
      name: "Daniel Martinez",
      status: "inactive"
    },
    {
      name: "Michael Johnson",
      status: "inactive"
    },
    {
      name: "Emily Davis",
      status: "active"
    },
    {
      name: "Robert Wilson",
      status: "inactive"
    },
    {
      name: "Sarah Brown",
      status: "active"
    },
    {
      name: "Daniel Martinez",
      status: "inactive"
    }
  ];

  useEffect(() => {
    const managerToken = localStorage.getItem("token")
    var config = {
      method: "get",
      url: `https://kepah-24275.botics.co/api/v1/admin/criminal-trespass/`,
      headers: {
        Authorization: `token ${managerToken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let criminalTrespass = response.data;
        console.log(response.data);
        setCriminalTrespass(criminalTrespass);
      })
      .catch(function (error) {
        console.log(error, "It's a error!!!");
      });
  }, [])
  
  

  return (
    <div className="list_of_officers_main trespass_sc">
      <div className="list_of_officers_manage_div">
        <div>
          <span className="list_of_officers_text">Criminal Trespass List ({criminalTrespass.length})</span>
        </div>
        <div
          onClick={() => {
            props.history.push("criminal");
          }}
        >
          <span className="manage_officers_text">Manage Trespassers</span>
        </div>
      </div>
      <div className="">
      <Container className="containerMaintenance pt-2">
          <Row className="rowMaintenance pl-2">
            <Col className="col-md-6 colMaintenance"> 
              Name
            </Col>
            <Col className="col-md-6"> 
              Status
            </Col>
            <div className="bottom_border_manager mt-1 mb-1" />
          </Row>
          <div className="list_of_maintenance_off">
            {criminalTrespass.map((val) => (
              <Row className="p-1 rowMaintenance row_alternate" id={val.id}>
                <Col className="col-md-6 rowMaintenance"> 
                  {val.user.name ? val.user.name : "-"}
                </Col>
                <Col className="col-md-6"> 
                  {val.status === "active" ? "Active" : "Not Active"}
                </Col>
              </Row>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CriminalTrespassFrag;
