import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { format } from 'date-fns';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Col, Row, useAccordionButton } from "react-bootstrap";
import BackButton from "../../../parts/BackButton/BackButton";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "../../Sidebar/Sidebar";

const MakeASchedule = ({ ...props }) => {
  const [value, setChange] = useState("");
  const [endingDate, setEndingValue] = useState("");
  const [chooseDate, setChooseDate] = useState(true);
  const [chooseTime, setChooseTime] = useState(false);
  const [choosePost, setChoosePost] = useState(false);
  const [increment, setIncrement] = useState(0);
  const [to_hrs, setTo_hrs] = useState(0);
  const [button, setButton] = useState("");
  const [incrementTo, setIncrementTo] = useState(0);
  const [to_hrsTo, setTo_hrsTo] = useState(0);
  const [buttonTo, setButtonTo] = useState("");

  const [chooseEndDate, setChooseEndDate] = useState(false);
  const [chooseOfficer, setChooseOfficer] = useState(false)

  const [user, setUser] = useState(null)
  const [selectedOfficer, setSelectedOfficer] = useState("");
  const [selectedDate, setSelectedDate] = useState()
  const [timeStart, setTimeStart] = useState("")
  const [timeEnd, setTimeEnd] = useState("")
  const [scheduleData, setScheduleData] = useState(null)

  const [selectedDateTime, setSelectedDateTime] = useState(null)
  const [selectedDateTime2, setSelectedDateTime2] = useState(null)


  const [errorState, setErrorState] = useState(false)

  useEffect(() => {
    const managerInfoChangeString = localStorage.getItem("officerId");
    const managerInfoChange = JSON.parse(managerInfoChangeString);
    setSelectedOfficer(managerInfoChange)
  }, []);

  const closeFunction = () => {
    localStorage.setItem("officerId", null);
    props.history.goBack()
  }

  const notify = () => toast.success('Successfully Added!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notifyError = () => toast.error('Error Happened!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notifyErrorRequired = () => toast.error('Fields marked * are required!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notifyLoading = () => toast.info('Loading', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const validateTime = () => {
    console.log(timeStart, timeEnd)
    if (timeStart === "" || timeEnd === "") {
      setErrorState(true);
      notifyErrorRequired()
    } else {
      selectOfficer()
    }
  }

  const validateOfficer = () => {
    if (selectedOfficer === "") {
      setErrorState(true);
    } else {
      addToSchedule()
    }
  }

  function handleTimeChange1(e) {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, '');

    // Format the input as HH:MM
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2);
    }

    // Update the state for timeStart
    setTimeStart(inputValue);
  }

  function handleTimeChange2(e) {
    let inputValue = e.target.value;

    // Remove any non-digit characters from the input
    inputValue = inputValue.replace(/\D/g, '');

    // Format the input as HH:MM
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2);
    }

    // Update the state for timeEnd
    setTimeEnd(inputValue);
  }

  const clickChoose = (e) => {
    setChooseEndDate(false);
    setChooseTime(true);
    setChoosePost(false);
    setChooseDate(false);
    setChange(e);
    // setEndingValue(e);
  };

  const selectNew = () => {
    setChooseDate(true);
    setChooseEndDate(false);
    setChooseTime(false);
    setChoosePost(false);
    setChooseOfficer(false)
    console.log("Clicked!", chooseDate, chooseEndDate, choosePost, chooseTime)
  };

  const selectOfficer = () => {
    setChooseOfficer(true)
    setChooseTime(false);
    setChoosePost(false);
    setChooseDate(false);
  }

  const managerToken = localStorage.getItem("token")

  const handleDropdownChange = (e) => {
    console.log(e.target.value)
    setSelectedOfficer(e.target.value);
  };

  useEffect(() => {
    console.log("OfficerSelected", selectedOfficer)
  }, [selectedOfficer])


  // useEffect(() => {
  //   console.log("State values after selectNew:", chooseDate, chooseEndDate, choosePost, chooseTime, chooseOfficer);
  // }, [chooseDate, chooseEndDate, choosePost, chooseTime, chooseOfficer]);

  const openTime = (e) => {
    setChooseTime(true);
    setChooseEndDate(false);
    setChoosePost(false);
    setChooseDate(false);
    setEndingValue(e);
    // setChange(e);
  };

  const openPost = () => {
    setChooseTime(false);
    setChooseDate(false);

    setChoosePost(true);
  };

  useEffect(() => {
    console.log("Date", selectedDate)
  }, [selectedDate])

  useEffect(() => {
    setErrorState(false)
    const [hours, minutes] = timeStart.split(':');
    const [hours2, minutes2] = timeEnd.split(':');
    console.log('hours:', hours);
    console.log('minutes:', minutes);
    console.log('hours2:', hours2);
    console.log('minutes2:', minutes2);

    if (selectedDate && hours && minutes && hours2 && minutes2) {
      console.log("selectedDate", selectedDate.toISOString())
      const electedDateTime = new Date(selectedDate.toISOString());
      const electedDateTime2 = new Date(selectedDate.toISOString());

      electedDateTime.setUTCHours(hours, minutes);
      electedDateTime2.setUTCHours(hours2, minutes2);

      setSelectedDateTime(electedDateTime)
      setSelectedDateTime2(electedDateTime2)
      // console.log("With Time", selectedDateTime.toISOString(), selectedDateTime2.toISOString())

    }
  }, [timeStart, timeEnd, selectedDate])

  useEffect(() => {
    if (selectedDateTime !== null && selectedDateTime2 !== null) {
      const postReq = {
        start_time: selectedDateTime.toISOString(),
        end_time: selectedDateTime2.toISOString(),
        user: parseInt(selectedOfficer),
        residence_building: 1
      }
      console.log(postReq)
      setScheduleData(postReq)
    }

  }, [selectedDateTime, selectedDateTime2, selectedOfficer])

  const addToSchedule = () => {
    var config = {
      method: "post",
      url: "https://kepah-24275.botics.co/api/v1/admin/officer-schedule/",
      headers: {
        Authorization: `token ${managerToken}`,
      },
      data: scheduleData,
    };

    notifyLoading()
    axios(config)
      .then((res) => {
        if (res.status == 201) {
          notify()
          console.log(res.data)
          setTimeout(() => {
            localStorage.setItem("officerId", null);
            props.history.goBack()
          }, 5000);
        }
      })
      .catch((error) => {
        notifyError()
        console.log(error.response.data)
      });
  };

  useEffect(() => {
    var userApi = {
      method: "get",
      url: `https://kepah-24275.botics.co/api/v1/admin/security-personnel/`,
      headers: {
        Authorization: `token ${managerToken}`,
      },
    }
    axios(userApi).then((res) => {
      console.log("Security Officer")
      console.log(res.data)
      setUser(res.data);
    })
  }, [])

  const onChangeDate = (date) => {
    setSelectedDate(date)
    clickChoose()
    console.log((date));
  }

  return (
    <div className="main-officer_schedule">
      <BackButton closeFunction={closeFunction} />
      <div className="side_nav">
        <Sidebar history={props.history} nav="Officers Schedule" />
      </div>
      <div className="go-back_choose-day">
       
        <div className="choose-day_main_back">
          <div style={{ marginTop: "-5%" }}>
            {chooseDate ? (
              <div className="choose-day_back">
                <div className="officers_management_div">
                  <span className="officers_management_text">
                    Choose Day
                  </span>
                </div>
                <div className="calendar_display">
                  <Calendar
                    prev2Label={null}
                    next2Label={null}

                    value={selectedDate}
                    onClickDay={onChangeDate}
                  />
                </div>
              </div>
            ) : null}
            {chooseTime ? (
              <div>
                <div className="choose-day_back">
                  <div className="choose_time_div">
                    <div>
                      <span className="list_of_officers_text">Choose Day</span>
                    </div>
                    <div>
                      <span
                        className="manage_officers_text"
                        onClick={() => selectNew()}
                      >
                        Select New
                      </span>
                    </div>
                  </div>

                  <div className="date_span">
                    <p>
                      {format(selectedDate, 'yyyy-MM-dd')}
                    </p>
                  </div>

                </div>
                <div className="choose-time_back">
                  <Row>
                    <Col className="col-md-11">
                      <div className="choose_time_div">
                        <span className="list_of_officers_text pb-2">Choose Time</span>
                      </div>
                    </Col>
                    <Col className="col-md-1">
                      <div className="choose_time_div">
                        <span className="list_of_officers_text pb-2" style={{ color: "red" }}>{errorState ? "*" : ""} </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-1">
                    </Col>
                    <Col className="col-md-2">
                      <p>From:</p>
                    </Col>
                    <Col className="col-md-3">
                      <input
                        value={timeStart}
                        style={{ background: "#EBF4FE", width: "100%" }}
                        placeholder="16:00"
                        className="input-time"
                        onChange={(e) => {
                          console.log(e.target.value)
                          handleTimeChange1(e)
                        }}
                      />
                    </Col>

                    <Col className="col-md-1">
                      <p>To:</p>
                    </Col>
                    <Col className="col-md-3">
                      <input
                        value={timeEnd}
                        style={{ background: "#EBF4FE", width: "100%", marginLeft: "10px" }}
                        placeholder="20:00"
                        className="input-time"
                        onChange={(e) => handleTimeChange2(e)}
                      />
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col className="col-md-9">
                  </Col>
                  <Col className="col-md-3">
                    <Button style={{ marginLeft: "23px" }} className="mt-4" onClick={() => {
                      validateTime()
                    }}>Next</Button>
                  </Col>
                </Row>
              </div>
            ) : null}
            {chooseOfficer ? (
              <div>
                <div className="choose-day_back">
                  <div className="choose_time_div">
                    <div>
                      <span className="list_of_officers_text">Choose Day</span>
                    </div>
                    <div>
                      <span
                        className="manage_officers_text"
                        onClick={() => selectNew()}
                      >
                        Select New
                      </span>
                    </div>
                  </div>

                  <div className="date_span">
                    <p>
                      {format(selectedDate, 'yyyy-MM-dd')}
                    </p>
                  </div>

                </div>
                <div className="choose-time_back">
                  <div className="choose_time_div">
                    <span className="list_of_officers_text pb-2">Choose Time</span>
                  </div>
                  <Row>
                    <Col className="col-md-1">
                    </Col>
                    <Col className="col-md-2">
                      <p>From:</p>
                    </Col>
                    <Col className="col-md-3">
                      <input
                        style={{ background: "#EBF4FE", width: "100%" }}
                        placeholder="12:00"
                        className="input-time"
                        value={timeStart}
                        onChange={(e) => handleTimeChange1(e)}
                      />
                    </Col>

                    <Col className="col-md-1">
                      <p>To:</p>
                    </Col>
                    <Col className="col-md-3">
                      <input
                        style={{ background: "#EBF4FE", width: "100%", marginLeft: "10px" }}
                        placeholder="06:00"
                        className="input-time"
                        onChange={(e) => handleTimeChange2(e)}
                        value={timeEnd}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="choose-time_back">
                  <div className="choose_time_div">
                    <span className="list_of_officers_text pb-2">Choose Security Officer</span>
                  </div>
                  <Row>

                    <div className="choose-time_back2 customMakeA">
                      <div className="phone_input_field_div">
                        <select
                          className="phone_input_field"
                          onChange={handleDropdownChange}
                          defaultValue={selectedOfficer}
                        >
                          <option> Select An Officer </option>
                          {user && user.map((usr) => (
                            <option value={usr.id} > {usr.name}  ({usr.email}) </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Row>
                </div>
                <Row className="pt-4">
                  <div className="col-md-12">
                    <Button style={{ width: "100%" }} onClick={() => {
                      validateOfficer()
                    }}>REQUEST WORKING HOURS</Button>
                  </div>
                </Row>
              </div>
            ) : null}

            <ToastContainer />
          </div>
        </div>
      </div>
    </div>

  );
};

export default MakeASchedule;
