import axios from 'axios';
import getManagerToken from '../../../config/ManagerToken';

const BASE_URL = 'https://kepah-24275.botics.co/api/v1';
const SOCKET_URL = 'wss://kepah-24275.botics.co';

const managerToken = getManagerToken();

export default async function startConversation(userId) {
  try {
    const response = await axios.post(`${BASE_URL}/conversations/start-conversation/`, {
      receiver: parseInt(userId),
    }, {
      headers: {
        Authorization: `token ${managerToken}`,
      },
    });
    const conversationId = response.data.id;
    console.log(response.data, "RES")
    return conversationId;
  } catch (error) {
    console.error('Error starting conversation:', error);
    throw error;
  }
}

function connectToNotificationsSocket(authToken) {
  const notificationsSocket = new WebSocket(`${SOCKET_URL}/messenger/notifications/?token=${authToken}`);

  notificationsSocket.onopen = (event) => {
    console.log('WebSocket Connection Established NotificationsSocket:', event);
  };

  notificationsSocket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (data.type === 'unread_count') {
      const unreadCount = data.unread_count;
      console.log('Unread Message Count:', unreadCount);
    } else if (data.type === 'new_message_notification') {
      const senderName = data.name;
      const messageData = data.message;
      console.log('New Message Received from', senderName, ':', messageData);
    }
    // Handle other notification types if necessary
  };

  notificationsSocket.onerror = (error) => {
    console.error('WebSocket Error:', error);
  };

  notificationsSocket.onclose = (event) => {
    console.log('WebSocket Connection Closed:', event);
  };
}