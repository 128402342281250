import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import isAuthenticated from '../../config/isAuthenticated';
import { Spinner } from 'react-bootstrap';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await isAuthenticated();
        setAuthenticated(response);
      } catch (error) {
        console.error('Error checking authentication:', error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    // Optional: You can render a loading indicator here if needed
    return (
      <div style={{ width: "100%", height: "100vh", backgroundColor: "white", display: "flex", alignItems:"center", justifyContent: "center" }}>
        <div style={{}}>

          <Spinner animation="border" role="status" variant="info">
           
          </Spinner>
        </div>
      </div>

    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;
