import React, { useState, useEffect } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import EditSvg from "../../../assets/edit.svg"
import DoneSvg from "../../../assets/done.svg"
import UnDoneSvg from "../../../assets/undone.svg"

import ResidentInfo from "../../ListOfResidents/ResidentInfo/ResidentInfo";

const ListOfMaintenancePerson = ({ ...props }) => {
  const [searchOnAptVar, setSearchOnAptVar] = useState("");
  const [searchOnNameVar, setSearchOnNameVar] = useState("");
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [updatedUserList, setUpdatedUserList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userListCheck, setUserListCheck] = useState(false);
  const [userListChanged, setUserListChanged] = useState(false); 
  const [searchOnApt, setSearchOnApt] = useState(false)
  const [searchOnName, setSearchOnName] = useState(false)

  const [showResident, setShowResident] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [position, setSelectedUserPosition] = useState(null);
  const [userUpdated, setUserUpdated] = useState(false);

  const showResidentInfo = (event, resident) => {
    // Get the position of the clicked apartment number
    const rect = event.currentTarget.getBoundingClientRect();
    const position = { x: rect.left, y: rect.top + rect.height };
    setShowResident(true);
    setSelectedResident(resident);
    setSelectedUserPosition(position);
  };

  const data = [
    {
      name: "John Doe",
      apartment: "A101",
      status: "waiting",
      phone: "555-1234"
    },
    {
      name: "Jane Smith",
      apartment: "B205",
      status: "assigned",
      phone: "555-5678"
    },
    {
      name: "Michael Johnson",
      apartment: "C304",
      status: "waiting",
      phone: "555-7890"
    },
    {
      name: "Emily Brown",
      apartment: "D402",
      status: "assigned",
      phone: "555-4567"
    },
    {
      name: "David Lee",
      apartment: "E503",
      status: "waiting",
      phone: "555-2345"
    },
    {
      name: "Sophia Miller",
      apartment: "F608",
      status: "assigned",
      phone: "555-6789"
    },
    {
      name: "William Wilson",
      apartment: "G706",
      status: "waiting",
      phone: "555-7896"
    },
    {
      name: "Olivia Garcia",
      apartment: "H802",
      status: "assigned",
      phone: "555-3456"
    },
    {
      name: "James Martinez",
      apartment: "I904",
      status: "waiting",
      phone: "555-5679"
    },
    {
      name: "Ava Davis",
      apartment: "J1001",
      status: "assigned",
      phone: "555-1238"
    }, {
        name: "John Doe",
        apartment: "A101",
        status: "waiting",
        phone: "555-1234"
      },
      {
        name: "Jane Smith",
        apartment: "B205",
        status: "assigned",
        phone: "555-5678"
      },
      {
        name: "Michael Johnson",
        apartment: "C304",
        status: "waiting",
        phone: "555-7890"
      },
      {
        name: "Emily Brown",
        apartment: "D402",
        status: "assigned",
        phone: "555-4567"
      },
      {
        name: "David Lee",
        apartment: "E503",
        status: "waiting",
        phone: "555-2345"
      },
      {
        name: "Sophia Miller",
        apartment: "F608",
        status: "assigned",
        phone: "555-6789"
      },
      {
        name: "William Wilson",
        apartment: "G706",
        status: "waiting",
        phone: "555-7896"
      },
      {
        name: "Olivia Garcia",
        apartment: "H802",
        status: "assigned",
        phone: "555-3456"
      },
      {
        name: "James Martinez",
        apartment: "I904",
        status: "waiting",
        phone: "555-5679"
      },
      {
        name: "Ava Davis",
        apartment: "J1001",
        status: "assigned",
        phone: "555-1238"
      }
  ];
  

  const managerToken = localStorage.getItem("token")

  var config = {
    method: "get",
    url: "https://kepah-24275.botics.co/api/v1/admin/maintenance/persons/",
    headers: {
      Authorization: `token ${managerToken}`,
      "Content-Type": "application/json",
    },
  };

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data
        const res = await axios(config);
        const userListData = res.data;
        console.log(res.data)
        
        setUpdatedUserList(userListData)
       
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (!searchOnName && !searchOnApt) {
  //     setUserList(userData)
  //     setUpdatedUserList(userList)
  //      setUserListCheck(true);
  //   } else {
  //     let filteredResults = []
  //     if (searchOnName) {
  //       filteredResults = userList.filter((user) => {
  //         console.log(user)
  //         if (user && user.name) {
  //           const nameMatch = user.name.toLowerCase().includes(searchOnNameVar.toLowerCase());
  //           return nameMatch;
  //         }
  //         return false;
  //       });
  //     }
  //     if (searchOnApt) {
  //       filteredResults = userList.filter((user) => {
  //         if (user && user.apartment_number) {
  //           const aptMatch = user.apartment_number.toString().includes(searchOnAptVar);
  //           return aptMatch;
  //         }
  //         return false;
  //       });
  //     }
  //     console.log(filteredResults)
  //     setFilteredUsers(filteredResults);
  //   }
  // }, [userList, searchOnNameVar, searchOnAptVar]);

  // useEffect(() => {
  //   setUpdatedUserList(filteredUsers)
  // }, [filteredUsers]);

  
  // useEffect(() => {
  //   if(!searchOnName && !searchOnApt) {
  //     setUpdatedUserList(userData)
  //     setUserList(userData)
  //   } else {
  //     setUpdatedUserList(updatedUserList)
  //   }
  // }, [userList, searchOnName, searchOnApt, userData]);

  // useEffect(() => {
  //   if(searchOnNameVar.length > 0 ) {
  //     setSearchOnName(true)
  //     setSearchOnApt(false)
  //   } else if(searchOnAptVar.length > 0 )  {
  //     setSearchOnApt(true)
  //     setSearchOnName(false)
  //   } else {
  //     setSearchOnApt(false)
  //     setSearchOnName(false)
  //   }
  // }, [searchOnAptVar, searchOnNameVar])

  return (
    <div onClick={(event) => {
      const rect = event.currentTarget.getBoundingClientRect();
      const position2 = { x: rect.left, y: rect.top + rect.height };
      console.log("position2")
      console.log(position2)
      if (selectedResident && position && (position.x !== position2.x || position.y !== position2.y)) {
        setSelectedResident(null); // Close the resident info div
      }
    }}>
      <div className="list_of_officers_manage_div_main_page">
        <div>
          <span className="list_of_officers_text_main_page">Maintenance Persons</span>
        </div>
      </div>

      {/* <div className="criminal_input_div">
        <input
          type="text"
          className="rent_input"
          placeholder="Full Name"
          onChange={(e) => {
            setSearchOnNameVar(e.target.value)
            setSearchOnName(true)
          }}
        />
        <input
          type="text"
          className="rent_input"
          placeholder="Apartment Number"
          onChange={(e) => {
            setSearchOnAptVar(e.target.value)
            setSearchOnApt(true)
          }}
        />
        <div className="criminal_search_btn">
          <img src={require("../../../assets/Search.png").default} />
        </div>
        <Form className="form_inline_criminal">
          <Button
            className="add_new_resident_btn"
            variant="primary"
            type="submit"
            onClick={() => props.addNewResident()}
          >
            ADD NEW RESIDENT
          </Button>
        </Form>
      </div> */}

      <div 
        className="pt-4">
        <div className="list_of_residents_div">
            <div>
              <Container>
                <div className="infoRow">
                  <div style={{paddingLeft: "20px"}} className="col_maintenance_name">
                    Name
                  </div>
                  <div className="col_maintenance_apt">
                    Email
                  </div>
                  <div className="col_maintenance_tenants">
                    Status
                  </div>
                  <div className="col_maintenance_email">
                    Phone
                  </div>
                  <div className="col_maintenance_edit">

                  </div>
                </div>
                <div className="bottom_border_manager mt-1 mb-1" />
                {/* <Row>
                  <div className="bottom_border_manager" />
                </Row> */}
                { updatedUserList ? updatedUserList.map(user => (
                  <div className="infoRow p-1 mt-2 row_alternate" key={user.id}>
                    <div className="col_maintenance_name">
                      <Row>
                   
                        <Col style={{ marginLeft: "15px", color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                          {user.name ? user.name : ""}
                        </Col>
                      </Row>
                    </div>
                    <div className="col_maintenance_apt" style={{ color: "#1d7def", fontSize: "14px", paddingTop: "6px"}}>
                      {user.email ? user.email : ""}
                    </div>
                    <div className="col_maintenance_tenants" style={{ fontSize: "14px", paddingTop: "6px"}} >
                        {user.status === "on_duty" ? (
                            <Row>
                                <Col className="col-md-1">
                                    <img style={{width: "16px", height:"16px"}} src={DoneSvg} />
                                </Col>
                                <Col>
                                    On Duty
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col className="col-md-1">
                                    <img style={{width: "16px", height:"16px"}} src={UnDoneSvg} />
                                </Col>
                                <Col>
                                    Off Duty
                                </Col>
                            </Row>
                        )}
                    </div>
                    <div className="col_maintenance_email" style={{ fontSize: "14px", paddingTop: "6px"}}>
                      {user.phone ? user.phone : ""}
                    </div>
                    <div className="col_maintenance_edit2" style={{display: "flex", alignItems: "center", justifyContent: "flex-end", cursor: "pointer"}}  onClick={() => {
                      localStorage.setItem("maintenanceInfoChange", JSON.stringify(user));
                      props.history.push("/maintenancerequest/officer")
                    }}>
                      <img src={EditSvg} style={{ height: "16px", width: "16px" }}/>
                    </div>
                  </div>
                  
                )): <></> }
                {selectedResident && (
                  <div>
                    <ResidentInfo
                      className=""
                      resident={selectedResident}
                      position={position}
                      onClose={() => setSelectedResident(null)} // Function to close ResidentInfo
                    />
                  </div>
                )}
              </Container>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfMaintenancePerson;
