import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import Sidebar from "../../Sidebar/Sidebar";
import RequestX from "../Request/RequestX";
import personImage from "../../../assets/profile.png"

const MaintenanceProfile = ({ ...props }) => {
  const [maintenanceUser, setMaintenanceUser] = useState([])
  const [maintenanceUserY, setMaintenanceUserY] = useState([])
  const [userSet, setUserSet] = useState(false);

  const showProf = JSON.parse(localStorage.getItem("showProfile"));
  const maintenanceUserx = JSON.parse(localStorage.getItem("maintenanceRequestUser"));

  useEffect(() => {
    console.log(maintenanceUserx.user)
    setMaintenanceUserY(maintenanceUserx)
  }, [])

  useEffect(() => {
    setUserSet(true)
    setMaintenanceUser(maintenanceUserY)
  }, [maintenanceUserY])

  return (
    <div className="incident_back">
      <div className="side_nav">
        <Sidebar history={props.history} nav="Maintenance Request" />
      </div>
      <div className="maintenance_main_div">
        <div className="maintenance_profile_main_page">
          <div className="maintenance_profile_main_background">
            <div className="maintenance_profile_name_img_main_div">
              <div className="maintenance_profiles_slide_div">
                {/* <div className="arrow_left_img_div">
            <img src={require("../../../assets/arrow-left.png").default} alt="Left Arrow" />
          </div> */}
                <div>
                  {userSet && maintenanceUser.user && (
                    <img
                      className="maintenance_profile_img"
                      src={maintenanceUser.user.profile_picture ? maintenanceUser.user.profile_picture : personImage}
                      alt="Profile"
                    />
                  )}
                </div>
                {/* <div className="arrow_right_img_div">
            <img src={require("../../../assets/arrow-right.png").default} alt="Right Arrow" />
          </div> */}
              </div>

              <div className="user_profile_name">
                <span className="user_profle_name_text">{userSet && maintenanceUser.user ? maintenanceUser.user.name : ""}</span>
                <span className="user_profile_view_text" onClick={props.showProfile}>{userSet && showProf ? "Close Profile" : "Show Profile"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="request_page_main_div">
          <RequestX history={props.history} />
        </div>
      </div>
    </div>

  );
};

export default MaintenanceProfile;
